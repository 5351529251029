import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { statusCodes } from '../api/api.service';
import { Observable, throwError, of as observableOf } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoaderService } from '@core/services/loader.services';
import 'rxjs/add/observable/throw';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(public auth: AuthService, private loader: LoaderService) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        this.loader.showLoading();

        if (this.auth.isAuthenticated()) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.auth.getToken()}`,
                    Accept: 'application/json'
                }
            });
        }

        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        this.loader.hideLoading();
                    }
                },
                (error: HttpErrorResponse) => {
                    const errorCode = error.status;
                    if (
                        errorCode === statusCodes.internal_server_error ||
                        errorCode === statusCodes.not_found ||
                        errorCode === statusCodes.not_authorized
                    ) {
                        this.auth.failedRequestCode();
                    }
                }
            ),
            catchError(error => {
                this.loader.hideLoading();
                return Observable.throw(error);
            }) as any
        );
    }
}
