import { AppMapDialogComponent } from './../components/map-dialog/map-dialog.component';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { AppAlertDialogComponent } from '../components/alert/alert-dialog.component';
import { AppDirectivesModule } from '../directives/directives';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'environments/environment';



@NgModule({
    declarations   : [
        AppConfirmDialogComponent,
        AppAlertDialogComponent,
        AppMapDialogComponent
    ],
    imports        : [
        FlexLayoutModule,
        MaterialModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AppDirectivesModule,
        AgmCoreModule.forRoot({
            apiKey:  'AIzaSyDVQxWZKo4_Fhf77XPxiuk_JBS2JthC5jE',
            libraries: ['places']
        }),

    ],
    exports        : [
        FlexLayoutModule,
        MaterialModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AppDirectivesModule

    ],
    entryComponents: [
        AppConfirmDialogComponent,
        AppAlertDialogComponent,
        AppMapDialogComponent
    ]
})

export class SharedModule
{

}
