import { Observable } from 'rxjs';
import 'rxjs/add/operator/delay';
import 'rxjs/add/observable/of';

export class GeneralData {
    public static getMenuFooter() {
        return Observable.of([
            {
                title: 'Nosotros',
                links: [
                    { name: '¿Quienes Somos?', url: '' },
                    { name: 'Cultura y valores', url: '' },
                    { name: 'Talento', url: '' },
                    { name: 'Nuestros locales', url: '' },
                    { name: 'Testimonios', url: '' }
                ]
            },
            {
                title: '¿Cómo funciona?',
                links: [
                    { name: 'Ofrece tu local en 4 pasos', url: '' },
                    { name: 'Documentos requeridos', url: '' },
                    { name: 'Preguntas frecuentes', url: '' },
                    { name: 'Preguntas frecuentes', url: '' }
                ]
            },
            {
                title: 'Mi cuenta',
                links: [
                    { name: 'Mi perfil', url: '' },
                    { name: 'Mis propiedades', url: '' }
                ]
            },
            {
                title: 'Políticas de uso',
                links: [
                    { name: 'Términos y condiciones', url: '' },
                    { name: 'Política de cookies', url: '' }
                ]
            }
        ]).delay(100);
    }
    public static getLogosFooter() {
        return Observable.of([
            {
                name: 'mass',
                icon: '/assets/images/logos-nosotros/mass.png',
                url: '',
                width: 45
            },
            {
                name: 'plaza-vea',
                icon: '/assets/images/logos-nosotros/plaza-vea.png',
                url: '',
                width: 45
            },
            {
                name: 'vivanda',
                icon: '/assets/images/logos-nosotros/vivanda.png',
                url: '',
                width: 45
            },
            {
                name: 'economax',
                icon: '/assets/images/logos-nosotros/economax.png',
                url: '',
                width: 45
            }
        ]).delay(100);
    }
    public static getLocations() {
        return Observable.of([
            {
                title: 'MS 13 de Agosto',
                latitude: -12.244121,
                longitude: -76.919433,
                description: ''
            },
            {
                title: 'MS 2 de Octubre 10',
                latitude: -11.948955,
                longitude: -77.080162,
                description: ''
            },
            {
                title: 'MS 24 de Junio',
                latitude: -12.205819,
                longitude: -76.940956,
                description: ''
            },
            {
                title: 'MS 26 De Noviembre',
                latitude: -12.16713,
                longitude: -76.919823,
                description: ''
            },
            {
                title: 'MS 27 de Diciembre',
                latitude: -12.189108,
                longitude: -76.92738,
                description: ''
            },
            {
                title: 'MS Alameda Canta',
                latitude: -12.020272,
                longitude: -77.082289,
                description: ''
            },
            {
                title: 'MS Alcides Carrion 12',
                latitude: -11.990948,
                longitude: -77.09693,
                description: ''
            },
            {
                title: 'MS Algarrobos 1',
                latitude: -11.93546,
                longitude: -77.083599,
                description: ''
            },
            {
                title: 'MS Alipio',
                latitude: -12.168844,
                longitude: -76.980383,
                description: ''
            },
            {
                title: 'MS Alisos 7',
                latitude: -11.983177,
                longitude: -77.078295,
                description: ''
            },
            {
                title: 'MS Almendra 2',
                latitude: -11.98554,
                longitude: -77.058916,
                description: ''
            },
            {
                title: 'MS Amancaes 1',
                latitude: -12.025839,
                longitude: -77.033652,
                description: ''
            },
            {
                title: 'MS Angamos 25',
                latitude: -12.112124,
                longitude: -77.001247,
                description: ''
            },
            {
                title: 'MS Angelica Gamarra 7',
                latitude: -12.006092,
                longitude: -77.070559,
                description: ''
            },
            {
                title: 'MS Ateca',
                latitude: -12.212978,
                longitude: -76.90804,
                description: ''
            },
            {
                title: 'MS Aviación 32',
                latitude: -12.102989,
                longitude: -77.001219,
                description: ''
            },
            {
                title: 'MS Aymaras 3',
                latitude: -12.074815,
                longitude: -76.988403,
                description: ''
            },
            {
                title: 'MS Belaunde',
                latitude: -11.93646,
                longitude: -77.053933,
                description: ''
            },
            {
                title: 'MS Belisario Suarez',
                latitude: -12.160549,
                longitude: -76.975114,
                description: ''
            },
            {
                title: 'MS Benavides',
                latitude: -12.128184,
                longitude: -76.989043,
                description: ''
            },
            {
                title: 'MS Betancour 4',
                latitude: -11.956623,
                longitude: -77.080888,
                description: ''
            },
            {
                title: 'MS Bethoveen 4',
                latitude: -12.098975,
                longitude: -76.995162,
                description: ''
            },
            {
                title: 'MS Bolognesi 6',
                latitude: -12.091246,
                longitude: -77.074271,
                description: ''
            },
            {
                title: 'MS Brigida Silva 3',
                latitude: -12.079591,
                longitude: -77.087357,
                description: ''
            },
            {
                title: 'MS Buenos Aires 4',
                latitude: -11.865583,
                longitude: -77.078187,
                description: ''
            },
            {
                title: 'MS Caceres',
                latitude: -12.146687,
                longitude: -77.005792,
                description: ''
            },
            {
                title: 'MS Candamo 1',
                latitude: -12.082413,
                longitude: -77.035039,
                description: ''
            },
            {
                title: 'MS Canevaro',
                latitude: -12.174525,
                longitude: -76.966944,
                description: ''
            },
            {
                title: 'MS Cedros',
                latitude: -12.203879,
                longitude: -77.014671,
                description: ''
            },
            {
                title: 'MS Centenario 2',
                latitude: -12.065944,
                longitude: -77.049128,
                description: ''
            },
            {
                title: 'MS Covida',
                latitude: -11.99546,
                longitude: -77.078198,
                description: ''
            },
            {
                title: 'MS Credito',
                latitude: -12.17008,
                longitude: -76.968101,
                description: ''
            },
            {
                title: 'MS Del Aire 13',
                latitude: -12.077438,
                longitude: -76.997158,
                description: ''
            },
            {
                title: 'MS Dominicos 1',
                latitude: -12.005038,
                longitude: -77.099455,
                description: ''
            },
            {
                title: 'MS El Arco',
                latitude: -12.178022,
                longitude: -76.962728,
                description: ''
            },
            {
                title: 'MS El Ejercito',
                latitude: -12.097796,
                longitude: -77.069588,
                description: ''
            },
            {
                title: 'MS EL Triunfo',
                latitude: -12.165455,
                longitude: -76.947318,
                description: ''
            },
            {
                title: 'MS Emilio Althaus 1',
                latitude: -12.081059,
                longitude: -77.03563,
                description: ''
            },
            {
                title: 'MS Escardo',
                latitude: -12.081589,
                longitude: -77.095192,
                description: ''
            },
            {
                title: 'MS Escardo 6',
                latitude: -12.073798,
                longitude: -77.092136,
                description: ''
            },
            {
                title: 'MS Estrela de Pro',
                latitude: -11.935514,
                longitude: -77.077093,
                description: ''
            },
            {
                title: 'MS Fibonaci',
                latitude: -12.10836,
                longitude: -77.011022,
                description: ''
            },
            {
                title: 'MS Fulgencio 8',
                latitude: -12.056159,
                longitude: -77.051932,
                description: ''
            },
            {
                title: 'MS Garzón 6',
                latitude: -12.066534,
                longitude: -77.045169,
                description: ''
            },
            {
                title: 'MS Grau 3',
                latitude: -12.27349,
                longitude: -76.869372,
                description: ''
            },
            {
                title: 'MS Guardia Civil',
                latitude: -12.183643,
                longitude: -77.000337,
                description: ''
            },
            {
                title: 'MS Guillermo La Fuente 3',
                latitude: -11.941878,
                longitude: -77.063149,
                description: ''
            },
            {
                title: 'MS Habich 1',
                latitude: -12.024181,
                longitude: -77.050941,
                description: ''
            },
            {
                title: 'MS Habich 5',
                latitude: -12.027269,
                longitude: -77.056247,
                description: ''
            },
            {
                title: 'MS Hacienda Villa',
                latitude: -12.168388,
                longitude: -77.025183,
                description: ''
            },
            {
                title: 'MS Huandoy 2',
                latitude: -11.939627,
                longitude: -77.077217,
                description: ''
            },
            {
                title: 'MS Inca Roca',
                latitude: -12.187498,
                longitude: -76.926668,
                description: ''
            },
            {
                title: 'MS Izaguirre',
                latitude: -11.989397,
                longitude: -77.064818,
                description: ''
            },
            {
                title: 'MS Izaguirre 14',
                latitude: -11.991821,
                longitude: -77.081324,
                description: ''
            },
            {
                title: 'MS Jesus Morales',
                latitude: -12.166202,
                longitude: -76.975159,
                description: ''
            },
            {
                title: 'MS Jorge Chavez 19',
                latitude: -12.151729,
                longitude: -77.004379,
                description: ''
            },
            {
                title: 'MS Jose Granda 30',
                latitude: -12.025642,
                longitude: -77.075508,
                description: ''
            },
            {
                title: 'MS Jose Leal 8',
                latitude: -12.085706,
                longitude: -77.041621,
                description: ''
            },
            {
                title: 'MS Jose Saco Rojas 27',
                latitude: -11.85381,
                longitude: -77.036036,
                description: ''
            },
            {
                title: 'MS Julio C Tello 6',
                latitude: -12.086414,
                longitude: -77.03947,
                description: ''
            },
            {
                title: 'MS La Cabaña',
                latitude: -12.154921,
                longitude: -76.971435,
                description: ''
            },
            {
                title: 'MS La Capilla',
                latitude: -12.173874,
                longitude: -76.96262,
                description: ''
            },
            {
                title: 'MS La Estación',
                latitude: -12.168826,
                longitude: -76.950946,
                description: ''
            },
            {
                title: 'MS La Merced',
                latitude: -12.117268,
                longitude: -77.006209,
                description: ''
            },
            {
                title: 'MS La Posada',
                latitude: -12.165313,
                longitude: -76.972167,
                description: ''
            },
            {
                title: 'MS Las Palmeras',
                latitude: -11.989452,
                longitude: -77.07172,
                description: ''
            },
            {
                title: 'MS Las Perlas',
                latitude: -11.991275,
                longitude: -77.07441,
                description: ''
            },
            {
                title: 'MS Lavalle 1',
                latitude: -12.153382,
                longitude: -76.970624,
                description: ''
            },
            {
                title: 'MS Libertad 15',
                latitude: -12.082651,
                longitude: -77.096256,
                description: ''
            },
            {
                title: 'MS Lima',
                latitude: -12.213631,
                longitude: -76.906654,
                description: ''
            },
            {
                title: 'MS Loma Umbrosa',
                latitude: -12.139099,
                longitude: -76.988054,
                description: ''
            },
            {
                title: 'MS Loreto 3',
                latitude: -12.05324,
                longitude: -77.049255,
                description: ''
            },
            {
                title: 'MS Los Alamos',
                latitude: -12.209516,
                longitude: -76.942745,
                description: ''
            },
            {
                title: 'MS Los Heroes 6',
                latitude: -12.156086,
                longitude: -76.967992,
                description: ''
            },
            {
                title: 'MS Los Quechuas 10',
                latitude: -12.074683,
                longitude: -76.979463,
                description: ''
            },
            {
                title: 'MS Lurin',
                latitude: -12.277606,
                longitude: -76.869069,
                description: ''
            },
            {
                title: 'MS Lurin 40',
                latitude: -12.277008,
                longitude: -76.870002,
                description: ''
            },
            {
                title: 'MS Manco Capac 3',
                latitude: -12.062957,
                longitude: -77.029521,
                description: ''
            },
            {
                title: 'MS Mariátegui',
                latitude: -12.152152,
                longitude: -76.951493,
                description: ''
            },
            {
                title: 'MS Mariategui 2',
                latitude: -12.221302,
                longitude: -76.931825,
                description: ''
            },
            {
                title: 'MS Matellini',
                latitude: -12.178646,
                longitude: -77.009782,
                description: ''
            },
            {
                title: 'MS Mayolo 17',
                latitude: -11.996557,
                longitude: -77.085569,
                description: ''
            },
            {
                title: 'MS Mega Ochenta',
                latitude: -11.962186,
                longitude: -77.054286,
                description: ''
            },
            {
                title: 'MS Mexico',
                latitude: -11.955208,
                longitude: -77.059553,
                description: ''
            },
            {
                title: 'MS Mexico 2',
                latitude: -12.066671,
                longitude: -77.007922,
                description: ''
            },
            {
                title: 'MS Micaela Bastidas',
                latitude: -12.208715,
                longitude: -76.945931,
                description: ''
            },
            {
                title: 'MS Miguel Iglesias',
                latitude: -12.192666,
                longitude: -76.955563,
                description: ''
            },
            {
                title: 'MS Modelo',
                latitude: -12.195623,
                longitude: -76.958716,
                description: ''
            },
            {
                title: 'MS Naranjal 12',
                latitude: -11.979818,
                longitude: -77.080067,
                description: ''
            },
            {
                title: 'MS Nazarenas 2',
                latitude: -12.131367,
                longitude: -76.987257,
                description: ''
            },
            {
                title: 'MS Oasis',
                latitude: -12.237943,
                longitude: -76.93682,
                description: ''
            },
            {
                title: 'MS Olivos 6',
                latitude: -11.986783,
                longitude: -77.087586,
                description: ''
            },
            {
                title: 'MS Ollantay',
                latitude: -12.137093,
                longitude: -76.962998,
                description: ''
            },
            {
                title: 'MS Pachacamac',
                latitude: -12.260347,
                longitude: -76.884129,
                description: ''
            },
            {
                title: 'MS Pachacutec 11',
                latitude: -12.159094,
                longitude: -76.958594,
                description: ''
            },
            {
                title: 'MS Parader 77',
                latitude: -12.220523,
                longitude: -76.923844,
                description: ''
            },
            {
                title: 'MS Paraiso',
                latitude: -12.229748,
                longitude: -76.860171,
                description: ''
            },
            {
                title: 'MS Parque Villa',
                latitude: -12.235867,
                longitude: -76.910443,
                description: ''
            },
            {
                title: 'MS Pastor Sevilla 1',
                latitude: -12.221351,
                longitude: -76.944228,
                description: ''
            },
            {
                title: 'MS Peru',
                latitude: -12.032315,
                longitude: -77.065829,
                description: ''
            },
            {
                title: 'MS Pinelo',
                latitude: -11.93838,
                longitude: -77.052292,
                description: ''
            },
            {
                title: 'MS Plaza',
                latitude: -12.227456,
                longitude: -76.862691,
                description: ''
            },
            {
                title: 'MS Primavera',
                latitude: -12.141964,
                longitude: -76.949706,
                description: ''
            },
            {
                title: 'MS Primero de Mayo',
                latitude: -12.197659,
                longitude: -76.96666,
                description: ''
            },
            {
                title: 'MS Próceres',
                latitude: -12.15939,
                longitude: -76.988545,
                description: ''
            },
            {
                title: 'MS Progresol',
                latitude: -12.197679,
                longitude: -76.957447,
                description: ''
            },
            {
                title: 'MS Punta Hermosa 21',
                latitude: -12.33104,
                longitude: -76.824947,
                description: ''
            },
            {
                title: 'MS Punta Negra 1',
                latitude: -12.364986,
                longitude: -76.79517,
                description: ''
            },
            {
                title: 'MS Queen',
                latitude: -11.944965,
                longitude: -77.050684,
                description: ''
            },
            {
                title: 'MS Recavarren 11',
                latitude: -12.109627,
                longitude: -77.024488,
                description: ''
            },
            {
                title: 'MS Revolución 19',
                latitude: -12.210144,
                longitude: -76.938918,
                description: ''
            },
            {
                title: 'MS Ricardo Palma 1',
                latitude: -11.863849,
                longitude: -77.077459,
                description: ''
            },
            {
                title: 'MS Rio Santa 1',
                latitude: -11.949961,
                longitude: -77.071286,
                description: ''
            },
            {
                title: 'MS Risso 3',
                latitude: -12.085507,
                longitude: -77.032733,
                description: ''
            },
            {
                title: 'MS Roox',
                latitude: -12.212409,
                longitude: -76.934337,
                description: ''
            },
            {
                title: 'MS Salvador 18',
                latitude: -12.231865,
                longitude: -76.922784,
                description: ''
            },
            {
                title: 'MS Salvador Allende 1',
                latitude: -12.159466,
                longitude: -76.95595,
                description: ''
            },
            {
                title: 'MS San Bartolo',
                latitude: -12.389328,
                longitude: -76.778555,
                description: ''
            },
            {
                title: 'MS San Diego',
                latitude: -11.936829,
                longitude: -77.071977,
                description: ''
            },
            {
                title: 'MS San German 3',
                latitude: -12.011668,
                longitude: -77.07073,
                description: ''
            },
            {
                title: 'MS San Juan',
                latitude: -12.203249,
                longitude: -76.949716,
                description: ''
            },
            {
                title: 'MS San Juan 4',
                latitude: -12.076839,
                longitude: -77.006448,
                description: ''
            },
            {
                title: 'MS San Juan 9',
                latitude: -12.158056,
                longitude: -76.973424,
                description: ''
            },
            {
                title: 'MS San Pedro',
                latitude: -12.197272,
                longitude: -76.981178,
                description: ''
            },
            {
                title: 'MS San Pedro 2',
                latitude: -12.195516,
                longitude: -76.983609,
                description: ''
            },
            {
                title: 'MS Santa Elvira 61',
                latitude: -11.959579,
                longitude: -77.072611,
                description: ''
            },
            {
                title: 'MS Sao Paulo 18',
                latitude: -12.030481,
                longitude: -77.064575,
                description: ''
            },
            {
                title: 'MS Separadora 3',
                latitude: -12.207396,
                longitude: -76.933961,
                description: ''
            },
            {
                title: 'MS Shangrilla',
                latitude: -11.915717,
                longitude: -77.078584,
                description: ''
            },
            {
                title: 'MS Sucre 11',
                latitude: -12.082474,
                longitude: -77.067153,
                description: ''
            },
            {
                title: 'MS Surco Viejo',
                latitude: -12.160163,
                longitude: -76.994372,
                description: ''
            },
            {
                title: 'MS Talara 2',
                latitude: -12.212034,
                longitude: -76.907852,
                description: ''
            },
            {
                title: 'MS Talara 4',
                latitude: -12.070742,
                longitude: -77.045112,
                description: ''
            },
            {
                title: 'MS Talara 6',
                latitude: -12.227613,
                longitude: -76.931759,
                description: ''
            },
            {
                title: 'MS Tantamayo 2',
                latitude: -11.963425,
                longitude: -77.092912,
                description: ''
            },
            {
                title: 'MS Tipac 1',
                latitude: -12.274725,
                longitude: -76.869585,
                description: ''
            },
            {
                title: 'MS Tomas Valle 12',
                latitude: -12.012324,
                longitude: -77.075262,
                description: ''
            },
            {
                title: 'MS Tupac 31',
                latitude: -11.881083,
                longitude: -77.022059,
                description: ''
            },
            {
                title: 'MS Tupac Amaru 10',
                latitude: -11.958313,
                longitude: -77.049792,
                description: ''
            },
            {
                title: 'MS Tupac Amaru 19',
                latitude: -12.01075,
                longitude: -77.052653,
                description: ''
            },
            {
                title: 'MS Tupac Amaru 33',
                latitude: -11.873545,
                longitude: -77.015404,
                description: ''
            },
            {
                title: 'MS Tupac Amaru 53',
                latitude: -11.915147,
                longitude: -77.040764,
                description: ''
            },
            {
                title: 'MS Unger 33',
                latitude: -11.99749,
                longitude: -77.055611,
                description: ''
            },
            {
                title: 'MS Universitaria 2',
                latitude: -11.895323,
                longitude: -77.03755,
                description: ''
            },
            {
                title: 'MS Universitaria 20',
                latitude: -12.0639,
                longitude: -77.078583,
                description: ''
            },
            {
                title: 'MS Universitaria 6',
                latitude: -12.082606,
                longitude: -77.082262,
                description: ''
            },
            {
                title: 'MS Universitaria 65',
                latitude: -11.971497,
                longitude: -77.076056,
                description: ''
            },
            {
                title: 'MS Universitaria 7',
                latitude: -12.018524,
                longitude: -77.076788,
                description: ''
            },
            {
                title: 'MS Universitaria 70',
                latitude: -11.940585,
                longitude: -77.060037,
                description: ''
            },
            {
                title: 'MS Vargas Machuca',
                latitude: -12.165894,
                longitude: -76.974318,
                description: ''
            },
            {
                title: 'MS Velasco',
                latitude: -12.227065,
                longitude: -76.940947,
                description: ''
            },
            {
                title: 'MS Vidreria',
                latitude: -12.211363,
                longitude: -76.944315,
                description: ''
            },
            {
                title: 'MS Villa Maria',
                latitude: -12.16374,
                longitude: -76.946691,
                description: ''
            },
            {
                title: 'MS Villa Sur',
                latitude: -12.213166,
                longitude: -76.933795,
                description: ''
            },
            {
                title: 'MS Villaran 7',
                latitude: -12.121127,
                longitude: -77.004455,
                description: ''
            },
            {
                title: 'MS Villasol',
                latitude: -11.959342,
                longitude: -77.075081,
                description: ''
            },
            {
                title: 'MS Viña Lariena',
                latitude: -12.167118,
                longitude: -76.990479,
                description: ''
            },
            {
                title: 'MS Vipol',
                latitude: -11.966878,
                longitude: -77.089699,
                description: ''
            },
            {
                title: 'MS Vista Alegre',
                latitude: -12.165484,
                longitude: -76.992294,
                description: ''
            },
            {
                title: 'MS Yupanqui 5',
                latitude: -11.977998,
                longitude: -77.04779,
                description: ''
            },
            {
                title: 'MS Zarumilla 8',
                latitude: -12.033159,
                longitude: -77.053367,
                description: ''
            },
            {
                title: 'MS Alisos 6',
                latitude: -11.984257,
                longitude: -77.069436,
                description: ''
            },
            {
                title: 'MS Angelica Gamarra 20',
                latitude: -12.006271,
                longitude: -77.082748,
                description: ''
            },
            {
                title: 'MS Aristides 13',
                latitude: -12.052985,
                longitude: -77.077332,
                description: ''
            },
            {
                title: 'MS Bolivia 7',
                latitude: -12.056562,
                longitude: -77.044995,
                description: ''
            },
            {
                title: 'MS Carabayllo 17',
                latitude: -11.944774,
                longitude: -77.05273,
                description: ''
            },
            {
                title: 'MS Combate de Angamos 15',
                latitude: -12.14235,
                longitude: -77.009388,
                description: ''
            },
            {
                title: 'MS Condorcanqui 9',
                latitude: -11.884724,
                longitude: -77.034347,
                description: ''
            },
            {
                title: 'MS Constructores 13',
                latitude: -12.0612,
                longitude: -76.94306,
                description: ''
            },
            {
                title: 'MS Dinthilac 3',
                latitude: -12.082506,
                longitude: -77.086085,
                description: ''
            },
            {
                title: 'MS Dominicos 33',
                latitude: -12.013307,
                longitude: -77.095952,
                description: ''
            },
            {
                title: 'MS Edmundo 1',
                latitude: -12.156243,
                longitude: -77.014951,
                description: ''
            },
            {
                title: 'MS Ingenieros 4',
                latitude: -12.065625,
                longitude: -76.949849,
                description: ''
            },
            {
                title: 'MS La Floresta',
                latitude: -12.15987,
                longitude: -76.98502,
                description: ''
            },
            {
                title: 'MS Las Flores 19',
                latitude: -11.997602,
                longitude: -77.014808,
                description: ''
            },
            {
                title: 'MS Mi Peru 9',
                latitude: -11.854291,
                longitude: -77.125076,
                description: ''
            },
            {
                title: 'MS Reducto 9',
                latitude: -12.128016,
                longitude: -77.023959,
                description: ''
            },
            {
                title: 'MS Salguero 5',
                latitude: -12.130317,
                longitude: -77.008091,
                description: ''
            },
            {
                title: 'MS San Hilarion 13',
                latitude: -11.992862,
                longitude: -77.009692,
                description: ''
            },
            {
                title: 'MS Santa Rosa 1',
                latitude: -11.968705,
                longitude: -76.994433,
                description: ''
            },
            {
                title: 'MS Tusilagos 3',
                latitude: -12.013269,
                longitude: -77.004678,
                description: ''
            },
            {
                title: 'MS Ventanilla',
                latitude: -11.877002,
                longitude: -77.12878,
                description: ''
            },
            {
                title: 'MS Villa Pachacutec 7',
                latitude: -11.830622,
                longitude: -77.144411,
                description: ''
            },
            {
                title: 'MS Zorritos 8',
                latitude: -12.049504,
                longitude: -77.050184,
                description: ''
            },
            {
                title: 'MS A. Ugarte',
                latitude: -12.117039,
                longitude: -77.026809,
                description: ''
            },
            {
                title: 'MS Bauzate 11',
                latitude: -12.063236,
                longitude: -77.019178,
                description: ''
            },
            {
                title: 'MS Benavides 37',
                latitude: -12.128751,
                longitude: -77.000428,
                description: ''
            },
            {
                title: 'MS Caceres 35',
                latitude: -12.009028,
                longitude: -76.823283,
                description: ''
            },
            {
                title: 'MS Collique 1',
                latitude: -11.928618,
                longitude: -77.057279,
                description: ''
            },
            {
                title: 'MS Corbeta 1',
                latitude: -12.146061,
                longitude: -77.006836,
                description: ''
            },
            {
                title: 'MS Cueva 7',
                latitude: -12.07737,
                longitude: -77.074462,
                description: ''
            },
            {
                title: 'MS Danoza 1',
                latitude: -11.88739,
                longitude: -77.124882,
                description: ''
            },
            {
                title: 'MS Del Muro',
                latitude: -11.944385,
                longitude: -76.990003,
                description: ''
            },
            {
                title: 'MS Jose Olaya 18',
                latitude: -11.839289,
                longitude: -77.126253,
                description: ''
            },
            {
                title: 'MS Las Torres 6',
                latitude: -12.017026,
                longitude: -76.898013,
                description: ''
            },
            {
                title: 'MS Mendiburi 12',
                latitude: -12.110321,
                longitude: -77.050118,
                description: ''
            },
            {
                title: 'MS Nicolini 2',
                latitude: -12.017596,
                longitude: -77.054796,
                description: ''
            },
            {
                title: 'MS Oman 1',
                latitude: -11.937953,
                longitude: -76.978075,
                description: ''
            },
            {
                title: 'MS Patriotas 8',
                latitude: -12.0738,
                longitude: -77.104957,
                description: ''
            },
            {
                title: 'MS Santa Rosa 17',
                latitude: -12.00125,
                longitude: -76.99966,
                description: ''
            },
            {
                title: 'MS Tristan 2',
                latitude: -12.073683,
                longitude: -76.939467,
                description: ''
            },
            {
                title: 'MS Unificado 1',
                latitude: -11.83318,
                longitude: -77.165192,
                description: ''
            },
            {
                title: 'MS Venus 3',
                latitude: -11.884565,
                longitude: -77.129643,
                description: ''
            },
            {
                title: 'MS Wisse 4',
                latitude: -11.93891,
                longitude: -76.977477,
                description: ''
            },
            {
                title: 'MS 12 De Octubre',
                latitude: -11.948867,
                longitude: -77.080651,
                description: ''
            },
            {
                title: 'MS Alameda 7',
                latitude: -12.198687,
                longitude: -77.012172,
                description: ''
            },
            {
                title: 'MS Campodonico 4',
                latitude: -12.086078,
                longitude: -77.020843,
                description: ''
            },
            {
                title: 'MS Constructores 2',
                latitude: -12.062788,
                longitude: -76.946513,
                description: ''
            },
            {
                title: 'MS Del Aire 7',
                latitude: -12.081695,
                longitude: -77.005822,
                description: ''
            },
            {
                title: 'MS Grau 1',
                latitude: -11.924844,
                longitude: -77.04144,
                description: ''
            },
            {
                title: 'MS Lima 3',
                latitude: -12.025912,
                longitude: -77.01568,
                description: ''
            },
            {
                title: 'MS Mariategui 51',
                latitude: -12.013475,
                longitude: -76.824949,
                description: ''
            },
            {
                title: 'MS Precursores 2',
                latitude: -12.071579,
                longitude: -77.090196,
                description: ''
            },
            {
                title: 'MS Proceres 39',
                latitude: -11.965354,
                longitude: -76.991145,
                description: ''
            },
            {
                title: 'MS Venezuela 50',
                latitude: -12.062948,
                longitude: -77.098382,
                description: ''
            },
            {
                title: 'MS Alvarado 6',
                latitude: -11.9551632,
                longitude: -77.0477591,
                description: ''
            },
            {
                title: 'MS Antares 5',
                latitude: -12.027244,
                longitude: -77.0381092,
                description: ''
            },
            {
                title: 'MS Bustamante 7',
                latitude: -12.0492949,
                longitude: -76.9723691,
                description: ''
            },
            {
                title: 'MS Caceres 2',
                latitude: -11.9306304,
                longitude: -77.0413028,
                description: ''
            },
            {
                title: 'MS Canta 31',
                latitude: -11.9911989,
                longitude: -77.1082702,
                description: ''
            },
            {
                title: 'MS Coralinas 6',
                latitude: -12.0091537,
                longitude: -77.0125352,
                description: ''
            },
            {
                title: 'MS Los Higos',
                latitude: -12.0383533,
                longitude: -76.9937698,
                description: ''
            },
            {
                title: 'MS Mangomarca 3',
                latitude: -12.0164337,
                longitude: -76.9855983,
                description: ''
            },
            {
                title: 'MS Mariategui 8',
                latitude: -12.1542759,
                longitude: -76.9545064,
                description: ''
            },
            {
                title: 'MS Metropolitana',
                latitude: -12.0332544,
                longitude: -76.9386237,
                description: ''
            },
            {
                title: 'MS Piramide 4',
                latitude: -12.0251792,
                longitude: -77.0041566,
                description: ''
            },
            {
                title: 'MS Virreyes 2',
                latitude: -12.0319208,
                longitude: -76.9494673,
                description: ''
            }
        ]);
    }

    public static getStores(): any[] {
        return [
            {
                id: "1",
                FolderPath: "Tiendas Mass",
                name: "VMachuca SJM MS",
                address: "AV. VARGAS MACHUCA N° 340, ZONA B. SAN JUAN DE MIRAFLORES.",
                reference: "A 2 CDRAS. DE PUENTE ALIPIO",
                district: "San Juan de Miraflores",
                latitude: -12.165747,
                longitude: -76.973953
            },
            {
                id: "2",
                FolderPath: "Tiendas Mass",
                name: "G Civil CLL MS",
                address: "AV. GUARDIA CIVIL 418. URB. PARCELACIÓN CAMPIÑA. CHORRILLOS.",
                reference: "FRENTE AL MERCADO SANTA ROSA",
                district: "Chorrillos",
                latitude: -12.183381,
                longitude: -76.999972
            },
            {
                id: "3",
                FolderPath: "Tiendas Mass",
                name: "SurcoViejoSC MS",
                address: "JR. LAS GAVIOTAS MZ G. LT. 20. URB. VILLA EL CONTADOR DE SURCO. SANTIAGO DE SURCO.",
                reference: "SECTOR G SUB SECTOR G1-LAS GAVIOTAS Y VISTA ALEGRE",
                district: "Santiago de Surco",
                latitude: -12.160179,
                longitude: -76.994265
            },
            {
                id: "4",
                FolderPath: "Tiendas Mass",
                name: "Ateca VMT MS",
                address: "JR. RÍMAC N° 259. MZ. 60. LT. 05. P.J. VILLA POETA -JOSE GALVEZ. VMT",
                reference: "ALT. DE LA CDRA. 10 DE LA AV. LIMA- JR. RIMAC 259 ",
                district: "Villa María del Triunfo",
                latitude: -12.212978,
                longitude: -76.90804
            },
            {
                id: "5",
                FolderPath: "Tiendas Mass",
                name: "V Alegre SC MS",
                address: "JR. GUARDIA CIVIL NORTE, MZ A, LOTE 10. URB. LOS PARRALES DE SURCO (J. PEREZ DE C) SANTIAGO DE SURCO",
                reference: "ENTRE GUARDIA CIVIL NORTE Y VISTA ALEGRE",
                district: "Santiago de Surco",
                latitude: -12.165461,
                longitude: -76.992243
            },
            {
                id: "6",
                FolderPath: "Tiendas Mass",
                name: "LaCabaña SJM MS",
                address: "JR. JUSTO NAVEDA N° 1190-ZONA D-URB. SAN JUAN. SAN JUAN DE MIRAFLORES.",
                reference: "ENTRE AV. SAN JUAN Y CIUDAD -JUSTO NAVEDA 1190",
                district: "San Juan de Miraflores",
                latitude: -12.154845,
                longitude: -76.971341
            },
            {
                id: "7",
                FolderPath: "Tiendas Mass",
                name: "Triunfo VMT MS",
                address: "AV. EL TRIUNFO N° 442. MZ. D1. LT. 03. PRIMER HOGAR POLICIAL A.C.U. VILLA MARÍA DEL TRIUNFO",
                reference: "ZONA 1 - AV TRIUNFO 442 CERCA A LA COMISARIA DE VILLA MARIA ",
                district: "Villa María del Triunfo",
                latitude: -12.165455,
                longitude: -76.947318
            },
            {
                id: "8",
                FolderPath: "Tiendas Mass",
                name: "Matellini CLL MS",
                address: "AV. ARIOSTO MATELLINI. URB. PASEO DE LA REPUBLICA. MZ. G. LT. 21. CHORRILLOS.",
                reference: "A 1 CUADRA DE LA ESTACION DE MATELLINI - METROPOLITANO",
                district: "Chorrillos",
                latitude: -12.178782,
                longitude: -77.009085
            },
            {
                id: "9",
                FolderPath: "Tiendas Mass",
                name: "Iglesias VES MS",
                address: "SECTOR 01. GRUPO 18. MZNA. J. LT. 15. VILLA EL SALVADOR.",
                reference: "GRUPO RESIDENCIAL 16 SECTOR PRIMERO-AV. MODELO Y AV. MICAELA BASTIDAS",
                district: "Villa El Salvador",
                latitude: -12.192666,
                longitude: -76.955563
            },
            {
                id: "10",
                FolderPath: "Tiendas Mass",
                name: "Canevaro SJM MS",
                address: "PJ. 13 DE OCTUBRE. MZ. G. LT. 8. PAMPAS DE SAN JUAN. SAN JUAN DE MIRAFLORES",
                reference: "CERCA AL MERCADO VALLE SHARON",
                district: "San Juan de Miraflores",
                latitude: -12.174525,
                longitude: -76.966944
            },
            {
                id: "11",
                FolderPath: "Tiendas Mass",
                name: "Credito SJM MS",
                address: "AV. PROLONG. CESAR CANEVARO. MZ. N. LT. 20. URB. VALLE SARON. SAN JUAN DE MIRAFLORES",
                reference: "CERCA AL MERCADO VALLE SHARON",
                district: "San Juan de Miraflores",
                latitude: -12.170069,
                longitude: -76.968101
            },
            {
                id: "12",
                FolderPath: "Tiendas Mass",
                name: "27Diciem VMT MS",
                address: "AV. 27 DE DICIEMBRE 968. MZ. 4R. SUB LOTE 2A. SEC. 2DO. PJ TABLADA DE LURÍN. VMT.",
                reference: "PARADERO 18 DE LA AV. 27 DE DICIEMBRE , ANTES DEL MERCADO CENTRAL DE TABLADA",
                district: "Villa María del Triunfo",
                latitude: -12.189108,
                longitude: -76.92738
            },
            {
                id: "13",
                FolderPath: "Tiendas Mass",
                name: "Pachacamac LU MS",
                address: "AV. ANTIGUA PANAMERICANA SUR MZ X LT 01. PACHACAMAC",
                reference: "REF. ALTURA DEL CRUCE LURIN-ANTIGUA PANAMERICANA SUR KM 37-PACHACAMAC",
                district: "Pachacamac",
                latitude: -12.260347,
                longitude: -76.884129
            },
            {
                id: "14",
                FolderPath: "Tiendas Mass",
                name: "Proceres SC MS",
                address: "AV. LOS PROCERES MZ. B. LT. 24. URB. HONOR Y LEALTAD. SANTIAGO DE SURCO.",
                reference: "FRENTE A TOTTUS",
                district: "Santiago de Surco",
                latitude: -12.158981,
                longitude: -76.988427
            },
            {
                id: "15",
                FolderPath: "Tiendas Mass",
                name: "Cedros CLL MS",
                address: "AV. ALAMEDA LOS CEDROS. URB. LOS CEDROS DE VILLA. MZ. D-09. LT. 23. CHORILLOS",
                reference: "MEDIA CDRA. ESTACION DE SERENAZGO LOCAL 4 PISOS ",
                district: "Chorrillos",
                latitude: -12.203714,
                longitude: -77.014609
            },
            {
                id: "16",
                FolderPath: "Tiendas Mass",
                name: "SPedro CLL MS",
                address: "AV. SAN MARTIN MZ. H-03. LT. 12. AAHH. TUPAC AMARU DE VILLA. CHORRILLOS",
                reference: "FRENTE AL CENTRO COMERCIAL SAN PEDRO",
                district: "Chorrillos",
                latitude: -12.197272,
                longitude: -76.981178
            },
            {
                id: "17",
                FolderPath: "Tiendas Mass",
                name: "24Junio VES MS",
                address: "SECTOR 02. GRUPO 06. MANZANA N. LOTE 16. VILLA EL SALVADOR.",
                reference: "FRENTE AL MERCADO 24 DE JUNIO",
                district: "Villa El Salvador",
                latitude: -12.205819,
                longitude: -76.940956
            },
            {
                id: "18",
                FolderPath: "Tiendas Mass",
                name: "Ejercito MA MS",
                address: "AV. DEL EJERCITO N° 375. MAGDALENA DEL MAR.",
                reference: "ENTRE EL CRUCE DE LA AV. BRASIL Y AV. EL EJERCITO-EX PEREZ ARANIBAL",
                district: "Magdalena del Mar",
                latitude: -12.097799,
                longitude: -77.069585
            },
            {
                id: "19",
                FolderPath: "Tiendas Mass",
                name: "Paraiso PA MS",
                address: "JR. PARAISO. MZ. 5. LT. 04. SECTOR. CERCADO. PACHACAMAC",
                reference: "UNA DE LAS ESQUINAS DE PLAZA PACHACAMAC",
                district: "Pachacamac",
                latitude: -12.229748,
                longitude: -76.860171
            },
            {
                id: "20",
                FolderPath: "Tiendas Mass",
                name: "13Agosto VES MS",
                address: "AMR. IV. ETAPA S.2. P.H. PACHACAMAC. MANZANA R. LT. 03. VILLA EL SALVADOR",
                reference: "A DOS CUADRAS DEL CRUCE AV. REVOLUCION CON AV. MARIA REICHE ",
                district: "Villa El Salvador",
                latitude: -12.244121,
                longitude: -76.919433
            },
            {
                id: "21",
                FolderPath: "Tiendas Mass",
                name: "Capilla SJM MS",
                address: "PP.JJ. HEROES DE SAN JUAN. MZ. I-1. LT. 15. PLAN INTEGRAL PAMPAS DE SAN JUAN. SAN JUAN DE MIRAFLORES.",
                reference: "FRENTE AL MERCADO MIGUEL IGLESIAS",
                district: "San Juan de Miraflores",
                latitude: -12.173874,
                longitude: -76.96262
            },
            {
                id: "22",
                FolderPath: "Tiendas Mass",
                name: "Progresol VES MS",
                address: "SECTOR 01. GRUPO 22A. MZ. I. LT. 01. VILLA EL SALVADOR",
                reference: "AL FINALIZAR EL HOSPITAL DE LA SOLIDARIDAD EN VILLA EL SALVADOR ",
                district: "Villa El Salvador",
                latitude: -12.197679,
                longitude: -76.957447
            },
            {
                id: "23",
                FolderPath: "Tiendas Mass",
                name: "IncaRoca VMT MS",
                address: "JR. ALFONSO UGARTE N° 1099. MZ. 6T. LT. 7C. P.J. SAN FCO. DE TABLADA DE LURÍN. VILLA MARÍA DEL TRIUNFO.",
                reference: "FRENTE AL MERCADO 27 DE DICIEMBRE ",
                district: "Villa María del Triunfo",
                latitude: -12.187498,
                longitude: -76.926668
            },
            {
                id: "24",
                FolderPath: "Tiendas Mass",
                name: "VMaria VMT MS",
                address: "AV. VILLA MARIA 592. LT. 15. MZ H5. PRIMER HOGAR POLICIAL. A.C.U. CERCADO. VILLA MARÍA DEL TRIUNFO",
                reference: "FRENTE AL MERCADO SAN FRANCISCO",
                district: "Villa María del Triunfo",
                latitude: -12.16374,
                longitude: -76.946691
            },
            {
                id: "25",
                FolderPath: "Tiendas Mass",
                name: "Estacion VMT MS",
                address: "CL. LOS ROBLES N° 107. MZ. 8. LT. 01-URB. JARDIN 2 Y 3 ZONA. VILLA MARÍA DEL TRIUNFO.",
                reference: "ALTURA DE LA ESTACION VILLA MARIA, TREN ELECTRICO",
                district: "Villa María del Triunfo",
                latitude: -12.168826,
                longitude: -76.950946
            },
            {
                id: "26",
                FolderPath: "Tiendas Mass",
                name: "1roMayo VES MS",
                address: "COOP. JOSE MARÍA ARGUEDAS. MZA. B. LT. 22. VILLA EL SALVADOR.",
                reference: "A DOS CDRAS DEL MERCADO UNICACHI DE VILLA ELSALVADOOR ",
                district: "Villa El Salvador",
                latitude: -12.197774,
                longitude: -76.966512
            },
            {
                id: "27",
                FolderPath: "Tiendas Mass",
                name: "HdaVilla CLL MS",
                address: "AV. DEFENSORES DEL MORRO (HUAYLAS) 448. URB. CHORRILLOS. CIUDAD. CHORRILLOS.",
                reference: "COSTADO DEL BANCO CONTINENTAL",
                district: "Chorrillos",
                latitude: -12.16833,
                longitude: -77.025081
            },
            {
                id: "28",
                FolderPath: "Tiendas Mass",
                name: "ParqVilla LU MS",
                address: "MZ. H. LT. 5. VILLA ALEJANDRO. LURIN.",
                reference: "FRENTE A PARQUE VILLA ALEJANDRO DE LURIN ",
                district: "Lurin",
                latitude: -12.235867,
                longitude: -76.910443
            },
            {
                id: "29",
                FolderPath: "Tiendas Mass",
                name: "Caceres4 CB MS",
                address: "A.H. RAFAEL PORRAS BARRENECHEA - AVELINO CACERES, ANDRES 497 - MZ 51 LT SUB 11 A - CARABAYLLO ",
                reference: "A UNA CUADRA DE PLAZA DE ARMAS DE CARABAYLLO /CRUCE DE CL MARISCAL CACERES Y CL ALFONSO UGARTE",
                district: "Carabayllo",
                latitude: -11.89176713,
                longitude: -77.02273986
            },
            {
                id: "30",
                FolderPath: "Tiendas Mass",
                name: "San Bartolo MS",
                address: "CALLE LOS BARCOS MZ. K. LOTE 09. SAN BARTOLO.",
                reference: "FRENTE AL MERCADO DE SAN BARTOLO",
                district: "San Bartolo",
                latitude: -12.389328,
                longitude: -76.778555
            },
            {
                id: "31",
                FolderPath: "Tiendas Mass",
                name: "Lurin LU MS",
                address: "CALLE ANTIGUA PANAMERICANA MZ. E. LT. 02. URB. LAS VIRREYNAS. LURIN.",
                reference: "REFERENCIA FRENTE AL MERCADO VIRGEN DE LA MERCEDES AL COSTADO DE INKAFARMA)",
                district: "Lurin",
                latitude: -12.277606,
                longitude: -76.869069
            },
            {
                id: "32",
                FolderPath: "Tiendas Mass",
                name: "Plaza PA MS",
                address: "AV. PAUL POBLET LIND MZ. 45, LT. 03. CERCADO. PACHACAMAC.",
                reference: "Entrada de Pachacamac, entre  Mi farma y Mi Banco.",
                district: "Pachacamac",
                latitude: -12.227456,
                longitude: -76.862691
            },
            {
                id: "33",
                FolderPath: "Tiendas Mass",
                name: "JMorales SJM MS",
                address: "AV. JESUS MORALES N° 480-482. SAN JUAN DE MIRAFLORES.",
                reference: "Cruce de Av Vargas Machuca con Av Jesus Morales. A Una cuadra del banco de La Nación",
                district: "San Juan de Miraflores",
                latitude: -12.166598,
                longitude: -76.97429
            },
            {
                id: "34",
                FolderPath: "Tiendas Mass",
                name: "Benavides SC MS",
                address: "AV. ALFREDO BENAVIDES 4562. URB. VISTA ALEGRE. SANTIAGO DE SURCO",
                reference: "A UNA CUADRA DEL TRIGAL DE SURCO CRUCE VELASCO ASTETE Y BENAVIDES ",
                district: "Santiago de Surco",
                latitude: -12.128184,
                longitude: -76.989043
            },
            {
                id: "35",
                FolderPath: "Tiendas Mass",
                name: "LUmbrosa SC MS",
                address: "JR. LOMA UMBROSA 443/445. MZ. O2. LT. 16. URB. PROLONG. BENAVIDES. SANTIAGO DE SURCO.",
                reference: "CRUCE AV. MONTE DE LOS OLIVOS",
                district: "Santiago de Surco",
                latitude: -12.139099,
                longitude: -76.988054
            },
            {
                id: "36",
                FolderPath: "Tiendas Mass",
                name: "Palmeras LO MS",
                address: "AV. LAS PALMERAS N° 3888. MZ. E. LT. 17. URB. LAS PALMERAS. I ETAPA. LOS OLIVOS.",
                reference: "A UNA CUADRA DEL MUNICIPIO DE LOS OLIVOS",
                district: "Los Olivos",
                latitude: -11.989452,
                longitude: -77.07172
            },
            {
                id: "37",
                FolderPath: "Tiendas Mass",
                name: "BSuarez SJM MS",
                address: "AV. BELISARIO SUAREZ N° 354. MZ.C. LT. 06. SAN JUAN MIRAFLORES.",
                reference: "A UNA CUADRA DEL COLEGIO MARISTAS ALT. CUADRA 7 DE LA AV. SAN JUAN",
                district: "San Juan de Miraflores",
                latitude: -12.160549,
                longitude: -76.975114
            },
            {
                id: "38",
                FolderPath: "Tiendas Mass",
                name: "Primavera VMT MS",
                address: "AV. PRIMAVERA N° 2384. MZ. 117. LT 09. SECTOR VALLECITO ALTO. VILLA MARÍA DEL TRIUNFO.",
                reference: "Altura CUADRA 23 JOSE CARLOS MAREATEGUI",
                district: "Villa María del Triunfo",
                latitude: -12.141964,
                longitude: -76.949706
            },
            {
                id: "39",
                FolderPath: "Tiendas Mass",
                name: "Mexico CO MS",
                address: "AV. UNIVERSITARIA NORTE MZ G LT 09. URB. LAS VEGAS. (ASC. PROV. VIV. TRAB. UNIDOS. VITARTE). COMAS",
                reference: "CRUCE AV. MEXICO CON AV. UNIVERSITARIA",
                district: "Comas",
                latitude: -11.955146,
                longitude: -77.059624
            },
            {
                id: "40",
                FolderPath: "Tiendas Mass",
                name: "Covida LO MS",
                address: "AV. SANTIAGO ANTUNEZ DE MAYOLO N° 1259, URB. COVIDA. I ETAPA. LOS OLIVOS.",
                reference: "A UNA CUADRA Y MEDIA DEL MERCADO DE COVIDA",
                district: "Los Olivos",
                latitude: -11.99546,
                longitude: -77.078198
            },
            {
                id: "41",
                FolderPath: "Tiendas Mass",
                name: "Roox VES MS",
                address: "SECTOR 03. GRUPO 07. MANZANA A. LOTE 09. VILLA EL SALVADOR.",
                reference: "GRUPO RESIDENCIAL 7 SECTOR TERCEROENTRE LA AV. CENTRAL Y VALLEJO EN VILLA EL SALVADOR",
                district: "Villa El Salvador",
                latitude: -12.212351,
                longitude: -76.934506
            },
            {
                id: "42",
                FolderPath: "Tiendas Mass",
                name: "Vidreria VES MS",
                address: "SECTOR 02. GRUPO 19. MANZANA N. LOTE 06. VILLA EL SALVADOR.",
                reference: "MICAELA BASTIDAS CON VELASCO ALVARADO",
                district: "Villa El Salvador",
                latitude: -12.211363,
                longitude: -76.944315
            },
            {
                id: "43",
                FolderPath: "Tiendas Mass",
                name: "MOchenta CO MS",
                address: "AV. TUPAC AMARU 4492-4496. P.J. PAMPA DE COMAS. COMAS",
                reference: "FRENTE AL CENTRO COMERCIAL MEGA 80",
                district: "Comas",
                latitude: -11.962186,
                longitude: -77.054286
            },
            {
                id: "44",
                FolderPath: "Tiendas Mass",
                name: "SanJuan VES MS",
                address: "SECTOR 01. GRUPO 25. MZNA. F. LT. 17. VILLA EL SALVADOR",
                reference: "CRUCE DE AV. EL SOL CON AV. MICAELA BASTIDAS",
                district: "Villa El Salvador",
                latitude: -12.203249,
                longitude: -76.949716
            },
            {
                id: "45",
                FolderPath: "Tiendas Mass",
                name: "EstDePro LO MS",
                address: "CALLE LA REFLEXIÓN. (EX. CA. 11) MZ. BBB5.LT.09. URB. PRO 5. SECTOR II ETAPA. LOS OLIVOS.",
                reference: "AL COSTADO DE LA COMISARIO DE PRO-PLAZA DE ARMAS DE PRO",
                district: "Los Olivos",
                latitude: -11.935524,
                longitude: -77.077122
            },
            {
                id: "46",
                FolderPath: "Tiendas Mass",
                name: "TupacA 10 CO MS",
                address: "AV. TUPAC AMARU 1004. P.J. PAMPA DE COMAS. COMAS",
                reference: "KM 10.5 AV. TUPAC AMARU ( AV. MEXICO CON TUPAC AMARU )",
                district: "Comas",
                latitude: -11.957762,
                longitude: -77.052801
            },
            {
                id: "47",
                FolderPath: "Tiendas Mass",
                name: "Oasis VES MS",
                address: "AH.HH. OASIS DE VILLA, GR. 04. MANZANA A. LOTE 05. VILLA EL SALVADOR.",
                reference: "AFINALIZANDO LA RUTA C-UNA CUADRA DEL OVALO OASIS ( OVALO 200 MILLAS )",
                district: "Villa El Salvador",
                latitude: -12.237943,
                longitude: -76.93682
            },
            {
                id: "48",
                FolderPath: "Tiendas Mass",
                name: "Separad 3 VES MS",
                address: "SECTOR 02. GRUPO 04. MANZANA E. LOTE 11. VILLA EL SALVADOR.",
                reference: "ULTIMA ESTACION DEL PARADERO DEL TREN ELECTRICO",
                district: "Villa El Salvador",
                latitude: -12.207396,
                longitude: -76.933961
            },
            {
                id: "49",
                FolderPath: "Tiendas Mass",
                name: "Aviacion 32 SB MS",
                address: "AV. AVIACION 3287. AIRES. TDA. MZ. A-2. LT.11. URB. LAS MAGNOLIAS. SAN BORJA",
                reference: "CDRA. 32 DE LA AV. AVIACION",
                district: "San Borja",
                latitude: -12.102989,
                longitude: -77.001219
            },
            {
                id: "50",
                FolderPath: "Tiendas Mass",
                name: "SanJuan 9 SJM MS",
                address: "AV. SAN JUAN  933. MZ. G2. LT. 08. ZONA A. URB. SAN JUAN. SAN JUAN DE MIRAFLORES",
                reference: "FRENTE AL COLEGIO MARISTA",
                district: "San Juan de Miraflores",
                latitude: -12.158056,
                longitude: -76.973424
            },
            {
                id: "51",
                FolderPath: "Tiendas Mass",
                name: "Univer 65 LO MS",
                address: "AV. UNIVERSITARIA NORTE. MZ. A. LT. 02. ASOC. LOS OLIVOS. (RESIDENCIAL) LOS OLIVOS.",
                reference: "ENTRE AV. MARAÑON Y UNIVERSITARIA",
                district: "Los Olivos",
                latitude: -11.972305,
                longitude: -77.075556
            },
            {
                id: "52",
                FolderPath: "Tiendas Mass",
                name: "Salvad 18 VES MS",
                address: "AGR. PACHACAMAC, S4,  ETAPA SEGUNDA, MANZANA L. LOTE 20. VILLA EL SALVADOR",
                reference: "FRENTE AL MERCADO PACHACAMAC",
                district: "Villa El Salvador",
                latitude: -12.231451,
                longitude: -76.922425
            },
            {
                id: "53",
                FolderPath: "Tiendas Mass",
                name: "2Octubre 10 LO MS",
                address: "AV. 02 DE OCTUBRE, MANZANA J, LOTE 23. URB. SANTA ANA. LOS OLIVOS.",
                reference: "CRUCE DE AV. BETANCOUR Y AV. 2 DE OCTUBRE",
                district: "Los Olivos",
                latitude: -11.948934,
                longitude: -77.080146
            },
            {
                id: "54",
                FolderPath: "Tiendas Mass",
                name: "SanPedro 2 CLL MS",
                address: "AV. TUPAC AMARU AAHH TUPAC AMARU DE VILLA. MZ. E.-05. LT. 02. CHORRILLOS",
                reference: "FRENTE A PARQUE TUPAC AMARU DE VILLA ",
                district: "Chorrillos",
                latitude: -12.195516,
                longitude: -76.983609
            },
            {
                id: "55",
                FolderPath: "Tiendas Mass",
                name: "PastorS 1 VES MS",
                address: "SECTOR 06, GRUPO 07. MANZANA N. LOTE 05. VILLA EL SALVADOR.",
                reference: "CRUCE DE LAS AV. PASTOR SEVILLA Y BOLIVAR ",
                district: "Villa El Salvador",
                latitude: -12.221351,
                longitude: -76.944228
            },
            {
                id: "56",
                FolderPath: "Tiendas Mass",
                name: "Naranjal 12 LO MS",
                address: "JR. HUACLLAN, MANZANA E2, LOTE 1, AAHH LOS JASMINEZ DEL NARANJAL. LOS OLIVOS.",
                reference: "AL COSTADO DEL MERCADO LOS PRODUCTORES",
                district: "Los Olivos",
                latitude: -11.979775,
                longitude: -77.080206
            },
            {
                id: "57",
                FolderPath: "Tiendas Mass",
                name: "Yupanqui 5 IN MS",
                address: "JR. INCA YUPANQUI 570. URB. TAHUANTINSUYO.",
                reference: "AL COSTADO DEL MERCADO CENTRAL DE LA 2DA. ZONA ",
                district: "Independencia",
                latitude: -11.977879,
                longitude: -77.047417
            },
            {
                id: "58",
                FolderPath: "Tiendas Mass",
                name: "Granda 30 SMP MS",
                address: "AV. UNIVERSITARIA NORTE N° 301. MZ. B-10.LT 07. URB. CONDEVILLA SEÑOR. SAN MARTIN DE PORRES.",
                reference: "UNIVERSITARIA CON JOSE GRANDA",
                district: "San Martín de Porres",
                latitude: -12.025643,
                longitude: -77.075474
            },
            {
                id: "59",
                FolderPath: "Tiendas Mass",
                name: "Vipol SMP MS",
                address: "MZ. I. LT.7. COOP.VIV. BATA CALLAO-PARCELA 1. SAN MARTIN DE PORRES.",
                reference: "FRENTA A MERCADO VIPOL ESPALDA AV. TANTAMAYO CERCA A HUANDOY",
                district: "San Martín de Porres",
                latitude: -11.966898,
                longitude: -77.089618
            },
            {
                id: "60",
                FolderPath: "Tiendas Mass",
                name: "Mayolo 17 SMP MS",
                address: "AV. ANTUNEZ DE MAYOLO MZ. C. LT. 1. ASOC. VIV. EX FUNDO SANTA ROSA. SAN MARTIN DE PORRES.",
                reference: "CRUCE DE AV. MAYOLO Y UNIVERSITARIA ",
                district: "San Martín de Porres",
                latitude: -11.996586,
                longitude: -77.085588
            },
            {
                id: "61",
                FolderPath: "Tiendas Mass",
                name: "Lavalle 1 SJM MS",
                address: "JR. HERNANDO LAVALLE N° 192. ZONA K. CIUDAD DE DIOS. SAN JUAN DE MIRAFLORES.",
                reference: "FRENTE A LA PUERTA DEL MERCADO CIUDAD DE DIOS SAN JUAN DE MIRAFLORES",
                district: "San Juan de Miraflores",
                latitude: -12.152983,
                longitude: -76.97049
            },
            {
                id: "62",
                FolderPath: "Tiendas Mass",
                name: "Mariateg 2 VES MS",
                address: "SECTOR 03. GRUPO 10. MANZANA N. LOTE 05. VILLA EL SALVADOR",
                reference: "CRUCE DE LA AV. REVOLUCION CON MARIATEGUI",
                district: "Villa El Salvador",
                latitude: -12.221151,
                longitude: -76.931913
            },
            {
                id: "63",
                FolderPath: "Tiendas Mass",
                name: "Huandoy 2 LO MS",
                address: "AV. LOS PROCERES MZ. D4. LT. 1. URB. PRO. 4TO SECTOR I. ETAPA. LOS OLIVOS",
                reference: "CRUCE DE PROCERES DE HUANDOY CON AV. CORDIALIDAD",
                district: "Los Olivos",
                latitude: -11.939609,
                longitude: -77.077249
            },
            {
                id: "64",
                FolderPath: "Tiendas Mass",
                name: "Villasol LO MS",
                address: "AV. CENTRAL MZ. 70. LT. 5. AAHH. LAURA CALLER IBERICO. LOS OLIVOS.",
                reference: "AV. CENTRAL MZ. 70 AA.HH. CALLER IBERICO",
                district: "Los Olivos",
                latitude: -11.959342,
                longitude: -77.075081
            },
            {
                id: "65",
                FolderPath: "Tiendas Mass",
                name: "Unger 33 IN MS",
                address: "AV. GERARDO UNGER 3371-3373. URB. IND. PAN. NORTE",
                reference: "CUADRA 33 DE UNGER FRENTE A LA MUNICIPALIDAD DE INDEPENDENCIA. ",
                district: "Independencia",
                latitude: -11.99749,
                longitude: -77.055611
            },
            {
                id: "66",
                FolderPath: "Tiendas Mass",
                name: "Carrion12 SMP MS",
                address: "MZ. J. LT. 18. ASOC. VIV. LOS CHASQUIS II ETAPA. SAN MARTIN DE PORRES.",
                reference: "CRUCE AV. CARLOS IZAGUIRRE PARADERO PUENTE CAMOTE",
                district: "San Martín de Porres",
                latitude: -11.990948,
                longitude: -77.09693
            },
            {
                id: "67",
                FolderPath: "Tiendas Mass",
                name: "Olivos 6 SMP MS",
                address: "AV. LOS OLIVOS MZ. G. LT. 4-A.ASOC. VIV. SEÑOR DE LOS MILAGROS.",
                reference: "UNIVERSITARIA CON AV. SANTA ROSA, PARADERO 5 FRENTE AL MERCASO VIRGEN DE FATIMA",
                district: "San Martín de Porres",
                latitude: -11.986783,
                longitude: -77.087586
            },
            {
                id: "68",
                FolderPath: "Tiendas Mass",
                name: "ACanta SMP MS",
                address: "AV. CANTA, MANZANA V8, LOTE 02, URB. CONDEVILLA SEÑOR II ETAPA. SAN MARTIN DE PORRES.",
                reference: "FRENTE MERCADO VIRGEN DE FATIMA ",
                district: "San Martín de Porres",
                latitude: -12.020272,
                longitude: -77.082289
            },
            {
                id: "69",
                FolderPath: "Tiendas Mass",
                name: "Izagui 14 LO MS",
                address: "AV. CARLOS ALBERTO IZAGUIRRE. MANZANA J, LOTE 6. URB. CAJABAMBA. LOS OLIVOS.",
                reference: "CRUCE AV. UNIVERSITARIA Y CARLOS IZAGUIRRE ",
                district: "Los Olivos",
                latitude: -11.991821,
                longitude: -77.081324
            },
            {
                id: "70",
                FolderPath: "Tiendas Mass",
                name: "26Nov 19 VMT MS",
                address: "JR. SAN PEDRO N° 118. MZ.2. LT.2-PJ. VIRGEN DE LOURDES ETAPA PRIMERA. VMT.",
                reference: "ALTURA DEL CEMENTERIO DE NUEVA ESPERANZA AV. 26 DE NOVIEMBRE CDRA. 12",
                district: "Villa María del Triunfo",
                latitude: -12.16713,
                longitude: -76.919823
            },
            {
                id: "71",
                FolderPath: "Tiendas Mass",
                name: "Mendibu 12 MI MS",
                address: "CALLE GENERAL MENDIBURU N° 1203. MIRAFLORES.",
                reference: "CALLE SAN DOMINGO CHOQUEHUANCA #283",
                district: "Miraflores",
                latitude: -12.110266,
                longitude: -77.050005
            },
            {
                id: "72",
                FolderPath: "Tiendas Mass",
                name: "BAires 4 PP MS",
                address: "AV. BUENOS AIRES N° 369-371. PUENTE PIEDRA",
                reference: "ALAMEDA PUENTE PIEDRA A MEDIA CUADRA DE LA MUNICIPALIDAD PUENTE PIEDRA",
                district: "Puente Piedra",
                latitude: -11.865492,
                longitude: -77.078304
            },
            {
                id: "73",
                FolderPath: "Tiendas Mass",
                name: "Algarrob 1 PP MS",
                address: "URBANIZACIÓN LOS PORTALES DE CHILLON. MZ. C1. LT. 16. PUENTE PIEDRA.",
                reference: "PASANDO EL PUENTE DE LA AV. CORDIALIDAD RIO CHILLON",
                district: "Puente Piedra",
                latitude: -11.935427,
                longitude: -77.08351
            },
            {
                id: "74",
                FolderPath: "Tiendas Mass",
                name: "VillaSur VES MS",
                address: "SECTOR 03. GRUPO 07. MANZANA B. LOTE 13. VILLA EL SALVADOR.",
                reference: "FRENTE A LA UNIVERSIDAD TECNOLOGICA Y MERCADO PLAZA VILLA SUR",
                district: "Villa El Salvador",
                latitude: -12.213095,
                longitude: -76.933725
            },
            {
                id: "75",
                FolderPath: "Tiendas Mass",
                name: "Escardo SM MS",
                address: "AV. RAFAEL ESCARDO N° 194. URB. MARANGA. 1ERA ETAPA. SAN MIGUEL.",
                reference: "AV LA PAZ CDRA. 14, AV. LA MARINA - HIRAOKA",
                district: "San Miguel",
                latitude: -12.081589,
                longitude: -77.095192
            },
            {
                id: "76",
                FolderPath: "Tiendas Mass",
                name: "German 3 SMP MS",
                address: "AV. GERMAN AGUIRRE 380. MZ. S. LT. 23-24. URB. SAN GERMAN",
                reference: "CRUCE AV. SAN GERMAN Y AV. TOMAS VALLE",
                district: "San Martín de Porres",
                latitude: -12.011668,
                longitude: -77.07073
            },
            {
                id: "77",
                FolderPath: "Tiendas Mass",
                name: "Gamarra 7 LO MS",
                address: "AV. ANGELICA GAMARRA DE LEON VELARDE. MANZANA D, LOTE 7, 1 Y 2 PISO. URB. EL TREBOL III ETAPA. LOS OLIVOS",
                reference: "TERCERA ETAPA DE LA URB, EL TREBOL ",
                district: "Los Olivos",
                latitude: -12.006092,
                longitude: -77.070559
            },
            {
                id: "78",
                FolderPath: "Tiendas Mass",
                name: "Recavar11 SU MS",
                address: "AV. RECAVARREN, GRAL. FEDERICO 1164-1168. SURQUILLO.",
                reference: "CERCA AL MERCADO 2 DE SURQUILLO",
                district: "Surquillo",
                latitude: -12.109627,
                longitude: -77.024488
            },
            {
                id: "79",
                FolderPath: "Tiendas Mass",
                name: "Ollantay SJM MS",
                address: "ST. OLLANTAY, MZ. R-3LT.19.PAMPLONA ALTA. SAN JUAN DE MIRAFLORES.",
                reference: "SECTOR OLLANTA EN PAMPLONA ALTA ",
                district: "San Juan de Miraflores",
                latitude: -12.137093,
                longitude: -76.962998
            },
            {
                id: "80",
                FolderPath: "Tiendas Mass",
                name: "Angamos 25 SU MS",
                address: "AV. ANGAMOS ESTE N° 2580. SURQUILLO.",
                reference: "FRENTE AL REAL PLAZA DE ANGAMOS",
                district: "Surquillo",
                latitude: -12.112124,
                longitude: -77.001247
            },
            {
                id: "81",
                FolderPath: "Tiendas Mass",
                name: "Brigida 3 SM MS",
                address: "AV. BRIGIDA SILVA DE OCHOA N° 100 (MZ. N2-2. LOTE 01), URB. PANDO 2DA ETAPA. SAN MIGUEL.",
                reference: "AV. BRIGADA SILVA ESPALDA DE TOTTUS ",
                district: "San Miguel",
                latitude: -12.084406,
                longitude: -77.089862
            },
            {
                id: "82",
                FolderPath: "Tiendas Mass",
                name: "Libertad 15 SM MS",
                address: "JR. LIBERTAD 1538. URB. MARANGA. 1ERA ETAPA. SAN MIGUEL",
                reference: "AV LIBERTAD 1538 CRUCE CON ESCARDO-FRENTE MERCADO LA LIBERTAD",
                district: "San Miguel",
                latitude: -12.082696,
                longitude: -77.096221
            },
            {
                id: "83",
                FolderPath: "Tiendas Mass",
                name: "Shangrilla PP MS",
                address: "PARCELACIÓN SEMIRUSTICA SHANGRILLA CALLE LAS FLORES. PUENTE PIEDRA.",
                reference: "FRENTE A LOS CONDOMIIOS VALLE ALTO SHANGRILLA",
                district: "Puente Piedra",
                latitude: -11.916172,
                longitude: -77.078245
            },
            {
                id: "84",
                FolderPath: "Tiendas Mass",
                name: "Nazarenas 2 SC MS",
                address: "AV. LAS NAZARENAS 289.  MZ. B1. LT. 31. URB. PROLONG. BENAVIDES. SANTIAGO DE SURCO.",
                reference: "ENTRE AV. CAMINOS DEL INCA Y AV. ASTETE",
                district: "Santiago de Surco",
                latitude: -12.131367,
                longitude: -76.987257
            },
            {
                id: "85",
                FolderPath: "Tiendas Mass",
                name: "Univer 20 SM MS",
                address: "AV. UNIVERSITARIA 2033 (MZ. A4. LT. 06) URB. PANDO. SAN MIGUEL.",
                reference: "AL COSTADO DEL COLEGIO CAROL WOGTILA",
                district: "San Miguel",
                latitude: -12.0639,
                longitude: -77.078583
            },
            {
                id: "86",
                FolderPath: "Tiendas Mass",
                name: "SanDiego SMP MS",
                address: "JR. MARIA DE LOS ANGELES, MANZANA N1, LOTE 02. URB. SAN DIEGO. SAN MARTIN DE PORRES.",
                reference: "PANAMERICANA NORTE ALTURA DE PRIMERA DE PRO, CERCA AL MERCADO SAN DIEGO",
                district: "San Martín de Porres",
                latitude: -11.945621,
                longitude: -77.087908
            },
            {
                id: "87",
                FolderPath: "Tiendas Mass",
                name: "Zarumilla 8 SMP MS",
                address: "AV. ZARUMILLA 866-868. URB. B. M. ZARUMILLA DERECHA. SAN MARTIN DE PORRES.",
                reference: "AV. ZARUMILLA ZONA DERECHA ",
                district: "San Martín de Porres",
                latitude: -12.033159,
                longitude: -77.053367
            },
            {
                id: "88",
                FolderPath: "Tiendas Mass",
                name: "Almendra 2 SMP MS",
                address: "AV. LAS ALMENDRAS N° 202, MANZANA L, LOTE 17. SAN MARTIN DE PORRES.",
                reference: "A UNA CDRA. DEL MERCADO ALMENDRAS ",
                district: "San Martín de Porres",
                latitude: -11.98554,
                longitude: -77.058916
            },
            {
                id: "89",
                FolderPath: "Tiendas Mass",
                name: "Villaran 7 SU MS",
                address: "AV. VILLARÁN MANUEL N° 703.MZ. C.LT.11. SURQUILLO.",
                reference: "CRUCE CALLE DARTNELL A 2 CDRAS TOMAS MARSANO",
                district: "Surquillo",
                latitude: -12.121127,
                longitude: -77.004455
            },
            {
                id: "90",
                FolderPath: "Tiendas Mass",
                name: "JC Tello 6 LI MS",
                address: "AV. TELLO JULIO CESAR 0602 - 0610. LINCE",
                reference: "AV. JULIO C. TELLO Y JR. FRANCISCO DE ZELA",
                district: "Lince",
                latitude: -12.086375,
                longitude: -77.039679
            },
            {
                id: "91",
                FolderPath: "Tiendas Mass",
                name: "Centenario 2 BR MS",
                address: "JR. CENTENARIO 252. BREÑA",
                reference: "RAMBLA BREÑA AV.BRASIL",
                district: "Breña",
                latitude: -12.065944,
                longitude: -77.049128
            },
            {
                id: "92",
                FolderPath: "Tiendas Mass",
                name: "SPaulo 18 SMP MS",
                address: "JR. SAO PAULO N° 1280. MZ. 36. LT. 22. URB. PERU. SAN MARTIN DE PORRES.",
                reference: "AV. CANADA CON JR. SAO PAULO A 3 CDRAS. DEL CRUCE DE AV. PERU Y DUEÑAS",
                district: "San Martín de Porres",
                latitude: -12.030461,
                longitude: -77.064603
            },
            {
                id: "93",
                FolderPath: "Tiendas Mass",
                name: "Loreto 3 BR MS",
                address: "JR. LORETO 355. BREÑA",
                reference: "FRENTE AL MERCADO CHACRA COLORADA ALT. CDRA. 11 DE AV. VENEZUELA ",
                district: "Breña",
                latitude: -12.05324,
                longitude: -77.049255
            },
            {
                id: "94",
                FolderPath: "Tiendas Mass",
                name: "Quechuas 10 AT MS",
                address: "AV. LOS QUECHUAS MZ. V. LT. 14. 1 PISO. URB. OLIMPO. IV ETAPA. ATE",
                reference: "AV. LOS QUECHUAS - SALAMANCA",
                district: "Ate",
                latitude: -12.074683,
                longitude: -76.979463
            },
            {
                id: "95",
                FolderPath: "Tiendas Mass",
                name: "Aymaras 3 AT MS",
                address: "AV. LOS AYMARAS 337. INTERIOR VIV. URB. AGRUP. RESID. SALAMANCA. ATE",
                reference: "AMEDIA CUADRA DE LA COMISARIA DE SALAMANCA ",
                district: "Ate",
                latitude: -12.074815,
                longitude: -76.988403
            },
            {
                id: "96",
                FolderPath: "Tiendas Mass",
                name: "LaFuente 3 CO MS",
                address: "AV. GUILLERMO DE LA FUENTE. MZ. R1. LT. 22. URB. SANTA LUZMILA. COMAS",
                reference: "AL COSTADO DEL MERCADO SANTA LUZMILA",
                district: "Comas",
                latitude: -11.941936,
                longitude: -77.061577
            },
            {
                id: "97",
                FolderPath: "Tiendas Mass",
                name: "Tipac 1 LU MS",
                address: "AAHH JULIO C. TELLO. LURIN",
                reference: "AV. JULIO C. TELLO COSTADO DEL MERCADO VIRGEN DE FATIMA",
                district: "Lurin",
                latitude: -12.274725,
                longitude: -76.869585
            },
            {
                id: "98",
                FolderPath: "Tiendas Mass",
                name: "Amancaes 1 RI MS",
                address: "AV. AMANCAES 124. URB. EL MANZANO. RIMAC.",
                reference: "AV. AMACAES CDRA 1",
                district: "Rimac",
                latitude: -12.025839,
                longitude: -77.033652
            },
            {
                id: "99",
                FolderPath: "Tiendas Mass",
                name: "Univer 6 SM MS",
                address: "AV. UNIVERSITARIA 662. URB. PANDO. 4TA ETAPA. SAN MIGUEL.",
                reference: "ALT. CDRA. 18 DE LA AV. LA MARINA ENTRE JR. CUZCO Y JR. AYACUCHO",
                district: "San Miguel",
                latitude: -12.082606,
                longitude: -77.082262
            },
            {
                id: "100",
                FolderPath: "Tiendas Mass",
                name: "Talara 4 JM MS",
                address: "AV. TALARA N° 442 452. TIENDA 5. JESUS MARÍA.",
                reference: "ENTRADA DE LA AGRUPACION ANGAMOS",
                district: "Jesús María",
                latitude: -12.070742,
                longitude: -77.045112
            },
            {
                id: "101",
                FolderPath: "Tiendas Mass",
                name: "DelAire 13 SL MS",
                address: "AV. DEL AIRE 1308. URB. JAVIER PRADO V. ETAPA. SAN LUIS",
                reference: "ALT. DE LA CDRA. 11 AV SAN LUIS Y AV. ROSA TORO",
                district: "San Luis",
                latitude: -12.077721,
                longitude: -76.997566
            },
            {
                id: "102",
                FolderPath: "Tiendas Mass",
                name: "Dominicos 1 CA MS",
                address: "AV. LOS DOMINICOS MZ. D. LT.01-02. URB. LOS NARDOS",
                reference: "FRENTE A LA AV. LOS DOMINICOS",
                district: "Callao",
                latitude: -12.005038,
                longitude: -77.099455
            },
            {
                id: "103",
                FolderPath: "Tiendas Mass",
                name: "P Hermosa 21 MS",
                address: "AV. GASTON GARCÍA RADA. MZ. D1. LOTE 21. AGRUPACIÓN  DE FAMILIAS SANTA CRUZ. PUNTA HERMOSA.",
                reference: "AMPLIACION SANTA CRUZ SIN VIA",
                district: "Punta Hermosa",
                latitude: -12.33104,
                longitude: -76.824947
            },
            {
                id: "104",
                FolderPath: "Tiendas Mass",
                name: "Bethoveen 4 SB MS",
                address: "JR. BEETHOVEN 498. MZ. H13. LT.1. URB. SAN BORJA SUR. II ETAPA. SAN BORJA.",
                reference: "ALT. 24 DE LA AV. SAN LUIS",
                district: "San Borja",
                latitude: -12.098975,
                longitude: -76.995162
            },
            {
                id: "105",
                FolderPath: "Tiendas Mass",
                name: "Mexico 5 LV MS",
                address: "AV. MEXICO 584. 1ER PISO. LA VICTORIA.",
                reference: "AL COSTADO DEL BANCO DE CREDITO",
                district: "La Victoria",
                latitude: -12.075104,
                longitude: -77.023632
            },
            {
                id: "106",
                FolderPath: "Tiendas Mass",
                name: "Sucre 11 PL MS",
                address: "AV. ANTONIO SUCRE 1158. PUEBLO LIBRE",
                reference: "A MEDIA CDRA. DE LA AV. MARINA, CRUCE DE AV. SURE Y AV. LA MARINA",
                district: "Pueblo Libre",
                latitude: -12.082642,
                longitude: -77.067164
            },
            {
                id: "107",
                FolderPath: "Tiendas Mass",
                name: "Lurin 40 LU MS",
                address: "A.H. NUEVO LURIN. KM. 40. Y ANEXOS MZ. 3. LT. 11. LURIN",
                reference: "KM 40 DE LA PANAMERICANA SUR PASANDO EL MERCADO VIRGEN DE LAS MERCEDES",
                district: "Lurin",
                latitude: -12.295314,
                longitude: -76.852235
            },
            {
                id: "108",
                FolderPath: "Tiendas Mass",
                name: "Grau 3 LU MS",
                address: "JR. GRAU N° 391. MZ. E. LT. 42. LURIN.",
                reference: "PLAZA DE LURIN",
                district: "Lurin",
                latitude: -12.273479,
                longitude: -76.869462
            },
            {
                id: "109",
                FolderPath: "Tiendas Mass",
                name: "Tupac 31 CB MS",
                address: "AV. TUPAC AMARU N° 3121-3123. PJ. EL PROGRESOL SECTOR II. CARABAYLLO.",
                reference: "A UNA CDRA DE PARADERO DON POLLO A UNA CDRA DE MIFARMA",
                district: "Carabayllo",
                latitude: -11.876413,
                longitude: -77.017429
            },
            {
                id: "110",
                FolderPath: "Tiendas Mass",
                name: "R Palma 1 PP MS",
                address: "CERCADO PUENTE PIEDRA. CA. RICARDO PALMA N° 188. PUENTE PIEDRA.",
                reference: "FRENTE AL MERCADO COOPERATIVA A MEDIA CDRA. DE INKAFARMA",
                district: "Puente Piedra",
                latitude: -11.863854,
                longitude: -77.077417
            },
            {
                id: "111",
                FolderPath: "Tiendas Mass",
                name: "JChavez 19 SC MS",
                address: "AV. CHAVEZ, JORGE N° 1557. LOCAL 01.  URB. CERCADO. SANTIAGO DE SURCO.",
                reference: "ENTRE TIENDA MAKRO Y BASE LA FAP",
                district: "Santiago de Surco",
                latitude: -12.151729,
                longitude: -77.004379
            },
            {
                id: "112",
                FolderPath: "Tiendas Mass",
                name: "Univer 7 SMP MS",
                address: "AV. UNIVERSITARIA 671. URB. EL ROSARIO. SAN MARTIN DE PORRES.",
                reference: "UNIVERSITARIA CDRA. 7",
                district: "San Martín de Porres",
                latitude: -12.019535,
                longitude: -77.075922
            },
            {
                id: "113",
                FolderPath: "Tiendas Mass",
                name: "Escardo 6 SM MS",
                address: "AV. RAFAEL ESCARDO N° 721. URB. MARANGA. 7MA ETAPA. SAN MIGUEL",
                reference: "AV. RAFAEL ESCARDO A 3 CASA DEL CRUCE AV. INTISUYO",
                district: "San Miguel",
                latitude: -12.073798,
                longitude: -77.092136
            },
            {
                id: "114",
                FolderPath: "Tiendas Mass",
                name: "Alisos 7 LO MS",
                address: "AV. LOS ALISOS MZ.R.LT.40. AAHH LOS JAZMINES DEL NARANJAL. LOS OLIVOS",
                reference: "ENTRE AV. ALISOS CON UNIVERSITARIA",
                district: "Los Olivos",
                latitude: -11.982884,
                longitude: -77.078876
            },
            {
                id: "115",
                FolderPath: "Tiendas Mass",
                name: "Punta Negra 1 MS",
                address: "AV. GUANAY MZ. F1. LT. 3A. PUNTA NEGRA.",
                reference: "ZONA CENTRAL PUNTA NEGRA",
                district: "Punta Negra",
                latitude: -12.364986,
                longitude: -76.79517
            },
            {
                id: "116",
                FolderPath: "Tiendas Mass",
                name: "Risso 3 LI MS",
                address: "JR. RISSO N° 0354. LINCE",
                reference: "RISSO CDRA. 3  CERCA A PLAZA VEA RISSO",
                district: "Lince",
                latitude: -12.085507,
                longitude: -77.032733
            },
            {
                id: "117",
                FolderPath: "Tiendas Mass",
                name: "JoseLeal 8 LI MS",
                address: "AV. LEAL JOSE CORONEL N° 0859. LINCE.",
                reference: "A UNA CDRA DEL MERCADO N2 DE RISSO",
                district: "Lince",
                latitude: -12.085745,
                longitude: -77.041892
            },
            {
                id: "118",
                FolderPath: "Tiendas Mass",
                name: "Bolognesi 6 MA MS",
                address: "JR. AUGUSTO BOLOGNESI N° 639. MAGDALENA DEL MAR.",
                reference: "A MEDIA CDRA. DEL MERCADO MAGDALENA",
                district: "Magdalena del Mar",
                latitude: -12.091178,
                longitude: -77.07415
            },
            {
                id: "119",
                FolderPath: "Tiendas Mass",
                name: "Alipio SJM MS",
                address: "AV. LOS ALAMOS, MZ H, LT 07. AA.HH. LOS FORESTALES. SAN JUAN DE MIRAFLORES.",
                reference: "FRENTE A LA VILLA MILITAR HEROES DE SAN JUAN Y MIRAFLORES",
                district: "San Juan de Miraflores",
                latitude: -12.168844,
                longitude: -76.980383
            },
            {
                id: "120",
                FolderPath: "Tiendas Mass",
                name: "San Juan 4 SL MS",
                address: "AV. SAN JUAN 415. URB. LINCOLN. SAN LUIS.",
                reference: "AV. SAN JUAN CDRA. 4",
                district: "San Luis",
                latitude: -12.076839,
                longitude: -77.006448
            },
            {
                id: "121",
                FolderPath: "Tiendas Mass",
                name: "Fibonaci SU MS",
                address: "CL. FIBONACI MZ. J. LT. 5. URB. VILLA VICTORIA. SURQUILLO.",
                reference: "FRENTE AL PARQUE VIRGEN DE FATIMA-EDIFICIO DE 4 PISO",
                district: "Surquillo",
                latitude: -12.10836,
                longitude: -77.011022
            },
            {
                id: "122",
                FolderPath: "Tiendas Mass",
                name: "Reducto 9 MI MS",
                address: "AV. REDUCTO 949. MIRAFLORES",
                reference: "CERCA AV. 28 DE JULIO AV. BENAVIDES",
                district: "Miraflores",
                latitude: -12.128016,
                longitude: -77.023959
            },
            {
                id: "123",
                FolderPath: "Tiendas Mass",
                name: "Ventanilla MS",
                address: "CALLE 12 MZ. C. 12. LT. 1. URB. EX. ZONA COMERCIAL E INDUSTRIAL. VENTANILLA.",
                reference: "ESPALDA DE LA MUNICIPALIDAD DE VENTANILLA",
                district: "Ventanilla",
                latitude: -11.876991,
                longitude: -77.128851
            },
            {
                id: "124",
                FolderPath: "Tiendas Mass",
                name: "Condorc 9 CB MS",
                address: "AV. CONDORCANQUI, MANZANA V, LOTE 9, 10. URB. SANTO DOMINGO. 5TA ETAPA. CARABAYLLO.",
                reference: "CERCA AL MERCADO FRONTERA FRENTE AL RESTAURANT LA FOGATA",
                district: "Carabayllo",
                latitude: -11.884921,
                longitude: -77.034328
            },
            {
                id: "125",
                FolderPath: "Tiendas Mass",
                name: "Aristides 13 CE MS",
                address: "JR. TNT. ARISTIDES DEL CARPIO MUÑOZ 1396. MZ. Z. LT 14. URB. LOS CIPRESES",
                reference: "A UNA CDRA DEL MERCADO LOS CIPRESES A 3 CDRAS DEL MERCADO COLONIAL",
                district: "Cercado de Lima",
                latitude: -12.052985,
                longitude: -77.077332
            },
            {
                id: "126",
                FolderPath: "Tiendas Mass",
                name: "Bolivia 7 BR MS",
                address: "AV. BOLIVIA 719. BREÑA",
                reference: "A 3 CDRAS DE ALFONSO UGARTE FRENTE AL COLEGIO ALBERT EINSTEIN ",
                district: "Breña",
                latitude: -12.056562,
                longitude: -77.044995
            },
            {
                id: "127",
                FolderPath: "Tiendas Mass",
                name: "Salguero 5 SC MS",
                address: "JR. SALGUERO, SIMON 552. MZ. G1. LT. 6. URB. LOS TULIPANES. SANTIAGO DE SURCO",
                reference: "A UNA CDRA DE LA AV. LA MERCED AL COSTADO DE LA LAVANDERIA MARTINIZING",
                district: "Santiago de Surco",
                latitude: -12.130317,
                longitude: -77.008091
            },
            {
                id: "128",
                FolderPath: "Tiendas Mass",
                name: "Tusilagos 3 SJL MS",
                address: "AV. LOS TUSILAGOS OESTE N° 307. MZ. T.LT. 10-APV URBANIZADORA AZCARRUNZ. SAN JUAN DE LURIGANCHO",
                reference: "A UNA CDRA. DE TOTTUS DE AV. TUSILAGOS OESTE",
                district: "San Juan de Lurigancho",
                latitude: -12.013269,
                longitude: -77.004678
            },
            {
                id: "129",
                FolderPath: "Tiendas Mass",
                name: "SRosa 1 SJL MS",
                address: "SV. MZ. F. LT. 61. APV LOS PINOS. SJL.",
                reference: "A MEDIA CDRA DEL CRUCE DE LA AV. PROCERES DE LA INDEPENDENCIA CON AV. SANTA ROSA ",
                district: "San Juan de Lurigancho",
                latitude: -11.968705,
                longitude: -76.994433
            },
            {
                id: "130",
                FolderPath: "Tiendas Mass",
                name: "Dinthilac 3 SM MS",
                address: "AV. MONSEÑOR JORGE DINTHILAC N° 305. URB. PANDO 2DA ETAPA. SAN MIGUEL.",
                reference: "A 2 CDRAS. DE LA AV. LA MARINA FRENTE AL PARQUE LAS ROSAS Y COLEGIO CHINO 10 DE OCTUBRE ",
                district: "San Miguel",
                latitude: -12.082495,
                longitude: -77.086238
            },
            {
                id: "131",
                FolderPath: "Tiendas Mass",
                name: "Alisos 6 LO MS",
                address: "AV. LOS ALISOS, MANZANA P, LOTE 22. URB. MICAELA BASTIDAS. SECTOR 1, 1 ETAPA. LOS OLIVOS.",
                reference: "ENTRE LA AV. LAS PALMERAS Y AV. PANAMERICANA - AL LADO DEL MERCADO 5 DE SITIEMBRE",
                district: "Los Olivos",
                latitude: -11.984257,
                longitude: -77.069436
            },
            {
                id: "132",
                FolderPath: "Tiendas Mass",
                name: "CombateA 15 SC MS",
                address: "JR. COMBATE DE ANGAMOS MZ.D. LT.15. URB. CRUZ SURCO (COOVIECMA). SANTIAGO DE SURCO.",
                reference: "CRUCE DE COMBATE DE ANGAMOS Y CASTELLANA",
                district: "Santiago de Surco",
                latitude: -12.14235,
                longitude: -77.009388
            },
            {
                id: "133",
                FolderPath: "Tiendas Mass",
                name: "Edmundo 1 SC MS",
                address: "AV. AGUILAR PASTOR EDMUNDO GRAL. TIENDA 1. MZ. A. LT. 1. URB. VIÑA DEL MAR. SANTIAGO",
                reference: "FRENTE A LA VILLA MILITAR DE CHORRILLOS ",
                district: "Santiago de Surco",
                latitude: -12.156243,
                longitude: -77.014951
            },
            {
                id: "134",
                FolderPath: "Tiendas Mass",
                name: "Carabay 17 CO MS",
                address: "AV. CARABAYLLO (AV. 7). N° 1784. MZ. S. LT. 27. URB. VIÑEDOS DE CARABAYLLO II ETAPA. COMAS",
                reference: "FRENTE AL MERCADO APECOLIP A MEDIA CUADRA DE LA AV. TUPAC AMARU",
                district: "Comas",
                latitude: -11.944802,
                longitude: -77.052653
            },
            {
                id: "135",
                FolderPath: "Tiendas Mass",
                name: "Dominic 33 CA MS",
                address: "AV. LOS DOMINICOS MZ. L. LT. 33. URB. JORGE CHAVEZ. ETAPA II. CALLAO",
                reference: "A MEDIA CUDRA DE TOTTUS AV. DOMINICOS CON TOMAS VALLE",
                district: "Callao",
                latitude: -12.01563,
                longitude: -77.093907
            },
            {
                id: "136",
                FolderPath: "Tiendas Mass",
                name: "Ingenier 4 LM MS",
                address: "AV. LOS INGENIEROS U.I.2. MZ. E2. LT. 18. URB. SANTA PATRICIA. III. ETAPA. LA MOLINA",
                reference: "ALT. DEL TEMPLO LO MORMONES AV. JAVIE RPRADO ESTE CDRA. 64",
                district: "La Molina",
                latitude: -12.065583,
                longitude: -76.949527
            },
            {
                id: "137",
                FolderPath: "Tiendas Mass",
                name: "JOlaya 18 VTA MS",
                address: "AV. EL SALVADOR. MZ. P. LT. 18.A. AAHH NUEVO PROGRESO. VENTANILLA",
                reference: "MERCADO JOSE OLAYA ",
                district: "Ventanilla",
                latitude: -11.839432,
                longitude: -77.125557
            },
            {
                id: "138",
                FolderPath: "Tiendas Mass",
                name: "Floresta SJM MS",
                address: "AA.HH. JUAN PABLO II, MZ. A. LT. 38. SAN JUAN DE MIRAFLORES.",
                reference: "LIMITE CON EL DISTRITO DE SURCO",
                district: "San Juan de Miraflores",
                latitude: -12.158066,
                longitude: -76.984194
            },
            {
                id: "139",
                FolderPath: "Tiendas Mass",
                name: "Gamarra 20 SMP MS",
                address: "AV. ANGELICA GAMARRA MZ. F. LT. 01. URB. EL PACÍFICO. SAN MARTIN DE PORRES.",
                reference: "UNIVERSITARIA CON ANGELICA GAMARRA AL COSTADO DE POLLERIA ROKYS",
                district: "San Martín de Porres",
                latitude: -12.006448,
                longitude: -77.083091
            },
            {
                id: "140",
                FolderPath: "Tiendas Mass",
                name: "Construc 13 LM MS",
                address: "AV. LOS CONSTRUCTORES N°1376. MZ. O. LT. 53. URB. COVIMA. LA MOLINA",
                reference: "AV CONSTRUCTORES CON AV HUAROCHIRI AL COSTADO DOMINOS PIZZA",
                district: "La Molina",
                latitude: -12.0612,
                longitude: -76.94306
            },
            {
                id: "141",
                FolderPath: "Tiendas Mass",
                name: "AUgarte MI MS",
                address: "CALLE ALFONSO UGARTE 342. MIRAFLORES",
                reference: "A UNA CDRA. DE LA AV. GONZALES PRADA CON LA VIA EXPRESA A LA ESPALDA AV. RICARDO PALMA",
                district: "Miraflores",
                latitude: -12.1172213,
                longitude: -77.0265998
            },
            {
                id: "142",
                FolderPath: "Tiendas Mass",
                name: "Nicolini 2 SMP MS",
                address: "AV. JUAN VICENTE NICOLINI N° 299. URB. SOL DE LIMA. SAN MARTIN DE PORRES.",
                reference: "A 3 CDRAS DE LA PANAMERICANA NORTE ENTRANDO POR LA PRIMERA DE PALAO NICOLINI CON JR. LAS VIOLETAS",
                district: "San Martín de Porres",
                latitude: -12.017596,
                longitude: -77.054796
            },
            {
                id: "143",
                FolderPath: "Tiendas Mass",
                name: "Collique 1 CO MS",
                address: "AV. A. N° 915. TIENDA 01. CONJUNTO RESIDENCIAL CIUDAD SOL DE COLLIQUE. COMAS.",
                reference: "DENTRO DEL CONJUNTO RESIDENCIAL SIL DE COLLIQUE ",
                district: "Comas",
                latitude: -11.925752,
                longitude: -77.058888
            },
            {
                id: "144",
                FolderPath: "Tiendas Mass",
                name: "Venus 3 VTA MS",
                address: "LOTE 25, MZ. A1. URB. COOPEMAR. VENTANILLA.",
                reference: "AL COSTADO DEL MERCADO ANGEL CASTILLO",
                district: "Ventanilla",
                latitude: -11.884481,
                longitude: -77.129748
            },
            {
                id: "145",
                FolderPath: "Tiendas Mass",
                name: "Tristan 2 LM MS",
                address: "AV. FLORA TRISTAN 202. MZ. C1. LT 1-73. URB. SANTA PATRICIA. I ETAPA. LA MOLINA",
                reference: "A 5 CDRAS DE JAVIER PRADO - 2 CDRAS DE LA UNIVERSIDAD SAN MARTIN DE PORRAS",
                district: "La Molina",
                latitude: -12.073683,
                longitude: -76.939467
            },
            {
                id: "146",
                FolderPath: "Tiendas Mass",
                name: "Corbeta 1 SC MS",
                address: "JR. CORBETA LA UNIÓN. U. IMB. 01. MZ. A. LT. 16. URB. CERCADO. SANTIAGO DE SURCO.",
                reference: "CRUCE CON JR. FRANKLIN D ROOSEVELT CERCA AL MERCADO APOSTOL SURCO VIEJO",
                district: "Santiago de Surco",
                latitude: -12.146061,
                longitude: -77.006836
            },
            {
                id: "147",
                FolderPath: "Tiendas Mass",
                name: "Bauzate 11 LV MS",
                address: "AV. BAUZATE Y MEZA 1199. PRIMER PISO. LA VICTORIA.",
                reference: "A UNA CDRA DE 28 DE JULIO FRENTE AL PARQUE EL PORVENIR",
                district: "La Victoria",
                latitude: -12.063236,
                longitude: -77.019178
            },
            {
                id: "148",
                FolderPath: "Tiendas Mass",
                name: "Patriotas 8 SM MS",
                address: "AV. DE LOS PATRIOTAS N° 809. URB. CAPEBCO. SAN MIGUEL.",
                reference: "ALT. CDRA 35 AV. LA MARINA ",
                district: "San Miguel",
                latitude: -12.0738,
                longitude: -77.104957
            },
            {
                id: "149",
                FolderPath: "Tiendas Mass",
                name: "Campodoni 4 LV MS",
                address: "AV. ESTEBAN CAMPODONICO N° 468-470. LA VICTORIA.",
                reference: "ENTRE LA CDRA. 4 Y 5 DE LA AV. CANADA",
                district: "La Victoria",
                latitude: -12.086078,
                longitude: -77.020843
            },
            {
                id: "150",
                FolderPath: "Tiendas Mass",
                name: "Oman 1 SJL MS",
                address: "SV. MZ. M. LT 11. PUE. JOVEN CRUZ DE MOTUPE. SJL",
                reference: "A DOS CDRAS DE FERNANDO WIESSE CDRA 58 FRENTE AL MERCADO 11 DE ENERO",
                district: "San Juan de Lurigancho",
                latitude: -11.937906,
                longitude: -76.978126
            },
            {
                id: "151",
                FolderPath: "Tiendas Mass",
                name: "Caceres SC MS",
                address: "JR. CACERES, ANDRES AVELINO MARISCAL N° 201. URB. CERCADO. SANTIAGO DE SURCO.",
                reference: "CRUCE CON JIRON BOLOGNESI Y CACERES ",
                district: "Santiago de Surco",
                latitude: -12.146687,
                longitude: -77.005792
            },
            {
                id: "152",
                FolderPath: "Tiendas Mass",
                name: "QhantuPza AT MS",
                address: "AV. LA ESTRELLA 286. AV. SANTA ROSA 158. TIENDA 118. FUNDO LA ESTRELLA. SANTA CLARA. ATE",
                reference: "CENTRO COMERCIAL QHANTU PLAZA",
                district: "Ate",
                latitude: -12.018157,
                longitude: -76.883803
            },
            {
                id: "153",
                FolderPath: "Tiendas Mass",
                name: "Danoza 1 VTA MS",
                address: "LOTE 01, MZ. A, SECTOR A, SUB SECTOR 1- URB. ANTONIA MORENO DE CACERES. VENTANILLA.",
                reference: "AL COSTADO DE ESSALUD A MEDIA CDRA. DEL MERCADO 12 DE DICIEMBRE",
                district: "Ventanilla",
                latitude: -11.886233,
                longitude: -77.124151
            },
            {
                id: "154",
                FolderPath: "Tiendas Mass",
                name: "SRosa 17 SJL MS",
                address: "AV. SANTA ROSA DE LIMA 1743. APV INCA MANCO CAPAC. SAN JUAN DE LURIGANCHO",
                reference: "A UN ACDRA. DE LA AV. LOS JARDINES ESTE A 6 CDRAS DEL METRO DE LA HACIENDA",
                district: "San Juan de Lurigancho",
                latitude: -12.00125,
                longitude: -76.99966
            },
            {
                id: "155",
                FolderPath: "Tiendas Mass",
                name: "Cueva 7 PL MS",
                address: "JR. CRNL. JUAN VALER SANDOVAL N° 721-725. PUEBLO LIBRE.",
                reference: "ALT. DE LA CDRA.15 DE LA AV. LA MARINA",
                district: "Pueblo Libre",
                latitude: -12.077451,
                longitude: -77.074591
            },
            {
                id: "156",
                FolderPath: "Tiendas Mass",
                name: "Alameda 7 CLL MS",
                address: "AV. ALAMEDA SUR 779. URB. LOS CEDROS DE VILLA. CHORRILLOS.",
                reference: "A 2 CDRAS DE LA AV. SAN MARCOS Y A MEDIA CDRA DE METRO",
                district: "Chorrillos",
                latitude: -12.19875,
                longitude: -77.012054
            },
            {
                id: "157",
                FolderPath: "Tiendas Mass",
                name: "Lima 3 SJL MS",
                address: "AV. LIMA 320A-322A. MZ. F4. LT. 91. URB. CAJA DE AGUA. SAN JUAN DE LURIGANCHO",
                reference: "FRENTE A LA COMISARIA CAJA DE AGUA CERCA AL MERCADO CAJA DE AGUA",
                district: "San Juan de Lurigancho",
                latitude: -12.025912,
                longitude: -77.01568
            },
            {
                id: "158",
                FolderPath: "Tiendas Mass",
                name: "DelAire 7 LV MS",
                address: "AV. DEL AIRE (PROLONGACIÓN) N° 746. LA VICTORIA",
                reference: "CDRA 7 DE LA AV DEL AIRE",
                district: "La Victoria",
                latitude: -12.081695,
                longitude: -77.005822
            },
            {
                id: "159",
                FolderPath: "Tiendas Mass",
                name: "Construc 2 LM MS",
                address: "AV. LOS CONSTRUCTORES. 1086. MZ. G.LT 41. URB. SANTA PATRICIA. III ETAPA. LA MOLINA",
                reference: "AV. CONSTRUCTORES CON AV. FLORA TRISTAN A MEDIA CDRA. PARADERO",
                district: "La Molina",
                latitude: -12.062788,
                longitude: -76.946513
            },
            {
                id: "160",
                FolderPath: "Tiendas Mass",
                name: "Procer 39 SJL MS",
                address: "AV. PROCERES DE LA INDEPENDENCIA N° 3978-3998. SJL",
                reference: "5 CDRAS. DE ESTAC. SANTA ROSA ANTES D ELA ESTACIO N BAYOVAR",
                district: "San Juan de Lurigancho",
                latitude: -11.965354,
                longitude: -76.991145
            },
            {
                id: "161",
                FolderPath: "Tiendas Mass",
                name: "12Octubre SMP MS",
                address: "AV. 12 DE OCTUBRE, MANZANA N, LOTE 03. ASOC. VIV. MIGUEL GRAU. SAN MARTIN DE PORRES.",
                reference: "FRENTE AL COLEGIO JUAN PABLO PEREGRINO  AL LADO DE LA PANDERIA DEGUSTAPAN ",
                district: "San Martín de Porres",
                latitude: -11.998498,
                longitude: -77.09318
            },
            {
                id: "162",
                FolderPath: "Tiendas Mass",
                name: "Polonia 6 SJL MS",
                address: "AV. LA FLORES N° 292. MZ. H. LT 2-3. URB. CANTO GRANDE. SJL",
                reference: "A UNA CDRA DE SUPERMERCADO METRO POR ESTACION DE TREN SAN MARTIN",
                district: "San Juan de Lurigancho",
                latitude: -11.974311,
                longitude: -77.002445
            },
            {
                id: "163",
                FolderPath: "Tiendas Mass",
                name: "Venezu 50 SM MS",
                address: "AV. VENEZUELA N° 5021, MANZANA B, LOTE 5, URB. VILLALBA. SAN MIGUEL.",
                reference: "A UNA CDRA IZQ, DE FAUCETT A UNA CDRA. DE SUPERMERCADO METRO",
                district: "San Miguel",
                latitude: -12.062948,
                longitude: -77.098382
            },
            {
                id: "164",
                FolderPath: "Tiendas Mass",
                name: "Coralina 6 SJL MS",
                address: "JR. ALHUCEMA SEC. INM. A. MZ. 81. LT. 19-20. URB. LAS FLORES. SAN JUAN DE LURIGANCHO.",
                reference: "A UNA CDRA. DEL PARADERO 8 DE AV. LAS FLORES A 5 CDRAS DE ESTACION TREN LA HACIENDA",
                district: "San Juan de Lurigancho",
                latitude: -12.00919,
                longitude: -77.01194
            },
            {
                id: "165",
                FolderPath: "Tiendas Mass",
                name: "Antares 5 RI MS",
                address: "JR. ANTARES 501. URB. VENTURA ROSSI. RIMAC",
                reference: "A 2 CDRAS DE AV. SAMUEL ALCAZAR FRENTE AL PARQUE DEL PERIODISMO",
                district: "Rimac",
                latitude: -12.0272545,
                longitude: -77.037873
            },
            {
                id: "166",
                FolderPath: "Tiendas Mass",
                name: "Precurso 2 SM MS",
                address: "AV. LOS PRECURSORES N° 281. URB. MARANGA 7 ETAPA. SAN MIGUEL.",
                reference: "FRENTE A CONDOMINIOS LOS PRECURSORES EX TIENDA DE COLCHONES",
                district: "San Miguel",
                latitude: -12.071579,
                longitude: -77.090196
            },
            {
                id: "167",
                FolderPath: "Tiendas Mass",
                name: "Metrop 10 AT MS",
                address: "CALLE EL SOL. MZ N, LT 61. SECTOR 10. ZONA 3. URB. TILDA. ATE",
                reference: "CRUCE DE CALLE MINKA CON CALLE JULIO C. TELLO / PARALELO A CALLE METROPOLITANA PARADERO FORTALEZA ",
                district: "Ate",
                latitude: -12.03332,
                longitude: -76.938602
            },
            {
                id: "168",
                FolderPath: "Tiendas Mass",
                name: "Higos 1 EA MS",
                address: "CA. LOS HIGOS 194-196. URB. TAYACAJA. EL AGUSTINO.",
                reference: "FRENTE AL MERCADO TAYACAJA A UNA CDRA. DE LA AV. CESAR VALLEJO",
                district: "El Agustino",
                latitude: -12.038353,
                longitude: -76.99377
            },
            {
                id: "169",
                FolderPath: "Tiendas Mass",
                name: "Campoy 1 SJL MS",
                address: "CALLE 11, MZ O, LT 11-URB. LOTIZACIÓN CAMPOY. SAN JUAN DE LURIGANCHO",
                reference: "ESPALDA DE TOTTUS CAMPOY CERCA AL INNOVA SCHOOL CAMPOY",
                district: "San Juan de Lurigancho",
                latitude: -12.02182,
                longitude: -76.973774
            },
            {
                id: "170",
                FolderPath: "Tiendas Mass",
                name: "Bustamant 7 SA MS",
                address: "JR. BUSTAMANTE MARIANO 725. MZ. F4. LT. 16. URB. LOS FICUS. SANTA ANITA.",
                reference: "CERCA AL PARQUE SAN MARTIN DE PORRAS Y MERCADO LOS FICUS",
                district: "Santa Anita",
                latitude: -12.049116,
                longitude: -76.971221
            },
            {
                id: "171",
                FolderPath: "Tiendas Mass",
                name: "Mariate 8 VMT MS",
                address: "AV. JOSE CARLOS MARIATEGUI N° 844. MZ. D3. LT. 04. - P.J. JOSE CARLOS MARIATEGUI-VILLA MARÍA DEL TRIUNFO.",
                reference: "MERCADO LA MODERNA ALT. DEL CRUCE DE AV. CARLOS MARIATEGUI Y SALVADOR ALLENDE",
                district: "Villa María del Triunfo",
                latitude: -12.1542209,
                longitude: -76.9523925
            },
            {
                id: "172",
                FolderPath: "Tiendas Mass",
                name: "Piramide 4 SJL MS",
                address: "AV. PIRAMIDE DEL SOL 432-433. URB. ZARATE. SAN JUAN DE LURIGANCHO.",
                reference: "CRUCE AV.CHIMU CDRA 7 Y JR. LOS CHASQUIS",
                district: "San Juan de Lurigancho",
                latitude: -12.025179,
                longitude: -77.004157
            },
            {
                id: "173",
                FolderPath: "Tiendas Mass",
                name: "Amaru 3 SA MS",
                address: "AV. TUPAC AMARU 380. MZ. U-2. LT 03.URB. UNIVERSAL. SANTA ANITA",
                reference: "A UNA CDRA. MERCADO UNIVERSAL A 3 CDRAS DE LA AV. PANAMERICANA NORTE ",
                district: "Santa Anita",
                latitude: -12.045184,
                longitude: -76.97981
            },
            {
                id: "174",
                FolderPath: "Tiendas Mass",
                name: "Canta 31 SMP MS",
                address: "AV. CANTA CALLAO, MZ. F. LT. 31. ASOC. VIV. BRISAS DE SANTA ROSA. SAN MARTIN DE PORRES.",
                reference: "A MEDIA CDRA DE AV. LOS DOMINICOS A 5 CDRAS DE TOTTUS AV. CANTA CALLAO",
                district: "San Martín de Porres",
                latitude: -11.991199,
                longitude: -77.10827
            },
            {
                id: "175",
                FolderPath: "Tiendas Mass",
                name: "Canta 1 CA MS",
                address: "AV. CANADA, MANZANA O1, LT 23. URB. SAN JUAN MACIAS. CALLAO.",
                reference: "A 2 CUDRAS DE LA AV. CANTA CALLAO",
                district: "Callao",
                latitude: -11.997443,
                longitude: -77.117492
            },
            {
                id: "176",
                FolderPath: "Tiendas Mass",
                name: "Virreyes 2 SA MS",
                address: "AV. LOS VIRREYES. MZ. J. LT 03. URB. ALAMEDA DE ATE. SANTA ANITA",
                reference: "AL LADO DE LA BOTICA CARRION CRUCE DE LAS CALLES AUGUSTO SALAZAR BONDI Y LOS VIRREYES",
                district: "Santa Anita",
                latitude: -12.032529,
                longitude: -76.948652
            },
            {
                id: "177",
                FolderPath: "Tiendas Mass",
                name: "Mangoma 3 SJL MS",
                address: "JR. TEMPLO DEL ASPERO N° 264-270-278. MZ. N. LT. 26. URB. MANGOMARCA BAJA. SAN JUAN DE LURIGANCHO",
                reference: "FRENTE AL MERCADO MANGOMARCA ENTRE AV. SANTUARIO Y CALLE TEMPLO ASPERO",
                district: "San Juan de Lurigancho",
                latitude: -12.016434,
                longitude: -76.985598
            },
            {
                id: "178",
                FolderPath: "Tiendas Mass",
                name: "Francisca 3 CE MS",
                address: "JR. SANTA FRANCISCA ROMANA 545. MZ. D5. LT 07. URB. PANDO. CERCADO DE LIMA",
                reference: "ESPALDA DE LA COMISARIA PALOMINO ALT. CDRA 28 DE LA AV. VENEZUELA",
                district: "Cercado de Lima",
                latitude: -12.061846,
                longitude: -77.072078
            },
            {
                id: "179",
                FolderPath: "Tiendas Mass",
                name: "Gonzales B11 LO MS",
                address: "JR. MANUEL GONZALES PRADA N° 537. URB. VILLA LOS ANGELES. LOS OLIVOS.",
                reference: "A 30 MTS DEL RESTAURANT PUNTO CABANISTA",
                district: "Los Olivos",
                latitude: -11.994411,
                longitude: -77.068372
            },
            {
                id: "180",
                FolderPath: "Tiendas Mass",
                name: "Canada 3 LV MS",
                address: "AV. CANADA 397. 1ER PISO. LA VICTORIA.",
                reference: "FRENTE AL MERCADO PALERMO CRUCE DE AV. CANADA Y PALERMO",
                district: "La Victoria",
                latitude: -12.081348,
                longitude: -77.023179
            },
            {
                id: "181",
                FolderPath: "Tiendas Mass",
                name: "Torres 2 SJM MS",
                address: "AV. JOSE TORRES PAZ N° 283. ZONA A. CIUDAD DE DIOS. SAN JUAN DE MIRAFLORES.",
                reference: "FRENTE AL MERCADO CIUDAD DE DIOS CRUCE DE TORRES PAXZ CON CALLE MERCADO",
                district: "San Juan de Miraflores",
                latitude: -12.15143,
                longitude: -76.971658
            },
            {
                id: "182",
                FolderPath: "Tiendas Mass",
                name: "LasDalias AT MS",
                address: "CALLES LAS DALIAS. MZ. Z. LT. 07. 1ER PISO. ASOC. PROP. VIV. VIRGEN DEL CARMEN. ATE",
                reference: "A 3 CDRAS DE LA CAJA MUNICIPAL CUZCO CRUCE DE DALIAS CON CALLE CALIFORNIA",
                district: "Ate",
                latitude: -12.037537,
                longitude: -76.928818
            },
            {
                id: "183",
                FolderPath: "Tiendas Mass",
                name: "Pastor 1 PL MS",
                address: "JR. MARIANO PASTOR SEVILLA N° 177. PUEBLO LIBRE.",
                reference: "MERCADO BOLIVAR CDRA. 11 DE LA AV. BOLIVAR",
                district: "Pueblo Libre",
                latitude: -12.073211,
                longitude: -77.065225
            },
            {
                id: "184",
                FolderPath: "Tiendas Mass",
                name: "Eucalipto 9 SA MS",
                address: "AV. LOS EUCALIPTOS 911. MZ. H3. LT. 10-A. URB. UNIVERSAL. SANTA ANITA",
                reference: "FRENTE AL COLEGIO CESAR VALLEJO CRUCE FRANCISCO BOLOGNESI CON CALLE EUCALIPTOS",
                district: "Santa Anita",
                latitude: -12.045233,
                longitude: -76.975141
            },
            {
                id: "185",
                FolderPath: "Tiendas Mass",
                name: "Alameda 17 LM MS",
                address: "ALAMEDA DEL CORREGIDOR 1781. LC. M1. MZ. Y. LT. 04. URB. LA ENSENADA. LA MOLINA",
                reference: "FRENTE A CHIFA NUEVA ERA CRUCE DE LA ALAMEDA DEL CORREGIDOR CON LAS CASCADAS ",
                district: "La Molina",
                latitude: -12.101073,
                longitude: -76.949447
            },
            {
                id: "186",
                FolderPath: "Tiendas Mass",
                name: "Bolognesi 6 SA MS",
                address: "AV. FRANCISCO BOLOGNESI 639. MZ U-3. LT 15. URB. LOS FICUS. SANTA ANITA.",
                reference: "FRENTE A INKAFARMA AV. BOLOGNESI CRUCE DE LAS CALLES MARCELINO VALERA Y BOLOGNESI",
                district: "Santa Anita",
                latitude: -12.050106,
                longitude: -76.972062
            },
            {
                id: "187",
                FolderPath: "Tiendas Mass",
                name: "13Enero SJL MS",
                address: "AV. ENERO, 13  DE N° 2261-2257. MZ. L. LT. 03. COOP. LA HUAYRONA. SAN JUAN DE LURIGANCHO.",
                reference: "A DOS CDRAS DE LA COMISARIA HUAYRONA",
                district: "San Juan de Lurigancho",
                latitude: -11.993191,
                longitude: -77.005207
            },
            {
                id: "188",
                FolderPath: "Tiendas Mass",
                name: "Tomasal 1 SC MS",
                address: "JR. TOMASAL 145. U. IMB. 02. MZ. M2. LT. 10C. URB. CENTRO COMERCIAL MONTERRICO. SANTIAGO DE SURCO.",
                reference: "AL COSTADO DEL KFC CRUCE DE LA AV. TOMASAL Y PRIMAVERA",
                district: "Santiago de Surco",
                latitude: -12.108312,
                longitude: -76.969072
            },
            {
                id: "189",
                FolderPath: "Tiendas Mass",
                name: "AsoSRosa STR MS",
                address: "MZ. F. LT. 12. ASOC. SANTA ROSA. SANTA ROSA.",
                reference: "A DOS CDRAS ANTES DE LLEGAR A LA AV. SANTA ROSA A UNA CDRA. DEL PARADERO LA CURVA DE SANTA ROSA",
                district: "Santa Rosa",
                latitude: -11.785306,
                longitude: -77.157018
            },
            {
                id: "190",
                FolderPath: "Tiendas Mass",
                name: "Insurgen 12 BE MS",
                address: "AV. JUAN VELASCO ALVARADO N° 1213. P-2. LT. 18. URB. CIUDAD DEL PESCADOR. BELLAVISTA.",
                reference: "A CDRA Y MEDIA DEL COLEGIO SANTA BEATRIZ",
                district: "Bellavista",
                latitude: -12.061138,
                longitude: -77.105051
            },
            {
                id: "191",
                FolderPath: "Tiendas Mass",
                name: "HuandoyL 1 LO MS",
                address: "CALLE ORION MZ. P. LT. 1. ASOC. DE VIV. URB. SAN ROQUE. LOS OLIVOS",
                reference: "AV. PROCERES DE HUANDOY ESQUINA CON CENTRAL A UNA CDRA. DE LA AV. CENTRAL",
                district: "Los Olivos",
                latitude: -11.95995,
                longitude: -77.076328
            },
            {
                id: "192",
                FolderPath: "Tiendas Mass",
                name: "Pampas 4 LO MS",
                address: "AV. HUANDOY (ANTES PROCERES) MZ. A. LT. 41. AAHH LOS JAZMINES DEL NARANJAL. LOS OLIVOS.",
                reference: "CRUCE PROCEREWS DE HUANDOY CON PAMPAS A 2 CDRAS DE AV. NARANJAL",
                district: "Los Olivos",
                latitude: -11.978834,
                longitude: -77.084063
            },
            {
                id: "193",
                FolderPath: "Tiendas Mass",
                name: "CantoG 26 SJL MS",
                address: "SV. UND. INMOB. 1. MZ. F. LT 04. APV. SANTA ELIZABETH. SJL",
                reference: "FRENTE AL CRUCE DE LA AV. DEL PARQUE A MEDIA CUADRA DE MAXI AHORRO",
                district: "San Juan de Lurigancho",
                latitude: -11.984191,
                longitude: -77.01488
            },
            {
                id: "194",
                FolderPath: "Tiendas Mass",
                name: "Chavin 3 SA MS",
                address: "JR. CHAVIN 398. MZ. M-1. LT. 16. COOP. LOS CHANCAS DE ANDAHUAYLAS. SANTA ANITA",
                reference: "CRUCE DE LA CALLE CHAVIN CON LOS QUECHUAS A MEDIA CUADRA DEL MERCADO LAMPA DE ORO",
                district: "Santa Anita",
                latitude: -12.037428,
                longitude: -76.967115
            },
            {
                id: "195",
                FolderPath: "Tiendas Mass",
                name: "Monte 1 SJL MS",
                address: "SV. MZ. 01. LT. 04-AAHH ENRIQUE MONTENEGRO. SJL",
                reference: "FRENTE AL MERCADO SAGRADO CORAZON CRUCE DE CALLE MAR DE FLORES CON MAR DE BERING",
                district: "San Juan de Lurigancho",
                latitude: -11.935911,
                longitude: -76.972401
            },
            {
                id: "196",
                FolderPath: "Tiendas Mass",
                name: "Caceres 35 AT MS",
                address: "AV. ANDRES AVELINO CACERES. LT 35. TIENDA 1 Y 2. PROYECTO ESPECIAL HUAYCAN ZONA A. ATE.",
                reference: "FRENTE AL MERCADO LA ARENERA DE HUAYCAN A MEDIA CUADRA DEL CRUCE JR. 15 DE JULIO CON AV. CACERES",
                district: "Ate",
                latitude: -12.009028,
                longitude: -76.823283
            },
            {
                id: "197",
                FolderPath: "Tiendas Mass",
                name: "CantoG 32 SJL MS",
                address: "AV. CANTO GRANDE 3223. MZ. B 1. LT. 50. SJL.",
                reference: "FRENTE AL CRUCE CON AV. DEL PARQUE",
                district: "San Juan de Lurigancho",
                latitude: -11.974776,
                longitude: -77.008726
            },
            {
                id: "198",
                FolderPath: "Tiendas Mass",
                name: "Rosacruz 1 AT MS",
                address: "MZ. P. LOTE 23. 1ER PISO. ASOC. RESIDENCIAL LAS AMERICAS. ATE",
                reference: "FRENTE AL MERCADO LAS AMERICAS",
                district: "Ate",
                latitude: -12.033861,
                longitude: -76.903668
            },
            {
                id: "199",
                FolderPath: "Tiendas Mass",
                name: "RosaluzL 5 PP MS",
                address: "CALLE 5, MANZANA B, LOTE 5, URB. ROSA LUZ. PUENTE PIEDRA.",
                reference: "FRENTE AL TOTTUS DE COVIDA",
                district: "Puente Piedra",
                latitude: -11.880772,
                longitude: -77.06922
            },
            {
                id: "200",
                FolderPath: "Tiendas Mass",
                name: "Vallejo 15 LI MS",
                address: "AV. VALLEJO CESAR N° 1550, 1554. LINCE.",
                reference: "CRUCE DE LA AV. CESAR VALLEJO CON PRESCOTT",
                district: "Lince",
                latitude: -12.087971,
                longitude: -77.047542
            },
            {
                id: "201",
                FolderPath: "Tiendas Mass",
                name: "JPradoNO 3 AT MS",
                address: "MZ. N, LOTE 3. ASOC. LAS CASCADAS DE JAVIER PRADO. ATE",
                reference: "CRUCE DE JAVIER ERAUD CON CIRO ALEGRIA",
                district: "Ate",
                latitude: -12.035431,
                longitude: -76.924645
            },
            {
                id: "202",
                FolderPath: "Tiendas Mass",
                name: "Alamo 1 CA MS",
                address: "MZ. Q. LT. 03. ASOC. DE VIV. DE LOS TRABAJADORES DEL PUERTO DEL CALLAO. CALLAO",
                reference: "A ESPALDAS DEL C.C. EL ALAMO",
                district: "Callao",
                latitude: -12.0013,
                longitude: -77.107694
            },
            {
                id: "203",
                FolderPath: "Tiendas Mass",
                name: "Arquite 1 AN MS",
                address: "PANAMERICANA NORTE I ETAPA, GRUPO E, SECTOR I, MANZANA E2, LOTE 04. ANCÓN",
                reference: "CRUCE PANAMERICANA NORTE CON LOS ARQUITECTOS",
                district: "Ancón",
                latitude: -11.813599,
                longitude: -77.130085
            },
            {
                id: "204",
                FolderPath: "Tiendas Mass",
                name: "Compuertas CO MS",
                address: "CALLE LAS COMPUESTAS. MZ. B. LT 2. URB-EL PINAR. COMAS",
                reference: "FRENTE AL MERCADO LA HACIENDA AV. UNIVERSITARIA Y CALLE COMPUERTAS",
                district: "Comas",
                latitude: -11.906137,
                longitude: -77.052904
            },
            {
                id: "205",
                FolderPath: "Tiendas Mass",
                name: "Insurgent 7 SM MS",
                address: "AV. DE LOS INSURGENTES N° 732. URB. CAPITAN DE NAVIO LUIS GERMÁN ASTETE. SAN MIGUEL.",
                reference: "A UNA CDRA DE INSURGENTE CON PRECURSORES ",
                district: "San Miguel",
                latitude: -12.067847,
                longitude: -77.1039
            },
            {
                id: "206",
                FolderPath: "Tiendas Mass",
                name: "Luzuriaga 5 JM MS",
                address: "JR. MARISCAL TORIBIO LUZURIAGA N° 558. JESUS MARÍA.",
                reference: "A MEDIA CDRA DEL JR. HUIRACOCHA CON LUZURIAGA",
                district: "Jesús María",
                latitude: -12.076269,
                longitude: -77.048365
            },
            {
                id: "207",
                FolderPath: "Tiendas Mass",
                name: "Abtao 4 AN MS",
                address: "CALLE ABTAO N° 460 CERCADO. ANCON.",
                reference: "CERCA A LA PLAZA ANCON",
                district: "Ancón",
                latitude: -11.77389,
                longitude: -77.174588
            },
            {
                id: "208",
                FolderPath: "Tiendas Mass",
                name: "Durand 26 SL MS",
                address: "AV. AUGUSTO DURAND 2695. URB. SAN LUIS. SAN LUIS.",
                reference: "CRUCE DURAND CON BEINGOLEA",
                district: "San Luis",
                latitude: -12.070831,
                longitude: -76.995263
            },
            {
                id: "209",
                FolderPath: "Tiendas Mass",
                name: "Jaspes 20 SJL MS",
                address: "JR. LOS JASPES 2017. MZ. 2019-APV INCA MANCO CAPAC. SAN JUAN DE LURIGANCHO",
                reference: "A MEDIA CUADRA DE JASPES CON LOS GRANITOS FRENTE AL COLEGIO INCA MANCO CAPAC",
                district: "San Juan de Lurigancho",
                latitude: -11.997802,
                longitude: -76.999936
            },
            {
                id: "210",
                FolderPath: "Tiendas Mass",
                name: "SanJuan 10 SL MS",
                address: "AV. SAN JUAN 1032. URB. SAN LUIS. SAN LUIS",
                reference: "A MEDIA CDRA. DE PIZZERIA ROYS A MEDIA CDRA. DE OREGO CON SAN JUAN",
                district: "San Luis",
                latitude: -12.07483,
                longitude: -77.000198
            },
            {
                id: "211",
                FolderPath: "Tiendas Mass",
                name: "Andromed 1 CLL MS",
                address: "JR. ANDROMEDA. URB. MATELLINI. MZ. I-02. LT. 05. CHORILLOS.",
                reference: "CRUCE DE CALLE LOS MAESTROS CON ANDROMEDA",
                district: "Chorrillos",
                latitude: -12.170747,
                longitude: -77.00908
            },
            {
                id: "212",
                FolderPath: "Tiendas Mass",
                name: "Belaunde 5 CO MS",
                address: "AV. VICTOR A. BELAUNDE (ESTE) N° 533. P.J. EL CARMEN. COMAS",
                reference: "A DOS CUADRAS DEL MERCADO DEL CARMEN",
                district: "Comas",
                latitude: -11.940541,
                longitude: -77.044442
            },
            {
                id: "213",
                FolderPath: "Tiendas Mass",
                name: "CulturaG 4 SA MS",
                address: "AV. LA CULTURA MZ. G. LT. 04. COOP. VIÑA SAN FRANCISCO. SANTA ANITA",
                reference: "CRUCE LA CULTURA CON CALLE CHOCANO",
                district: "Santa Anita",
                latitude: -12.031541,
                longitude: -76.950438
            },
            {
                id: "214",
                FolderPath: "Tiendas Mass",
                name: "JoseM AN MS",
                address: "COOPERATIVA VIRGEN DEL ROSARIO, MANZANA I, LOTE 17. ANCÓN",
                reference: "EX MARGINAL ANCON",
                district: "Ancón",
                latitude: -11.77649,
                longitude: -77.167383
            },
            {
                id: "215",
                FolderPath: "Tiendas Mass",
                name: "Patriota 4 SM MS",
                address: "AV. DE LOS PATRIOTAS N° 409. URB. MARANGA. 3ERA ETAPA. SAN MIGUEL.",
                reference: "CRUCE DE PATRIOTAS CON AMERICO VESPUCIO",
                district: "San Miguel",
                latitude: -12.077032,
                longitude: -77.098306
            },
            {
                id: "216",
                FolderPath: "Tiendas Mass",
                name: "PECueva VTA MS",
                address: "LOTE 13, MZ. L- URB. PEDRO CUEVA VASQUEZ. VENTANILLA.",
                reference: "CRUCE DE GAMBETA CON CALLE 11",
                district: "Ventanilla",
                latitude: -11.879706,
                longitude: -77.124405
            },
            {
                id: "217",
                FolderPath: "Tiendas Mass",
                name: "Rosatoro 7 SL MS",
                address: "AV. AGUSTIN DE LA ROSA TORO 780. MZ. H. LT. 24. URB. LA VIÑA. SAN LUIS",
                reference: "CRUCE DE CALLE ALAVA Y RORA TORO",
                district: "San Luis",
                latitude: -12.078554,
                longitude: -76.994503
            },
            {
                id: "218",
                FolderPath: "Tiendas Mass",
                name: "Mala L7 MS",
                address: "ARTADI CASTILLO MANZANA D, LOTE 6, 7, 8 Y 9. MALA.",
                reference: "FRENTE AL MERCADO SAN PEDRO",
                district: "Mala",
                latitude: -12.653892,
                longitude: -76.635302
            },
            {
                id: "219",
                FolderPath: "Tiendas Mass",
                name: "Huaylas 9 CLL MS",
                address: "AV. DEFENSORES DEL MORRO (HUAYLAS) 957. URB. SAN JUAN. CHORRILLOS.",
                reference: "CRUCE DE HUAYLAS Y CALLE TERAN",
                district: "Chorrillos",
                latitude: -12.172591,
                longitude: -77.020533
            },
            {
                id: "220",
                FolderPath: "Tiendas Mass",
                name: "Tambo D25 CI MS",
                address: "AV. SAN MARTIN. MZ. D. LT 25. A. C.P.R. TAMBO VIEJO. ZONA D. CIENEGUILLA",
                reference: "CRUCE DE CALLE 119 Y CALLE PACHACUTEC",
                district: "Cieneguilla",
                latitude: -12.112506,
                longitude: -76.819177
            },
            {
                id: "221",
                FolderPath: "Tiendas Mass",
                name: "Proceres 1 SJL MS",
                address: "SV. UNID. INM. A-MZ J. LT. URB. CAJA DE AGUA. SAN JUAN DE LURIGANCHO",
                reference: "CRUCE DE PROCERES CON AV. LIMA",
                district: "San Juan de Lurigancho",
                latitude: -12.026114,
                longitude: -77.009888
            },
            {
                id: "222",
                FolderPath: "Tiendas Mass",
                name: "Gallesse 7 SM MS",
                address: "JR. FEDERICO GALLESE N° 753. HUERTIZACIÓN FUNDO SAN MIGUEL. SAN MIGUEL.",
                reference: "EX AV. SAN MIGUEL GALESSE CRUCE DE JR. MARISCAL CASTILLA CON AV. GALLESSE",
                district: "San Miguel",
                latitude: -12.090735,
                longitude: -77.082681
            },
            {
                id: "223",
                FolderPath: "Tiendas Mass",
                name: "Chimu 11 SJL MS",
                address: "AV. GRAN CHIMU 1170. URB. ZARATE. SAN JUAN DE LURIGANCHO",
                reference: "A MEDIA CDRA. DEL CRUCE DE LA AV. LAS LOMAS",
                district: "San Juan de Lurigancho",
                latitude: -12.024434,
                longitude: -76.99226
            },
            {
                id: "224",
                FolderPath: "Tiendas Mass",
                name: "Chimu 9 SJL MS",
                address: "AV. GRAN CHIMU 989-A/991 MZ. R 3. LT. 23. URB. ZARATE. SAN JUAN DE LURIGANCHO",
                reference: "FRENTE AL IEE ANTENOR ORRRGO ESPINOZA FRENTE AV. PORTADA DEL SOL Y GRAN CHIMU",
                district: "San Juan de Lurigancho",
                latitude: -12.024513,
                longitude: -76.996927
            },
            {
                id: "225",
                FolderPath: "Tiendas Mass",
                name: "DonDiego 2 SC MS",
                address: "AV. DON DIEGO DE DIA 228. MZ. A. LT. 13. URB. HUERTOS DE SAN ANTONIO. SANTIAGO DE SURCO.",
                reference: "CRUCE DEL JR. AFREND ROSENBLAD CON AV. DON DIEGO",
                district: "Santiago de Surco",
                latitude: -12.113445,
                longitude: -76.972649
            },
            {
                id: "226",
                FolderPath: "Tiendas Mass",
                name: "Flores 15 SJL MS",
                address: "AV. FLORES DE PRIMAVERA 1535. COOP. LAS FLORES. SJL.",
                reference: "CDRA. 15 DE LA AV. LAS FLORES",
                district: "San Juan de Lurigancho",
                latitude: -12.003497,
                longitude: -77.012525
            },
            {
                id: "227",
                FolderPath: "Tiendas Mass",
                name: "Noviembre 1 IN MS",
                address: "AV. 17 DE NOVIEMBRE N° 498. P.J. INDEPENDENCIA. ",
                reference: "CRUCE DE 17 DE NOVIEMBRE CON PRIMERO DE MAYO",
                district: "Independencia",
                latitude: -11.992439,
                longitude: -77.050588
            },
            {
                id: "228",
                FolderPath: "Tiendas Mass",
                name: "MicaelaN 4 VES MS",
                address: "SECTOR 01. GRUPO 19. MZ. N. LT. 04. VILLA EL SALVADOR. ",
                reference: "CRUCE DE MICAELA BASTIDAS CON PACTO ANDINO",
                district: "Villa El Salvador",
                latitude: -12.198515,
                longitude: -76.952237
            },
            {
                id: "229",
                FolderPath: "Tiendas Mass",
                name: "Tello 2 CDL MS",
                address: "AV. JULIO C. TELLO N° 209-211. CARMEN DE LA LEGUA.",
                reference: "CRUCE MIGUEL GRAU Y JULIO C. TELLO",
                district: "Carmen de la Legua Reynoso",
                latitude: -12.042062,
                longitude: -77.096912
            },
            {
                id: "230",
                FolderPath: "Tiendas Mass",
                name: "JoseCM 20 AT MS",
                address: "AV. JOSE CARLOS MARIATEGUI 2020. ATE",
                reference: "CRUCE DE MARIATEGUI CON SANTA ROSA",
                district: "Ate",
                latitude: -12.024982,
                longitude: -76.903135
            },
            {
                id: "231",
                FolderPath: "Tiendas Mass",
                name: "Canada 35 SL MS",
                address: "AV. CANADA 3599. MZ F. LT 26. URB. JAVIER PRADO. IV ETAPA. SAN LUIS",
                reference: "CRUCE DE ROSA TORO CON CANADA A MEDIA CUADRA ",
                district: "San Luis",
                latitude: -12.080299,
                longitude: -76.992895
            },
            {
                id: "232",
                FolderPath: "Tiendas Mass",
                name: "Merino 24 LI MS",
                address: "AV. MERINO, IGNACIO 2495-2497. LINCE.",
                reference: "ESQUINA DE MERINO CON JOSE DE LA TORRE UGARTE",
                district: "Lince",
                latitude: -12.088768,
                longitude: -77.031748
            },
            {
                id: "233",
                FolderPath: "Tiendas Mass",
                name: "Ancash LT3 EA MS",
                address: "JR. ANCASH. MZ. S. LT. 03. AAHH. ANCIETA ALTA. EL AGUSTINO.",
                reference: "A.H. ANCIETA ALTA",
                district: "El Agustino",
                latitude: -12.041453,
                longitude: -77.00299
            },
            {
                id: "234",
                FolderPath: "Tiendas Mass",
                name: "Chimu 4 SJL MS",
                address: "AV. GRAN CHIMU 462. MZ. R-1. LT. 06. URB. ZARATE. SAN JUAN DE LURIGANCHO.",
                reference: "A MEDIA CUADRA DE LA AV. CHIMU CON AYAR MANCO ",
                district: "San Juan de Lurigancho",
                latitude: -12.027378,
                longitude: -77.006158
            },
            {
                id: "235",
                FolderPath: "Tiendas Mass",
                name: "Brasil 30 MA MS",
                address: "BRASIL 3030 3040. TIENDA 3. MAGDALENA DEL MAR",
                reference: "A UNA CDRA DEL CRUCE DE PERSHING CON AV. BRASIL",
                district: "Magdalena del Mar",
                latitude: -12.087621,
                longitude: -77.064361
            },
            {
                id: "236",
                FolderPath: "Tiendas Mass",
                name: "MartinK 16 SJL MS",
                address: "SV. MZ. K. LT 16. COOP. VALLE SHARON",
                reference: "FRENTE AL MERCADO DEL PARADERO 1 DE HUASCAR",
                district: "San Juan de Lurigancho",
                latitude: -11.967103,
                longitude: -77.006251
            },
            {
                id: "237",
                FolderPath: "Tiendas Mass",
                name: "SFelipe 1 CO MS",
                address: "AV. UNIVERSITARIA NORTE N° 10655. MZ. P. LT. 06. URB. SAN FELIPE. II ETAPA. SECTOR B. COMAS",
                reference: "CRUCE UNIVERSITARIA CON SAN FELIPE",
                district: "Comas",
                latitude: -11.900283,
                longitude: -77.039681
            },
            {
                id: "238",
                FolderPath: "Tiendas Mass",
                name: "Fresnos 20 LM MS",
                address: "AV. LOS FRESNOS 2005. MZ. H2. LT.13. URB. PORTADA DEL SOL DE LA MOLINA. III ETAPA.",
                reference: "MZ H2 LT13",
                district: "La Molina",
                latitude: -12.112966,
                longitude: -76.93646
            },
            {
                id: "239",
                FolderPath: "Tiendas Mass",
                name: "SanLuis 20 SB MS",
                address: "AV. SAN LUIS 2084. TDA. 02. MZ. M4. LT. 31. SAN BORJA. I ETAPA. SECTOR D. 4TA SECCIÓN. SAN BORJA",
                reference: "CRUCE DE SAN BORJA NORTE CON SAN LUIS",
                district: "San Borja",
                latitude: -12.091109,
                longitude: -76.995944
            },
            {
                id: "240",
                FolderPath: "Tiendas Mass",
                name: "Callao 5 LP MS",
                address: "AV. CALLAO N° 515. URB. ALTAMAR II LA PERLA. CALLAO.",
                reference: "CRUCE DE AV. CALLAO CON CALLE TUMBES",
                district: "La Perla",
                latitude: -12.071607,
                longitude: -77.116595
            },
            {
                id: "241",
                FolderPath: "Tiendas Mass",
                name: "Canto 35 SJL MS",
                address: "SV. MZ. C. LT.2. URB. CANTO GRANDE. SJL",
                reference: "CRUCE AV. MACHUPICCHU Y CANTO GRANDE",
                district: "San Juan de Lurigancho",
                latitude: -11.971773,
                longitude: -77.007011
            },
            {
                id: "242",
                FolderPath: "Tiendas Mass",
                name: "Ruiseñore 3 SA MS",
                address: "AV. LOS RUISEÑORES 388. MZ. R1. LT. 28. URB. SANTA ANITA. SANTA ANITA",
                reference: "FRENTE AL CONDOMINIO RUISEÑORES ",
                district: "Santa Anita",
                latitude: -12.052302,
                longitude: -76.96558
            },
            {
                id: "243",
                FolderPath: "Tiendas Mass",
                name: "Teodoro 3 CE MS",
                address: "JR. CABO TEODORO CARDENAS 336. MANZANA 13-B. URB. SANTA BEATRIZ. LIMA.",
                reference: "CRUCE AL PETIT THOURS Y TEODORO",
                district: "Cercado de Lima",
                latitude: -12.077624,
                longitude: -77.033959
            },
            {
                id: "244",
                FolderPath: "Tiendas Mass",
                name: "Quechuas 9 AT MS",
                address: "AV. LOS QUECHUAS 920. MZ. 3H. LT. 14. URB. AGRUP. RESID. SALAMANCA. IV ETAPA.",
                reference: "FRENTE A TAMBO DE LOS QUECHUAS CDRA 9 CRUCE CON AV. TEPSICORE",
                district: "Ate",
                latitude: -12.074834,
                longitude: -76.983202
            },
            {
                id: "245",
                FolderPath: "Tiendas Mass",
                name: "Panama 54 SU MS",
                address: "AV. REPUBLICA DE PANAMA N° 5481. SURQUILLO.",
                reference: "A 2 CDRAS DEL GRIFO REPSOL ROCA Y BOLOGNA ESQUINA CRUCE CON AV. SERGIO BERNALES/AV. SERGIO BERNALES 107 TDA. 2",
                district: "Surquillo",
                latitude: -12.11849,
                longitude: -77.018082
            },
            {
                id: "246",
                FolderPath: "Tiendas Mass",
                name: "Heraud 4 SA MS",
                address: "CALLE JAVIER PRADO HERAUD 451. MZ. S2. LT. 15. URB. UNIVERSAL. SANTA ANITA.",
                reference: "A MEDIA CDRA DEL MERCADO DEL EDEN CRUCE DE CESAR VALLEJO CON JAVIER HERAUD",
                district: "Santa Anita",
                latitude: -12.04436,
                longitude: -76.978932
            },
            {
                id: "247",
                FolderPath: "Tiendas Mass",
                name: "Carapongo CHS MS",
                address: "AV. 2. MZ. F2. LT 1. URB. SAN ANTONIO DE CARAPONGO. LURIGANCHO.",
                reference: "FRENTE A SERENAZGO BASE CHOSICA",
                district: "Lurigancho-Chosica",
                latitude: -12.003262,
                longitude: -76.87503
            },
            {
                id: "248",
                FolderPath: "Tiendas Mass",
                name: "Pariachi 35 AT MS",
                address: "MZ. G. LT. 35. RESIDENCIAL PRADERAS DE PARIACHI. II ETAPA AMPLIACION. ATE.",
                reference: "AL COSTADO DEL CC. PARIACHI",
                district: "Ate",
                latitude: -12.00101,
                longitude: -76.834485
            },
            {
                id: "249",
                FolderPath: "Tiendas Mass",
                name: "Industria 1 AT MS",
                address: "AV. SEPARADORA INDUSTRIAL N° 3124. MZ. Q. LT. 03. ATE",
                reference: "AL FRENTE DE PLANTA DE BACKUS",
                district: "Ate",
                latitude: -12.053492,
                longitude: -76.940843
            },
            {
                id: "250",
                FolderPath: "Tiendas Mass",
                name: "Condorc L6 CB MS",
                address: "AV. CONDORCANQUI MZ. D-1. LOTE 06. URB. SANTO DOMINGO. 4TA ETAPA. CARABAYLLO.",
                reference: "CRUCE CONDORCANQUI CON PARQUE ZONAL",
                district: "Carabayllo",
                latitude: -11.881952,
                longitude: -77.032832
            },
            {
                id: "251",
                FolderPath: "Tiendas Mass",
                name: "AzucenasT 2 SA MS",
                address: "JR. LAS AZUCENAS. MZ. T. LT. 02. ASOC. SOL DE SANTA ANITA.",
                reference: "FRENTE AL MERCADO SEÑOÑR DE LOS MILAGROS",
                district: "Santa Anita",
                latitude: -12.04484,
                longitude: -76.95606
            },
            {
                id: "252",
                FolderPath: "Tiendas Mass",
                name: "Echenique CHS MS",
                address: "AV. LIMA NORTE 160. TIENDA 8. PARQUE ECHENIQUE. LURIGANCHO-CHOSICA",
                reference: "FRENTE AL PARQUE ECHENIQUE",
                district: "Lurigancho-Chosica",
                latitude: -11.93309,
                longitude: -76.69316
            },
            {
                id: "253",
                FolderPath: "Tiendas Mass",
                name: "SFelipe 6 CO MS",
                address: "AV. SAN FELIPE 641. MZ. D1. LT.9. URB. SAN FELIPE. COMAS",
                reference: "A MEDIA CDRA. MERCADO SAN FELIPE ENTRE AV. LIMA Y LA AV. UNIVERSITARIA",
                district: "Comas",
                latitude: -11.902573,
                longitude: -77.038575
            },
            {
                id: "254",
                FolderPath: "Tiendas Mass",
                name: "Brasil 20 PL MS",
                address: "AV. BRASIL 2048, DPTO Y 2068. PUEBLO LIBRE",
                reference: "A UNA CDRA. DEL CINE STAR LAS AMERICAS CRUCE AV. BRASIL Y CALLE LORETO",
                district: "Pueblo Libre",
                latitude: -12.078818,
                longitude: -77.057112
            },
            {
                id: "255",
                FolderPath: "Tiendas Mass",
                name: "Sunset 1 PH MS",
                address: "AV. SUNSET MZ. 5. LOTE 32. AGRUPACIÓN DE FAMILIAS SANTA CRUZ. PUNTA HERMOSA.",
                reference: "AL COSTADO DEL HOTEL ALOHA-T A MEDIA CDRA. DE LA AV. SUNSET Y CALLE SABO BLANCO",
                district: "Punta Hermosa",
                latitude: -12.332935,
                longitude: -76.827674
            },
            {
                id: "256",
                FolderPath: "Tiendas Mass",
                name: "CarabayA 4 CO MS",
                address: "AV. CARABAYLLO (AV. 4). MZ. A. LT. 4. URB. SANTA MOLINA. II. ETAPA. COMAS.",
                reference: "AL COSTADO DEL MERCADO FUTURO PERU CRUCE DE LA AV. CARABAYLLO CON CALLE 3",
                district: "Comas",
                latitude: -11.95832,
                longitude: -77.058656
            },
            {
                id: "257",
                FolderPath: "Tiendas Mass",
                name: "Marañon 6 LO MS",
                address: "AV. RIO MARAÑON MZ. N. LT. 4. URB. VILLA  DEL NORTE. LOS OLIVOS.",
                reference: "A MEDIA CDRA. DE TAMBO Y MERCADO VILLA NORTE FRENTE A INKAFARMA A 3 CDRAS DE LA AV. PALMERAS CON MARAÑON",
                district: "Los Olivos",
                latitude: -11.972248,
                longitude: -77.072063
            },
            {
                id: "258",
                FolderPath: "Tiendas Mass",
                name: "Cornejo 14 CE MS",
                address: "AV. MARIANO H. CORNEJO 1456. MZ. A. LT. 27. URB. LAS BRISAS. CERCADO DE LIMA.",
                reference: "MEDIA CDRA DE LA HUACA MATEO SALADO A 1 CDRA DE AV. ALBORADA CON AV. MARIANO CORNEJO",
                district: "Cercado de Lima",
                latitude: -12.067493,
                longitude: -77.066234
            },
            {
                id: "259",
                FolderPath: "Tiendas Mass",
                name: "Calle19 B1 PA MS",
                address: "CALLE 19. MZ. B1. LT 05. AAHH. PORTADA DE MANCHAY III. PACHACAMAC.",
                reference: "PLAZA PORTADA DE MANCHAY III CALLE 19 CON AV. PRINCIPAL",
                district: "Pachacamac",
                latitude: -12.09464,
                longitude: -76.88429
            },
            {
                id: "260",
                FolderPath: "Tiendas Mass",
                name: "Jiron8H 2 CO MS",
                address: "JR. 8. N° 400-402. PSJE. 160. MZ. H. LT. 2. URB. CARABAYLLO. COMAS",
                reference: "AL FRENTE DEL MERCADO PLAZA 8",
                district: "Comas",
                latitude: -11.96589,
                longitude: -77.05985
            },
            {
                id: "261",
                FolderPath: "Tiendas Mass",
                name: "Rosa 1 SC MS",
                address: "JR. DOÑA ROSA 172. URB. LOS ROSALES. SANTIAGO DE SURCO.",
                reference: "A MEDIA CDRA DE POLLERIA VILLA CHIKEN CRUCE DE LAS CALLES DOÑA ROSA Y DOÑA ESPERANZA",
                district: "Santiago de Surco",
                latitude: -12.140446,
                longitude: -76.99854
            },
            {
                id: "262",
                FolderPath: "Tiendas Mass",
                name: "SolDeOro 21 LO MS",
                address: "JR. SOL DE ORO 2101. URB. EL TREBOL. LOS OLIVOS.",
                reference: "A MEDIA CDRA. MERCADO EL TREBOL CERCA A BBVA JR. SOL DE ORO Y LORENZO FARFAN",
                district: "Los Olivos",
                latitude: -12.005932,
                longitude: -77.062909
            },
            {
                id: "263",
                FolderPath: "Tiendas Mass",
                name: "Lima 3632 SMP MS",
                address: "AV. LIMA 3632. URB. PERU. SAN MARTIN DE PORRES.",
                reference: "CRUCE DE AV. LIMA CON JR. CALLAO A UNAS CDRAS DEL MERCADO ZONAL ",
                district: "San Martín de Porres",
                latitude: -12.028574,
                longitude: -77.085825
            },
            {
                id: "264",
                FolderPath: "Tiendas Mass",
                name: "Berlind 32 AT MS",
                address: "CALLE BERLIND MZ. D. LOTE 32. ASOC. VIVIENDA LAS CASCADAS DE JAVIER PRADO. ATE.",
                reference: "A 3 CDRAS DEL REAL PLAZA PURUCHUCO A MEDIA CDRA DE CALLE BERLIN CON CALLE GRAN BRETAÑA",
                district: "Ate",
                latitude: -12.038978,
                longitude: -76.92618
            },
            {
                id: "265",
                FolderPath: "Tiendas Mass",
                name: "Cpescador 2 BE MS",
                address: "CALLE 21 N° 104. URB. CIUDAD DEL PESCADOR. BELLAVISTA.CALLAO.",
                reference: "AL LADO DE LA COMISARIA CIUDAD DEL PESCADOR CRUCE CALLE 21 CON CALLE 6D",
                district: "Bellavista",
                latitude: -12.058805,
                longitude: -77.105559
            },
            {
                id: "266",
                FolderPath: "Tiendas Mass",
                name: "Castilla 12 MA MS",
                address: "MARISCAL CASTILLA 1260. MAGDALENA DEL MAR.",
                reference: "A MEDIA CDRA DE INVERSIONES DE LA CRUZ CRUCE DE JR. CASTILLA Y CALLE 28 DE JULIO",
                district: "Magdalena del Mar",
                latitude: -12.085264,
                longitude: -77.071499
            },
            {
                id: "267",
                FolderPath: "Tiendas Mass",
                name: "Pucusana 1 MS",
                address: "JR. CALLAO 115, MZNA. 10, LT.07 PUCUSANA.",
                reference: "FRENTE A PLAZA DE ARMAS DE PUCUSANA ENTRE LA CALLE GRAU Y LA CALLE LIMA",
                district: "Pucusana",
                latitude: -12.481504,
                longitude: -76.797378
            },
            {
                id: "268",
                FolderPath: "Tiendas Mass",
                name: "Calle7 A1 CLL MS",
                address: "CALLE 07. ASOC. VIV. DE LA VIÑA DE ATE. MZ. A. LT. 02. CHORILLOS.",
                reference: "AL COSTADO DEL MERCADO LOS PINOS A UNAS CDRAS. DEL PARQUE ZONAL",
                district: "Chorrillos",
                latitude: -12.188762,
                longitude: -77.014808
            },
            {
                id: "269",
                FolderPath: "Tiendas Mass",
                name: "Eleboros 5 SJL MS",
                address: "JR. LOS ELEBOROS 517-C-MZ 50. LT. 10-URB. LAS FLORES. SAN JUAN DE LURIGANCHO",
                reference: "A UNA CDRA DEL MERCADO EL CARMEN CRUCE DE ELEBOROS CON BELLOTITAS",
                district: "San Juan de Lurigancho",
                latitude: -12.017528,
                longitude: -77.009735
            },
            {
                id: "270",
                FolderPath: "Tiendas Mass",
                name: "CruzMotuE 2 PP MS",
                address: "ASOCIACIÓN DE VIVIENDA MILAGROSA CRUZ DE MOTUPE, MANZANA E, LOTE 02. PUENTE PIEDRA.",
                reference: "CERCA AL MERCADO CRUZ DE MOTUPE KM 27 PANAMERICANA NORTE",
                district: "Puente Piedra",
                latitude: -11.890203,
                longitude: -77.067136
            },
            {
                id: "271",
                FolderPath: "Tiendas Mass",
                name: "Pinos 14 CO MS",
                address: "AV. LOS PINOS (B). MZ. P1. LT. 8. URB. EL PINAR. COMAS",
                reference: "AL COSTADO DEL MERCADO EL PINAR A UNAS CDRAS DEL PARQUE SAN MARTIN",
                district: "Comas",
                latitude: -11.91441,
                longitude: -77.05586
            },
            {
                id: "272",
                FolderPath: "Tiendas Mass",
                name: "AgueroÑ 24 SM MS",
                address: "AV. JOSE DE LA RIVAGUERO MZ. N. LT. 24. URB. PANDO IX ETAPA. SAN MIGUEL.",
                reference: "A 2 CDRAS DE LA UNIVERSIDAD SAN MARCOS CRUCE DE LA AV. RIVAGUERO CON LOS NARANJOS",
                district: "San Miguel",
                latitude: -12.063153,
                longitude: -77.082601
            },
            {
                id: "273",
                FolderPath: "Tiendas Mass",
                name: "Pariahuan 6 LO MS",
                address: "JR. PARIAHUANCA N° 607, MANZANA X, LOTE 24. URB. PARQUE DE NARANJAL, I ETAPA. LOS OLIVOS.",
                reference: "CERCA AL MINI MERCADO VIRGEN DEL ROSARIO CRUCE DE JR. PARIAHUANCA CON JR. ANITA",
                district: "Los Olivos",
                latitude: -11.980451,
                longitude: -77.070384
            },
            {
                id: "274",
                FolderPath: "Tiendas Mass",
                name: "Aviacion 42 SU MS",
                address: "AV. AVIACIÓN 4240. URB. LA CALERA DE LA MERCED. SURQUILLO.",
                reference: "FRENTE AL GRIFO PETRO AMERICA A UNA CDRA DEL CRUCE CON CALLE EMILIANO ZAPATA CON AVIACION",
                district: "Surquillo",
                latitude: -12.118346,
                longitude: -76.998668
            },
            {
                id: "275",
                FolderPath: "Tiendas Mass",
                name: "Gaviotas 33 SC MS",
                address: "JR. LAS GAVIOTAS U.IMB 1 TDA 1. MZ. A4. LT 33. URB. SAGITARIO. SANTIAGO DE SURCO",
                reference: "AL COSTADO DE LA COMISARIA DE SAGITARIO CRUCE DE AV. GAVIOTAS CON CALLE ARTEMISA",
                district: "Santiago de Surco",
                latitude: -12.165038,
                longitude: -76.996976
            },
            {
                id: "276",
                FolderPath: "Tiendas Mass",
                name: "Napo 2 AT MS",
                address: "CALLE RIO NAPO. MZ. O. LT. 2. SECTOR F-ASOC. PROP. VIV. LOS ANGELES DE VITARTE. 1ER PISO. ATE.",
                reference: "FRENTE AL MERCADO LOS ANGELES CRUCE DE RIO NAPO CON RIO UCAYALI",
                district: "Ate",
                latitude: -12.034694,
                longitude: -76.932089
            },
            {
                id: "277",
                FolderPath: "Tiendas Mass",
                name: "Belisario 9 SJM MS",
                address: "AV. BELISARIO SUAREZ N° 965. SAN JUAN DE MIRAFLORES.",
                reference: "FRENTE A LA CLINICA SANTA MARIA DEL SUR CRUCE DE AV. BELISARIO SUAREZ Y CALLE JUAN VALENTIN ESPEJO",
                district: "San Juan de Miraflores",
                latitude: -12.16264,
                longitude: -76.965459
            },
            {
                id: "278",
                FolderPath: "Tiendas Mass",
                name: "Aleliet 12 SJM MS",
                address: "CALLE LOS ALELIES MZ. T. LT. 12 COOP. VIV. VALLE SHARON PAMPAS DE SAN JUAN. SAN JUAN DE MIRAFLORES.",
                reference: "FRENTE AL MERCADO SHARON CALLE ANDRES GUZMAN CON LOS EUCALIPTOS",
                district: "San Juan de Miraflores",
                latitude: -12.170726,
                longitude: -76.966052
            },
            {
                id: "279",
                FolderPath: "Tiendas Mass",
                name: "Donofrio 2 BR MS",
                address: "JR. PEDRO DONOFRIO N° 215. BREÑA",
                reference: "MERCADO PROVEEDORES UNIDOS CRUCE DE LA CALLE DONOFRIO CON EL JR. PASTAZA",
                district: "Breña",
                latitude: -12.06413,
                longitude: -77.05881
            },
            {
                id: "280",
                FolderPath: "Tiendas Mass",
                name: "Sucre 906 PL MS",
                address: "ANTONIO JOSE DE SUCRE N° 906. PUEBLO LIBRE.",
                reference: "AL COSTADO DEL PARDOS CHICKEN CRUCE DE LA AV. SUCRE CON LA CALLE NICOLAS ALCAZAR",
                district: "Pueblo Libre",
                latitude: -12.08043,
                longitude: -77.06619
            },
            {
                id: "281",
                FolderPath: "Tiendas Mass",
                name: "Ayllon 416 CY MS",
                address: "AV. AYLLON 416-418. CHACLACAYO.",
                reference: "AL COSTADO DEL BCP CHACLACAYO",
                district: "Chaclacayo",
                latitude: -11.97672,
                longitude: -76.77336
            },
            {
                id: "282",
                FolderPath: "Tiendas Mass",
                name: "Azule 11 CLL MS",
                address: "JR. COSTA AZUL. URB. JOSE OLAYA. MZ. E. LT. 11. CHORRILLOS.",
                reference: "A UNA CUADRA DEL PARADERO PLAZA LIMA SUR DEL METROPOLITANO",
                district: "Chorrillos",
                latitude: -12.174167,
                longitude: -77.015543
            },
            {
                id: "283",
                FolderPath: "Tiendas Mass",
                name: "AlbertoG 1 LO MS",
                address: "JR. SAN ALBERTO MZ. G. LT. 01. URB. SANTA ROSA. LOS OLIVOS.",
                reference: "AL COSTADO DEL CENTRO COMERCIAL SANTA ROSA DE LOS OLIVOS",
                district: "Los Olivos",
                latitude: -12.008281,
                longitude: -77.077687
            },
            {
                id: "284",
                FolderPath: "Tiendas Mass",
                name: "Caceres 32 PP MS",
                address: "AA. HH. MARISCAL CACERES. MZ. A. LT. 32. PUENTE PIEDRA.",
                reference: "A DOS LOTES DEL PARADERO FUNDICION",
                district: "Puente Piedra",
                latitude: -11.833421,
                longitude: -77.112846
            },
            {
                id: "285",
                FolderPath: "Tiendas Mass",
                name: "AlisosL 8 SMP MS",
                address: "AV. LOS ALISOS MZ. D2. LT. 8. URB. ROSARIO DEL NORTE. SAN MARTIN DE PORRES.",
                reference: "AL COSTADO DEL COLEGIO PRIVADO LICEO SAN JUAN",
                district: "San Martín de Porres",
                latitude: -11.983,
                longitude: -77.08198
            },
            {
                id: "286",
                FolderPath: "Tiendas Mass",
                name: "Lima 41 SMP MS",
                address: "AV. LIMA 4199. MZ. 4B. LOTE 8. URB. PERU. SAN MARTIN DE PORRES",
                reference: "FRENTE A TOTUS",
                district: "San Martín de Porres",
                latitude: -12.028097,
                longitude: -77.09319
            },
            {
                id: "287",
                FolderPath: "Tiendas Mass",
                name: "Cusco 7 MA MS",
                address: "JR. CUZCO (EX JOSE BARRENECHEA) 713. MAGDALENA DEL MAR.",
                reference: "AL FRENTE DE LA COMISARÍA DE MAGDALENA DEL MAR -CRUCE DE JR. CUSCO CON JR. CASTILLA",
                district: "Magdalena del Mar",
                latitude: -12.088257,
                longitude: -77.073117
            },
            {
                id: "288",
                FolderPath: "Tiendas Mass",
                name: "Alborada 14 CE MS",
                address: "AV. LA ALBORADA 1417. MZ. F. LT. 01. URB. LAS BRISAS. CERCADO DE LIMA.",
                reference: "FRENTE AL NIDO MIS PEQUEÑOS ANGELITOS - CRUCE DE AV ALBORADA CON CALLE LOS GERANIOS",
                district: "Cercado de Lima",
                latitude: -12.062674,
                longitude: -77.06647
            },
            {
                id: "289",
                FolderPath: "Tiendas Mass",
                name: "Tristan 6 LM MS",
                address: "AV. FLORA TRISTAN 633. MZ. W. LT 39. URB. SANTA PATRICIA. II ETAPA. LA MOLINA",
                reference: "A MEDIA CUADRA DE POLLERÍA LAS CANASTAS - A MEDIA CUADRA DE JR. MAR DE PLATA Y FLORA TRISTAN",
                district: "La Molina",
                latitude: -12.06824,
                longitude: -76.943279
            },
            {
                id: "290",
                FolderPath: "Tiendas Mass",
                name: "Sucre 13 PL MS",
                address: "AV. ANTONIO JOSE DE SUCRE N° 1362. PUEBLO LIBRE.",
                reference: "AL COSTADO DEL SEMINARIO SANTO TORIBIO- CRUCE DE LA AV. ANTONIO JOSE DE SUCRE CON JR. AMAZONAS",
                district: "Pueblo Libre",
                latitude: -12.084295,
                longitude: -77.067796
            },
            {
                id: "291",
                FolderPath: "Tiendas Mass",
                name: "SanLuis 9 SL MS",
                address: "AV. SAN LUIS N° 964. URB. LAS MORAS. SAN LUIS.",
                reference: "A UNA CUADRA DEL CONDOMINIO DEL AIRE-A MEDIA CUADRA DE LA AV. SAN JUAN",
                district: "San Luis",
                latitude: -12.076301,
                longitude: -77.000535
            },
            {
                id: "292",
                FolderPath: "Tiendas Mass",
                name: "Fresnos 17 LM MS",
                address: "AV. LOS FRESNOS 1766. MZ. A.10. LT.11. URB. PORTADA DEL SOL DE LA MOLINA. II ETAPA. LA MOLINA",
                reference: "A MEDIA CUADRA DEL SANGUCHON CAMPESINO-A MEDIA CUADRA DEL CRUCE DE LA CALLE CHAN CHAN Y AV. LOS FRESNOS",
                district: "La Molina",
                latitude: -12.110281,
                longitude: -76.93868
            },
            {
                id: "293",
                FolderPath: "Tiendas Mass",
                name: "Benja 5 AT MS",
                address: "AV. BENJAMIN FRANKLIN 574. ZONA URBANA AAHH SICUANI. ATE",
                reference: "FRENTE AL MERCADO SANTA ROSA - CRUCE DE AV. MICHAEL FARADAY Y AV. FRANKLIN BENJAMIN",
                district: "Ate",
                latitude: -12.06722,
                longitude: -76.9757
            },
            {
                id: "294",
                FolderPath: "Tiendas Mass",
                name: "Libertad 7 SM MS",
                address: "JR. LIBERTAD N° 743. AGRUPACIÓN LA PAZ. SAN MIGUEL.",
                reference: "A MEDIA CUADRA DEL MERCADO MODELO SAN MIGUEL - CRUCE DE LA AV. LIBERTAD CON LA CALLE COMANDANTE LADISIAO ESPINAR",
                district: "San Miguel",
                latitude: -12.085944,
                longitude: -77.089519
            },
            {
                id: "295",
                FolderPath: "Tiendas Mass",
                name: "GuardiaA 7 CLL MS",
                address: "AV. GUARDIA PERUANA. URB. PASEO DE LA REPUBLICA. MZ. A-07. LT 02. CHORRILLOS",
                reference: "A UNA CUADRA DEL TAMBO DE AV. MATELLINI CON GUARDIA PERUANA-",
                district: "Chorrillos",
                latitude: -12.177471,
                longitude: -77.006102
            },
            {
                id: "296",
                FolderPath: "Tiendas Mass",
                name: "Trujillo 4 MA MS",
                address: "JR. TRUJILLO 425-427. MAGDALENA DEL MAR.",
                reference: "CRUCE DE LA AV. JAVIER PRADO OESTE CON EL JR. TRUJILLO - OSINFOR",
                district: "Magdalena del Mar",
                latitude: -12.093336,
                longitude: -77.062121
            },
            {
                id: "297",
                FolderPath: "Tiendas Mass",
                name: "Moreno 9 SU MS",
                address: "JR. FRANCISCO MORENO N° 969. SURQUILLO.",
                reference: "A UNA CUADRA DEL MERCADO N°2 DE SURQUILLO - A MEDIA CUADRA DEL CRUCE DE CALLE LIZARDO MONTERO Y FRANCISCO MORENO",
                district: "Surquillo",
                latitude: -12.11177,
                longitude: -77.024606
            },
            {
                id: "298",
                FolderPath: "Tiendas Mass",
                name: "BlancoA 16 SB MS",
                address: "CALLE CERRO BLANCO (EX. CA. 4). A.H. S.J. MASIAS 166. MZ. A. LT. 16. SAN JUAN MASIAS. SAN BORJA.",
                reference: "A UNA CUADRA A LA ESPALDA DEL MUSEO DE LA NACIÓN - A UNA CUADRA DEL CRUCE DE CALLE CERRO BLANCO Y AV. CANADÁ",
                district: "San Borja",
                latitude: -12.084925,
                longitude: -77.003466
            },
            {
                id: "299",
                FolderPath: "Tiendas Mass",
                name: "Madresel 3 CB MS",
                address: "JR. MADRE SELVA 385. URB. SANTA ISABEL. COMAS",
                reference: "FRENTE AL MERCADO SAN PEDRO - CRUCE DE JR. MADRESELVAS Y JR. AMARANTOS",
                district: "Carabayllo",
                latitude: -11.89995,
                longitude: -77.03725
            },
            {
                id: "300",
                FolderPath: "Tiendas Mass",
                name: "Lima 2 SM MS",
                address: "LIMA 270. MZ. C. LT. 04. URB. UDIMA Y LIBERTAD. SAN MIGUEL",
                reference: "A MEDIA CUADRA DE COLEGIO AMÉRICA - CRUCE DE LA AV. LIMA CON EL JR. SAN MARTIN",
                district: "San Miguel",
                latitude: -12.09024,
                longitude: -77.077095
            },
            {
                id: "301",
                FolderPath: "Tiendas Mass",
                name: "SAntonio CB MS",
                address: "EL BOULEVARD DE SAN ANTONIO, MANZANA C9, LOTE 17. CARABAYLLO.",
                reference: "AL LADO DEL CONDOMINIO \"TAMBO VERDE\" - CRUCE DE AV. SAN JUAN DE DIOS Y AV. CAMINO REAL (AVENIDA B)",
                district: "Carabayllo",
                latitude: -11.857539,
                longitude: -77.043992
            },
            {
                id: "302",
                FolderPath: "Tiendas Mass",
                name: "Marsano 5 SC MS",
                address: "AV. SANTIAGO DE SURCO N° 3503. TIENDA 000002. URB. LIGURIA. SANTIAGO DE SURCO.",
                reference: "A UNA CUADRA DEL CRUCE DE LA AV. AYACUCHO CON TOMAS MARSANO - A UNA CUADRA DE GRIFO PRIMAX DE TOMAS MARSANO",
                district: "Santiago de Surco",
                latitude: -12.133335,
                longitude: -76.997756
            },
            {
                id: "303",
                FolderPath: "Tiendas Mass",
                name: "Construc 5 LM MS",
                address: "AV. LOS CONSTRUCTORES. 545. MZ. Q. LT. 34. URB. LAS ACACIAS DE MONTERRICO.",
                reference: "A UNA CUADRA DE PARQUE VIRGEN DE ASUCIÓN  DE LA AV. CONSTRUCTORES - A MEDIA CUADRA DEL CRUCE DE AV. CONSTRUCTORES CON JIRÓN FORESTALES",
                district: "La Molina",
                latitude: -12.065164,
                longitude: -76.95374
            },
            {
                id: "304",
                FolderPath: "Tiendas Mass",
                name: "Sarria 12 CE MS",
                address: "AV. VICTOR SARRIA ARZUBIAGA 1216. MZ. C. LT. 16. URB. ELIO. CERCADO DE LIMA.",
                reference: "FRENTE AL COLEGIO HIPOLITO UNANUE  CRUCE DE SARRIA CON CALLE JOSÉ MARÍA SEGUIN",
                district: "Cercado de Lima",
                latitude: -12.051466,
                longitude: -77.071153
            },
            {
                id: "305",
                FolderPath: "Tiendas Mass",
                name: "Leyendas 3 SM MS",
                address: "AV. PARQUE DE LAS LEYENDAS 335. URB. PANDO 1ERA ETAPA. SAN MIGUEL",
                reference: "A UNAS CUADRAS DEL COLEGIO CLARETIANO Y DEL PARQUE DE LAS LEYENDAS/CRUCE DE CALLE LAS LEYENDAS CON LA MARINA",
                district: "San Miguel",
                latitude: -12.075001,
                longitude: -77.087506
            },
            {
                id: "306",
                FolderPath: "Tiendas Mass",
                name: "Alcides 2 Smp MS",
                address: "AV. DANIEL ALCIDES CARRION  620. URB. POPULAR ASOC. VIV. SAN FRANCISCO DE CAYRAN. SAN MARTÍN DE PORRES.",
                reference: "PARQUE COVITI / HUACA STA ROSA / A 4 CDR DEL REST. RINCONCITO AYACUCHANO-CRUCE JR 1 DE OCTUBRE CON AV. DANIEL ALCIDES CARRION",
                district: "San Martín de Porres",
                latitude: -11.993828,
                longitude: -77.090911
            },
            {
                id: "307",
                FolderPath: "Tiendas Mass",
                name: "Bolivar 16 PL MS",
                address: "AV. SIMON BOLIVAR 1695. PUEBLO LIBRE",
                reference: "AL COSTADO DEL COLEGIO PARTICULAR JORGE POLAR",
                district: "Pueblo Libre",
                latitude: -12.071803,
                longitude: -77.072155
            },
            {
                id: "308",
                FolderPath: "Tiendas Mass",
                name: "Caminos 31 SC MS",
                address: "AV. CAMINOS DEL INCA N° 3160. MZ. B. LT. 31. URB. PROLG. BENAVIDES. SANTIAGO DE SURCO.",
                reference: "AL COSTADO DE TAMBO DE LA CUADRA 31 DE CAMINOS DEL INCA/CRUCE DE CAMINOS DEL INCA CON TINOCO",
                district: "Santiago de Surco",
                latitude: -12.14252,
                longitude: -76.985232
            },
            {
                id: "309",
                FolderPath: "Tiendas Mass",
                name: "Tizon 5 JM MS",
                address: "AV. TIZON Y BUENO. RICARDO N° 547. JESUS MARIA.",
                reference: "MODERNO MERCADO JESUS MARÍA/CRUCE DE AV. RICARDO TIZÓN Y BUENO CON JR. HUIRACOCHA",
                district: "Jesús María",
                latitude: -12.079097,
                longitude: -77.050726
            },
            {
                id: "310",
                FolderPath: "Tiendas Mass",
                name: "SanJose 5 BE MS",
                address: "AV. SAN JOSE  511. URB. SAN JOSE. BELLAVISTA. CALLAO",
                reference: "A MEDIA CUADRA DE LA CLINICA AUNA BELLAVISTA/CRUCE DE LA AV. SAN JOSE CON JR. LOS CONDORES",
                district: "Bellavista",
                latitude: -12.056811,
                longitude: -77.096132
            },
            {
                id: "311",
                FolderPath: "Tiendas Mass",
                name: "SevillaD 7 VES MS",
                address: "COOP VIRGEN DE COCHARCAS MZ. D LOTE 7 Y 8",
                reference: "A 4 CASAS DEL MERCADO VIRGEN DE COCHARCAS/ENTRE AV. PASTOR SEVILLA Y AV. MATEO PUMACAHUA",
                district: "Villa El Salvador",
                latitude: -12.1949519,
                longitude: -76.9599604
            },
            {
                id: "312",
                FolderPath: "Tiendas Mass",
                name: "Filomeno 2 RI MS",
                address: "AV. ARMANDO FILOMENO 200. RIMAC",
                reference: "A 1 CUADRA DE LA CAPILLA SANTA ROSA            ",
                district: "Rimac",
                latitude: -12.024249,
                longitude: -77.028918
            },
            {
                id: "313",
                FolderPath: "Tiendas Mass",
                name: "Castilla 6 SM MS",
                address: "JR. RAMON CASTILLA MZ. B. LOTE. 8 (N° 647) URB. PAULO SEXTO. SAN MIGUEL.",
                reference: "A 80 M DEL HOSPITAL GERIATRICO SAN JOSE PNP/CRUCE DEL JR. MARISCAL RAMON CASTILLA Y LA PROLONGACIÓN JR. CUZCO",
                district: "San Miguel",
                latitude: -12.086,
                longitude: -77.080558
            },
            {
                id: "314",
                FolderPath: "Tiendas Mass",
                name: "Caminos 14 SC MS",
                address: "AV. CAMINOS DEL INCA N° 1463. LOCAL 1A. URB. LAS GARDENIAS. SANTIAGO DE SURCO.",
                reference: "AL COSTADO DEL RESTAURANTE PICCOLO POMODORO/A MEDIA CUADRA DEL CRUCE CON JR. BATALLÓN CALLAO SUR",
                district: "Santiago de Surco",
                latitude: -12.124385,
                longitude: -76.983765
            },
            {
                id: "315",
                FolderPath: "Tiendas Mass",
                name: "Alameda 12 LM MS",
                address: "AV. ALAMEDA DEL CORREGIDOR 1207 MZ. H LT. 16 URB. SIRIUS 1ERA ETAPA",
                reference: "FRENTE AL PARQUE NACIONES UNIDAS EN AV. ALAMEDA DEL CORREGIDOR/CRUCE CON LA CALLE LOS BAMBUES",
                district: "La Molina",
                latitude: -12.093237,
                longitude: -76.952476
            },
            {
                id: "316",
                FolderPath: "Tiendas Mass",
                name: "Revoluc 14 CO MS",
                address: "AV. REVOLUCION 1429. A.H. COLLIQUE III. ZONA. COMAS",
                reference: "MERCADO SANTA ROSA DE COLLIQUE/CRUCE DE LA AV. REVOLUCIÓN CON LA AV. JULIO C. TELLO",
                district: "Comas",
                latitude: -11.914076,
                longitude: -77.028834
            },
            {
                id: "317",
                FolderPath: "Tiendas Mass",
                name: "QuechuaE 20 AT MS",
                address: "AV. LOS QUECHUAS MZ. E. LT. 20. URB. LOS ALAMOS. ATE.",
                reference: "A MEDIA CUADRA DEL COLEGIO MARÍA DE LA ENCARNACIÓN",
                district: "Ate",
                latitude: -12.073714,
                longitude: -76.986531
            },
            {
                id: "318",
                FolderPath: "Tiendas Mass",
                name: "Miller 9 JM MS",
                address: "JR. MARISCAL MILLER 914. JESUS MARÍA.",
                reference: "FRENTE A LA COOPERATIVA VIPOL/&CRUCE DE JR. MARISCAL MILLER CON JR. CORONEL CAMILO CARRILLO",
                district: "Jesús María",
                latitude: -12.073667,
                longitude: -77.038229
            },
            {
                id: "319",
                FolderPath: "Tiendas Mass",
                name: "Ayllon 9 CY MS",
                address: "NICOLAS AYLLON 964. CHACLACAYO",
                reference: "FRENTE AL METRO DE CHACLACAYO",
                district: "Chaclacayo",
                latitude: -11.974926,
                longitude: -76.767543
            },
            {
                id: "320",
                FolderPath: "Tiendas Mass",
                name: "OlivosD 8 SMP MS",
                address: "AV. LOS OLIVOS MZ. D. LT. 08. ASOC. VIRGEN DE LAS NIEVES. SAN MARTIN DE PORRES.",
                reference: "AV. LOS OLIVOS MZ. D LT. 08 - ASOC. VIRGEN DE LAS NIEVES SMP",
                district: "San Martín de Porres",
                latitude: -11.985445,
                longitude: -77.093366
            },
            {
                id: "321",
                FolderPath: "Tiendas Mass",
                name: "Ejercito 17 SI MS",
                address: "AV. PEREZ ARANIBAR AUGUSTO 1780. SAN ISIDRO",
                reference: "ENTE AL CENTRO DE SALUD SAN ISIDRO/CRUCE CON CALLE VALLE RIESTRA",
                district: "San Isidro",
                latitude: -12.10622,
                longitude: -77.05512
            },
            {
                id: "322",
                FolderPath: "Tiendas Mass",
                name: "Ordonez 1 SB MS",
                address: "JR. ORDOÑEZ JONHSONN EDUARDO 198. MZ. D. LT. 11. 12. SAN TOMAS. SAN BORJA.",
                reference: "AL COSTADO DE LA CLINICA VESALIO/CRUCE DE CALLE SIR JOSEPH JOHN THOMPSON Y JR. EDUARDO ORDOÑEZ JOHNSON",
                district: "San Borja",
                latitude: -12.10589,
                longitude: -77.007254
            },
            {
                id: "323",
                FolderPath: "Tiendas Mass",
                name: "Cutervo 18 CE MS",
                address: "JR. CUTERVO 1866. MZ. 09. LT. 02. URB. CHACRA RIOS NORTE. LIMA",
                reference: "A UNA CUADRA DEL MERCADO EL PROGRESO/CRUCE DE JIRÓN SANTIAGO CON JIRÓN CUTERVO",
                district: "Cercado de Lima",
                latitude: -12.05296,
                longitude: -77.058611
            },
            {
                id: "324",
                FolderPath: "Tiendas Mass",
                name: "Dulanto 6 PL MS",
                address: "AV. MANUEL CIPRIANO DULANTO 601. PUEBLO LIBRE.",
                reference: "AL COSTADO DE LA CEVICHERIA SR. PESCA'O/CRUCE DE LA AV. MANUEL CIPRIANO DULANTO CON CALLE AGUSTÍN GAMARRA",
                district: "Pueblo Libre",
                latitude: -12.0798,
                longitude: -77.061401
            },
            {
                id: "325",
                FolderPath: "Tiendas Mass",
                name: "Ayacucho A3 LP MS",
                address: "CI. AYACUCHO MZ. A. LT. 3. URB. SARITA COLONIA. LA PERLA. CALLAO.",
                reference: "FRENTE AL MERCADO PLAZA TROPICAL/CRUCE DE CALLE AYACUCHO CON JR. 2 DE MAYO",
                district: "La Perla",
                latitude: -12.070942,
                longitude: -77.114069
            },
            {
                id: "326",
                FolderPath: "Tiendas Mass",
                name: "SolDeOro 75 LO MS",
                address: "JR. SOL DE ORO 7508. LOS OLIVOS",
                reference: "A 50 M DE LA UNIVERSIDAD CATOLICA SEDES SAPIENTIAE/CRUCE DE JR. SOL DE ORO CON PASAJE LOS PLANETAS",
                district: "Los Olivos",
                latitude: -11.999135,
                longitude: -77.063762
            },
            {
                id: "327",
                FolderPath: "Tiendas Mass",
                name: "Hermes 2 AT MS",
                address: "AV. HERMES MZ. D1 LOTE 09. URB. OLIMPO. 3ERA ETAPA. ATE.",
                reference: "AL COSTADO DEL CHIFA FA GUO YIN/CRUCE CON LA CALLE ZEUS",
                district: "Ate",
                latitude: -12.070442,
                longitude: -76.975042
            },
            {
                id: "328",
                FolderPath: "Tiendas Mass",
                name: "Belaunde 11 CO MS",
                address: "AV. VICTOR ANDRES BELAUNDE OESTE 1182. URB. EL RETABLO III ETAPA. COMAS",
                reference: "A 50 M DE LOS NUEVOS PROYECTOS DE GRAÑA Y MONTERO COLLIQUE /CRUCE DE LA AV. VICTOR ANDRES BELAUNDE OESTE CON AV. RETABLO                       ",
                district: "Comas",
                latitude: -11.933104,
                longitude: -77.059457
            },
            {
                id: "329",
                FolderPath: "Tiendas Mass",
                name: "Iquique CA MS",
                address: "CAA. 01. MZ. J. LT. 20. URB. SAN FERNANDO I ETAPA CALLAO",
                reference: "A LA ALTURA DE MINKA Y A LA ESPALDA DEL CONDOMINIO IQUIQUE",
                district: "Callao",
                latitude: -12.051506,
                longitude: -77.110518
            },
            {
                id: "330",
                FolderPath: "Tiendas Mass",
                name: "Galvez 19 LI MS",
                address: "AV. GALVEZ, JOSE N- 1985    ",
                reference: "A 2 CUADRAS DE LA MUNICIPALIDAD DE LINCE    ",
                district: "Lince",
                latitude: -12.08343819,
                longitude: -77.0290698
            },
            {
                id: "331",
                FolderPath: "Tiendas Mass",
                name: "Ballesta 2 CLL MS",
                address: "AV. ALAMEDA BALLESTAS N° 0292. URB. LOS CEDROS DE VILLA. CHORRILLOS",
                reference: "FRENTE A COLEGIO DIVINO MAESTRO",
                district: "Chorrillos",
                latitude: -12.207686,
                longitude: -77.015557
            },
            {
                id: "332",
                FolderPath: "Tiendas Mass",
                name: "Cornejo 20 CE MS",
                address: "AV. MARIANO H. CORNEJO 2042. MZ. L. LT. 05. URB. SANTA EMMA. LIMA",
                reference: "A MEDIA CUADRA DEL CRUCE DE AV CORNEJO Y AV. SANTA GERTRUDIS",
                district: "Cercado de Lima",
                latitude: -12.0677,
                longitude: -77.074213
            },
            {
                id: "333",
                FolderPath: "Tiendas Mass",
                name: "Larra 1 JM MS",
                address: "JR. LARRABURE Y UNANUE 392. JESUS MARIA.",
                reference: "FRENTE A COLEGIO \"JEAN PIAGET\"/CRUCE DE AV. MÁXIMO ABRIL Y JIRÓN LARRABURE",
                district: "Jesús María",
                latitude: -12.069927,
                longitude: -77.039283
            },
            {
                id: "334",
                FolderPath: "Tiendas Mass",
                name: "Moncloa 26 CE MS",
                address: "JR. TNT. ARISTIDES DEL CARPIO MUÑOZ 1687, MZ. L. LT. 13 URB. LOS CIPRESES. CERCADO DE LIMA.",
                reference: "A 30 M DEL MERCADO SEÑOR CAUTIVO",
                district: "Cercado de Lima",
                latitude: -12.057611,
                longitude: -77.07791
            },
            {
                id: "335",
                FolderPath: "Tiendas Mass",
                name: "Collique 2 CO MS",
                address: "AV. MICAELA BASTIDAS N° 1261. TIENDA 1. CONJUNTO RESIDENCIAL CIUDAD SOL DE COLLIQUE. COMAS",
                reference: "EX AEROPUERTO COLLIQUE",
                district: "Comas",
                latitude: -11.927258,
                longitude: -77.059635
            },
            {
                id: "336",
                FolderPath: "Tiendas Mass",
                name: "JoseM 22 AN MS",
                address: "ZONA 2 OTROS CA ANCASH MZ M  LT 22 - ANCON",
                reference: "A 3 PREDIOS DE TAMBO-CRUCE DE AV. ANCASH CON AV MARIÁTEGUI",
                district: "Ancón",
                latitude: -11.77722484,
                longitude: -77.16413388
            },
            {
                id: "337",
                FolderPath: "Tiendas Mass",
                name: "Balaguer CHS MS",
                address: "AV. BERNARDO BALAGUER MZ. B. LOTE. 18. LURIGANCHO CHOSICA.",
                reference: "A 400 M DE LA UNIVERSIDAD PERUANA UNIÓN/CRUCE DE JR. BERNARDO BALAGUER CON JR. LOS OLIVOS",
                district: "Lurigancho-Chosica",
                latitude: -11.986421,
                longitude: -76.834987
            },
            {
                id: "338",
                FolderPath: "Tiendas Mass",
                name: "Terreros 2 CE MS",
                address: "JR. FRAY BRUNO TERREROS 261. MZ. 30. LT. 09. CERCADO DE LIMA",
                reference: "FRENTE AL MERCADO COOPERATIVA DE SERVICIOS ESPECIALES - MIRONES BAJOS /CRUCE DE JR. BRUNO TERREROS CON JR. MARIANO ANGULO",
                district: "Cercado de Lima",
                latitude: -12.037125,
                longitude: -77.072218
            },
            {
                id: "339",
                FolderPath: "Tiendas Mass",
                name: "Ayacucho 3 SM MS",
                address: "JR. PROLONGACIÓN AYACUCHO 300. URB. SAN MIGUELITO. SAN MIGUEL",
                reference: "A 2 CUADRAS DEL PARQUE ISABEL DE MENDAÑA/A 2 CUADRAS DEL PARQUE ISABEL DE MENDAÑA",
                district: "San Miguel",
                latitude: -12.085142,
                longitude: -77.075308
            },
            {
                id: "340",
                FolderPath: "Tiendas Mass",
                name: "Lorenzo 5 CLL MS",
                address: "AVENIDA B LOTE 1 MZ E-4 URB LOS CEDROS DE VILLA AV. SAN LORENZO - CHORRILLOS    ",
                reference: "A 2 CUADRAS DEL COLEGIO TRILCE DE VILLA-ESQUINA DE AV. SAN LORENZO CON CALLE SANTA LUCIA",
                district: "Chorrillos",
                latitude: -12.202428,
                longitude: -77.008758
            },
            {
                id: "341",
                FolderPath: "Tiendas Mass",
                name: "Faucett 2 SM MS",
                address: "AV. ELMER FAUCETT N° 253, INT. 1, URBANIZACIÓN MARANGA, 4° ETAPA. SAN MIGUEL.",
                reference: "EN EL PRIMER PISO DE LA ACADEMIA PITAGORAS. AL FRENTE DEL BANCO INTERBANK/CURCE DE LA AV. ELMER FAUCETT CON CALLE FRANCISCO DE ORELLANA..",
                district: "San Miguel",
                latitude: -12.070916,
                longitude: -77.09873
            },
            {
                id: "342",
                FolderPath: "Tiendas Mass",
                name: "Madre 1 SA MS",
                address: "JR. MADRE SELVA 184. MZ. O1. LT 36. COOPERATIVA SANTA ROSA DE QUIVES. SANTA ANITA.",
                reference: "A UNA CUADRA DE LA POSTA SANTA ROSA DE QUIVES SANTA ANITA-FRENTE AL CRUCE CON CALLE CACTUS        ",
                district: "Santa Anita",
                latitude: -12.053646,
                longitude: -76.980717
            },
            {
                id: "343",
                FolderPath: "Tiendas Mass",
                name: "Chorri 3 CLL MS",
                address: "AV. CHORRILLOS 0318. URB. COSTA SUR. CHORRILLOS.",
                reference: "FRENTE A LA CLÍNICA MAISON DE SANTÉ-CRUCE DE LA AVENIDA CHORRILLOS CON CALLE GASTANETA",
                district: "Chorrillos",
                latitude: -12.1580671,
                longitude: -77.0232651
            },
            {
                id: "344",
                FolderPath: "Tiendas Mass",
                name: "GuardiaD 5 SC MS",
                address: "JR. GUARDIA CIVIL SUR, MANZANA D, LT 5, URB. LAS VIÑAS DE SAN ANTONIO. SANTIAGO DE SURCO",
                reference: "A 2 CUADRAS DE SURCO SALUD DE VILLA ALEGRE/ESQUINA DE AV. GUARDIA CIVIL CON CALLE VIÑA CARLINI",
                district: "Santiago de Surco",
                latitude: -12.1673058,
                longitude: -76.9909301
            },
            {
                id: "345",
                FolderPath: "Tiendas Mass",
                name: "Ejercito 2 MI MS",
                address: "AV. DEL EJERCITO N° 231. MIRAFLORES",
                reference: "FRENTE AL IBK DE LA CUADRA 2 DE LA AVENIDA DEL EJÉRCITO-CRUCE DE LA AV. DEL EJERCITO CON CALLE TOVAR    ",
                district: "Miraflores",
                latitude: -12.1172956,
                longitude: -77.0446371
            },
            {
                id: "346",
                FolderPath: "Tiendas Mass",
                name: "Proceres 2 SMP MS",
                address: "AV. LOS PROCERES N° 266, MZ E8, LT 25, URB. CONDEVILLA SEÑOR.SAN MARTIN DE PORRES.",
                reference: "CERCA A LA COMISARIA DE CONDEVILLA/A 350 M DEL CRUCE DE LA AV. JOSE RANDA Y PROCERES.",
                district: "San Martín de Porres",
                latitude: -12.018097,
                longitude: -77.086383
            },
            {
                id: "347",
                FolderPath: "Tiendas Mass",
                name: "Borgoño 1 MI MS",
                address: "CALLE GENERAL BORGOÑO N° 127. MIRAFLORES. LIMA",
                reference: "FRENTE AL THUNDERBIRD HOTELS J. PARDO/A MEDIA CUADRA DEL CRUCE CON AV. PARDO",
                district: "Miraflores",
                latitude: -12.1187025,
                longitude: -77.033544
            },
            {
                id: "348",
                FolderPath: "Tiendas Mass",
                name: "Aguero 14 EA MS",
                address: "AV. JOSE DE LA RIVAGUERO 1480. URB. POPULAR EL AGUSTINO. EL AGUSTINO",
                reference: "AL COSTADO DEL GIMNASIO PLATINIUM LIFE/A 50 METROS DEL CRUCE DE LA AV. JOSE DE LA RIVA AGUERO  CON RENÁN ELIAS OLIVERA",
                district: "El Agustino",
                latitude: -12.047975,
                longitude: -77.000298
            },
            {
                id: "349",
                FolderPath: "Tiendas Mass",
                name: "Amaru 73 CO MS",
                address: "AV. TUPAC AMARU N° 7372 MZ 2 LT 05 URB. VILLA COLLIQUE",
                reference: "A MEDIA CUADRA DEL PARQUE PABLO IV CRUZANDO LA TUPAC AMARU/CRUCE DE LA AV TUPAC AMARU CON JIRÓN VENUS",
                district: "Comas",
                latitude: -11.922838,
                longitude: -77.042016
            },
            {
                id: "350",
                FolderPath: "Tiendas Mass",
                name: "Tupac 1 CO MS",
                address: "AV. TUPAC AMARU N° 181. MZ. U-1. LT. 2. URB. CARABAYLLO.",
                reference: "FRENTE AL HOSPITAL DE LA SOLIDARIDAD DE COMAS/AV TUPAC AMARU CRUCE CON CALLE 24",
                district: "Comas",
                latitude: -11.971373,
                longitude: -77.057728
            },
            {
                id: "351",
                FolderPath: "Tiendas Mass",
                name: "Micaela L5 Ves MS",
                address: "SECTOR 02 GRUPO 17 MANZANA I LOTE 06",
                reference: "FRENTE AL MERCADO MODELO DE VILLA EL SALVADOR    ",
                district: "Villa El Salvador",
                latitude: -12.20808962,
                longitude: -76.94629741
            },
            {
                id: "352",
                FolderPath: "Tiendas Mass",
                name: "Castilla 14 SC MS",
                address: "AV. CASTILLA, RAMON MARISCAL N° 1408, LOCAL 101. MZ H, LT 16, URB. RESID. INGS. LA CASTELLANA. SANTIAGO DE SURCO.",
                reference: "FRENTE AL STARBUCKS DE LA AV. CASTILLA/CRUCE CON LA AV. LA CASTELLANA",
                district: "Santiago de Surco",
                latitude: -12.136829,
                longitude: -77.004894
            },
            {
                id: "353",
                FolderPath: "Tiendas Mass",
                name: "Ugarte B28 VE MS",
                address: "CALLE M. JUAREZ PEREA MZ. B. LT. 28. ZN. URB. SANTA ROSA. VENTANILLA.",
                reference: "FRENTE AL MERCADO SATÉLITE DE VENTANILLA/CRUCE CALLE JOSÉ FLORES CON CALLE UGARTECHE",
                district: "Ventanilla",
                latitude: -11.868132,
                longitude: -77.113235
            },
            {
                id: "354",
                FolderPath: "Tiendas Mass",
                name: "Malasquez MY MS",
                address: "AV. VICTOR MALASQUEZ MZ. C. LT. 03. SECTOR CENTRAL-HUERTOS DE MANCHAY. PACHACAMAC",
                reference: "AL COSTADO DEL GRIFO  REPSOL DE LA AV. MALASQUEZ/A MEDIA CUADRA DEL CRUCE CON AV. GRAU",
                district: "Pachacamac",
                latitude: -12.113233,
                longitude: -76.873506
            },
            {
                id: "355",
                FolderPath: "Tiendas Mass",
                name: "Lomas RI MS",
                address: "AV. ABELARDO GAMARRA S/N RIMAC",
                reference: "DENTRO DEL CONDOMÍNIO LAS PRADERAS DE RIMAC/A UNA CUADRA DEL CRUCE DE AV. MORRO SOLAR CON AV. ALCAZAR",
                district: "Rimac",
                latitude: -12.016952,
                longitude: -77.0417028
            },
            {
                id: "356",
                FolderPath: "Tiendas Mass",
                name: "Naran 17 LO MS",
                address: "AV. NARANJAL  MZ. P3. LT 66. URB. LOS NARANJOS. I ETAPA. LOS OLIVOS ",
                reference: "A 30 METROS DEL ÓVALO CANTA CALLAO/A 20 METROS DEL GRIFO PETROPERU",
                district: "Los Olivos",
                latitude: -11.974292,
                longitude: -77.08708
            },
            {
                id: "357",
                FolderPath: "Tiendas Mass",
                name: "Amancaes 9 RI MS",
                address: "AV. AMANCAES 975. URB. EL BOSQUE. RIMAC",
                reference: "A UNA CUADRA DEL PARQUE VIRGEN DE AMANCAES/A MEDIA CUADRA DEL CRUCE DE LA AV AMANCAES CON AV EL SOL",
                district: "Rimac",
                latitude: -12.020355,
                longitude: -77.029042
            },
            {
                id: "358",
                FolderPath: "Tiendas Mass",
                name: "Marina 28 SM MS",
                address: "AV. DE LA MARINA 2841 LT. 6 URB. CAPEBCO SAN MIGUEL",
                reference: "AL COSTADO DEL BCP/AV. ESCARDO CON AV. LA MARINA",
                district: "San Miguel",
                latitude: -12.07746821,
                longitude: -77.09394327
            },
            {
                id: "359",
                FolderPath: "Tiendas Mass",
                name: "MBocanegra CA MS",
                address: "AV. BOCANEGRA MZ Ñ LT 25 URB. RES SANTA ROSA, CALLAO",
                reference: "A UNA CUADRA DEL MERCADO VIRGEN DEL CARMEN/CRUCE DE AV. BOCANEGRA Y AV. DOMINICOS",
                district: "Callao",
                latitude: -12.007261,
                longitude: -77.100994
            },
            {
                id: "360",
                FolderPath: "Tiendas Mass",
                name: "Canevaro 4 SJM MS",
                address: "AV. CESAR CANEVARO N° 460. MZ. G. LT. 15. ZONA C-SJM",
                reference: "CENTRO DE ATENCIÓN PRIMARIA III SAN JUAN DE MIRAFLORES - ESSALUD/CRUCE DE AV. CANEVARO Y AV, VARGAS MACHUCA",
                district: "San Juan de Miraflores",
                latitude: -12.164051,
                longitude: -76.96905
            },
            {
                id: "361",
                FolderPath: "Tiendas Mass",
                name: "Dominic 25 SMP MS",
                address: "AV. LOS DOMINICOS MZ. C. LOTE 25. ASOC. VIV. RESIDENCIAL VENECIA. SAN MARTIN DE PORRES.",
                reference: "AL COSTADO DEL MERCADO VENECIA, FRENTE A LA POLLERÍA LA LEÑA/A 500 METROS DEL CRUCE DE LA AV. CANTA CALLAO CON AV. DOMINICOS. ",
                district: "San Martín de Porres",
                latitude: -11.9949561,
                longitude: -77.1051615
            },
            {
                id: "362",
                FolderPath: "Tiendas Mass",
                name: "Tacna 4 CE MS",
                address: "AV. TACNA 435-441. LT 8. CERCADO DE LIMA.",
                reference: "FRENTE A LA IGLESIA DE LAS NAZARENAS/A MEDIA CUADRA DEL CRUCE CON JR. HUANCAVELICA",
                district: "Cercado de Lima",
                latitude: -12.04591,
                longitude: -77.037063
            },
            {
                id: "363",
                FolderPath: "Tiendas Mass",
                name: "Indust 30 LM MS",
                address: "AV. SEPARADORA INDUSTRIAL U11 U12 MZ. E LT. 64 URB. COVIMA LA MOLINA",
                reference: "A UNA CUADRA DE IGLESIA REINO DE LOS TESTIGOS DE JEHOVA/ESQUINA CON LA CALLE CUETO FERNANDINI",
                district: "La Molina",
                latitude: -12.058297,
                longitude: -76.9465631
            },
            {
                id: "364",
                FolderPath: "Tiendas Mass",
                name: "Montec B6 VE MS",
                address: "MZ. B. LOTE 6 Y 7. ZN. COOP. MONTECARLO. CONO SUR. VENTANILLA.",
                reference: "PARADERO EL OCHO, A MEDIA CUADRA DEL MERCADO CORAZON DE JESÚS/AV. NESTOR GAMBETA, AL FRENTE DE INICIO DE LA AV. PERUANIDAD",
                district: "Ventanilla",
                latitude: -11.8984,
                longitude: -77.126969
            },
            {
                id: "365",
                FolderPath: "Tiendas Mass",
                name: "Mariate 2 CB MS",
                address: "AV. JOSE CARLOS MARIATEGUI, MZ. V,  LOTE 18. URB. LUCYANA. CARABAYLLO.",
                reference: "A MEDIA CUADRA DEL COLEGIO BELÉN/FRENTE AL CRUCE CON CALLE AGUSTO SALAVERRY",
                district: "Carabayllo",
                latitude: -11.894389,
                longitude: -77.032285
            },
            {
                id: "366",
                FolderPath: "Tiendas Mass",
                name: "Union 2 AT MS",
                address: "JR. DE LA UNION N° 250. MZ. E1.LOTE 15. ZONA 2. ATE (ZONA URB. COOP. 27 DE ABRIL)",
                reference: "A MEDIA CUADRA DEL MERCADO 27 DE ABRIL/",
                district: "Ate",
                latitude: -12.05494,
                longitude: -76.959098
            },
            {
                id: "367",
                FolderPath: "Tiendas Mass",
                name: "Ejercito 9 MA MS",
                address: "DEL EJERCITO (EX AUGUSTO PEREZ ARANIBAR) N° 915. MAGDALENA DEL MAR.",
                reference: "AL COSTADO DEL GRIFO PRIMAX/CRUCE CON LA BAJADA MARBELLA",
                district: "Magdalena del Mar",
                latitude: -12.100318,
                longitude: -77.062339
            },
            {
                id: "368",
                FolderPath: "Tiendas Mass",
                name: "IzaguiA 3 SMP MS",
                address: "MZ. A. LOTE 3. ASOC. VIV. SAN REMO III. SAN MARTIN DE PORRES.",
                reference: "FRENTE AL MERCADO SARITA COLONIA/CRUCE DE LA AV IZAGUIRRE Y AV. BERTELLO",
                district: "San Martín de Porres",
                latitude: -11.988386,
                longitude: -77.113044
            },
            {
                id: "369",
                FolderPath: "Tiendas Mass",
                name: "Bolivar 5 SE MS",
                address: "AV. SIMON BOLIVAR N° SANTA EULALIA",
                reference: "A MEDIA CUADRA DEL PRIMAX ARZAPALO    ",
                district: "Santa Eulalia",
                latitude: -11.921017,
                longitude: -76.668834
            },
            {
                id: "370",
                FolderPath: "Tiendas Mass",
                name: "Agustin 11 SU MS",
                address: "JR. SAN AGUSTIN 1198. SURQUILLO",
                reference: "A 1 CUADRA DEL MERCADO SAN FELIPE DE SURQUILLO/CRUCE CON JIRÓN SAN AGUSTÍN",
                district: "Surquillo",
                latitude: -12.115394,
                longitude: -77.016207
            },
            {
                id: "371",
                FolderPath: "Tiendas Mass",
                name: "CaudeR 14 CB MS",
                address: "AV. CAUDIVILLA MZ. R1. LOTE. 4. URB. RES. LUCYANA. CARABAYLLO.",
                reference: "A MEDIA CUADRA DEL MERCADO LA FRONTERA/CRUCE DE LA AVENIDA MARIANO CONDORCANQUI CON LA AV. SEÑOR DE CAUDIVILLA",
                district: "Carabayllo",
                latitude: -11.8876258,
                longitude: -77.036475
            },
            {
                id: "372",
                FolderPath: "Tiendas Mass",
                name: "Proceg 3 LO MS",
                address: "AV. HUANDOY MZ G3 LT 7 URB EL PARQUE DEL NARANJAL II ETAPA.",
                reference: "AL FRENTE DEL RESTAURANT LA LORETANA-ENTRE CL COLCAS Y PISCOBAMBA",
                district: "Los Olivos",
                latitude: -11.97521545,
                longitude: -77.08277802
            },
            {
                id: "373",
                FolderPath: "Tiendas Mass",
                name: "Tupac 64 CO MS",
                address: "AV. TUPAC AMARU N° 6488-6496. MZ. G. LT 23 Y LT 24. URB. LA PASCANA. COMAS.",
                reference: "AL COSTADO DEL INSTITUTO MONTALVO /A 140 METROS DEL CRUCE DE LA AV TUPAC AMARU CON AV. MICAELA BASTIDAS",
                district: "Comas",
                latitude: -11.934952,
                longitude: -77.047264
            },
            {
                id: "374",
                FolderPath: "Tiendas Mass",
                name: "RosalZ 1 VES MS",
                address: "CH PACHAC IV ETAPA PARC. 3A MANZANA Z1 LOTE 02 INT. 1",
                reference: "A 5 CUADRAS DEL DEPÓSITO DE TRENES DE VES-CRUCE DE AV. MARIATEGUI Y AV. SEPARADORA INDUSTRIAL",
                district: "Villa El Salvador",
                latitude: -12.2175896,
                longitude: -76.9268817
            },
            {
                id: "375",
                FolderPath: "Tiendas Mass",
                name: "Bernal 3 SJM MS",
                address: "COOP. VIV. HEROES DE SAN JUAN, MZ. A., LT 15. URB. SAN JUAN. SAN JUAN DE MIRAFLORES",
                reference: "FRENTE AL COLEGIO FERMIN TANGUIS/ESQUINA DE BENALES CON CALLE MANUEL CASOS",
                district: "San Juan de Miraflores",
                latitude: -12.154989,
                longitude: -76.977261
            },
            {
                id: "376",
                FolderPath: "Tiendas Mass",
                name: "Pomabam 2 BR MS",
                address: "JR. POMABAMBA N° 201. BREÑA.",
                reference: "A ESPALDAS DEL METRO DE BREÑA/ALTURA CUADRA 9 AV. ALFONSO UGARTE",
                district: "Breña",
                latitude: -12.051914,
                longitude: -77.043516
            },
            {
                id: "377",
                FolderPath: "Tiendas Mass",
                name: "Incas 4 CO MS",
                address: "AV. LOS INCAS N° 482. MZ. G. LT. 25. URB. LA ALBORADA II ETAPA. COMAS",
                reference: "AL COSTADO DE LA POLLERÍA EL REFUGIO/FRENTE AL CRUCE CON LA AV. EL RETABLO",
                district: "Comas",
                latitude: -11.914066,
                longitude: -77.049544
            },
            {
                id: "378",
                FolderPath: "Tiendas Mass",
                name: "Velarde 1 SC MS",
                address: "JR. VELARDE, MAXIMILIANO. CAP. MZ. A. LT.21-22.URB. RESIDENCIAL LOS PORTALES. SANTIAGO DE SURCO.",
                reference: "AL COSTADO DE IGLESIA CRISTIANA PENTECOSTES DEL PERU MOVIMIENTO MISIONERO MUNDIAL/ESQUINA CON JR. EUDOCIO RABINES",
                district: "Santiago de Surco",
                latitude: -12.160337,
                longitude: -76.992404
            },
            {
                id: "379",
                FolderPath: "Tiendas Mass",
                name: "Porvenir 3 PP MS",
                address: "MZ. F. LT. 03. ASOC. DE VIV. CASA HUERTA LAS CASUARINAS. PUENTE PIEDRA",
                reference: "A MEDIA CUADRA GRIFO EL NORTEÑO/A MEDIA CUADRA DEL CRUCE CON LA AV SAN JUAN",
                district: "Puente Piedra",
                latitude: -11.856158,
                longitude: -77.0848368
            },
            {
                id: "380",
                FolderPath: "Tiendas Mass",
                name: "Cerro 1 SC MS",
                address: "AV. CERRO CHICO N° 147. MZ.J. LT. 17. URB. SAN IGNACIO DE MONTERRICO-LOYOLA. SANTIAGO DE SURCO.",
                reference: "A MEDIA CUADRA PUENTE PEATONAL RICARDO PALMA/A MEDIA CUADRA DEL CRUCE CON AV. CIRCUNVALACIÓN",
                district: "Santiago de Surco",
                latitude: -12.135365,
                longitude: -76.979174
            },
            {
                id: "381",
                FolderPath: "Tiendas Mass",
                name: "Ungers 65 CO MS",
                address: "AV. METROPOLITANA (AV. GERARDO UNGER) N° 6550. MZ. L-1. LT 039. URB. SANTA LUZMILA. COMAS",
                reference: "A MEDIA CUADRA DE LA COMISARÍA DE STA LUZMILA/A MEDIA CUADRA DEL CRUCE CON LA AV. GUILLERMO LA FUENTE",
                district: "Comas",
                latitude: -11.9438858,
                longitude: -77.0666595
            },
            {
                id: "382",
                FolderPath: "Tiendas Mass",
                name: "WiesseE16SJL MS",
                address: "SV M.Z E-1 LT. 16-17 1ER PISO Y MZ PROGRAMA CIUDAD MARISCAL CACERES SECTOR S",
                reference: "AL COSTADO DE TIENDAS EFE    ",
                district: "San Juan de Lurigancho",
                latitude: -11.95116568,
                longitude: -76.98707634
            },
            {
                id: "383",
                FolderPath: "Tiendas Mass",
                name: "Saenz 3 CA MS",
                address: "AV. SAENZ PEÑA N° 342. CDO DEL CALLAO. CALLAO",
                reference: "FRENTE AL BBVA DE SAENZ PEÑA/A MEDIA CUADRA DEL CRUCE CON AV. MARCO POLO",
                district: "Callao",
                latitude: -12.061149,
                longitude: -77.143727
            },
            {
                id: "384",
                FolderPath: "Tiendas Mass",
                name: "Cahuide 9 LP MS",
                address: "JR. CAHUIDE N° 950-970. LA PERLA. CALLAO",
                reference: "FRENTE AL MERCADO N°1 DE LA PERLA/A MEDIA CUADRA DEL CRUCE CON AV. LIMA",
                district: "La Perla",
                latitude: -12.0691818,
                longitude: -77.1249255
            },
            {
                id: "385",
                FolderPath: "Tiendas Mass",
                name: "Lima 9 VMT MS",
                address: "AV. LIMA 960 MZ. 56 LT. 7 PUEBLO VILLA POETA JOSE GALVEZ",
                reference: "A UNA CUADRA DEL MERCADO ATECA VMT/ESQUINA DE CALLE TALARA CON AV. LIMA",
                district: "Villa María del Triunfo",
                latitude: -12.21223062,
                longitude: -76.90641197
            },
            {
                id: "386",
                FolderPath: "Tiendas Mass",
                name: "Caminos 25 SC MS",
                address: "AV. CAMINOS DEL INCA. MZ. M. LT. 27. URB. MONTERRICO SUR. SANTIAGO DE SURCO",
                reference: "A MEDIA CUADRA DEL TAMBO DE CAMINOS DEL INCA/A MEDIA CUADRA DE AV. MONTERRICO SUR",
                district: "Santiago de Surco",
                latitude: -12.137046,
                longitude: -76.98395
            },
            {
                id: "387",
                FolderPath: "Tiendas Mass",
                name: "Antares J1 SC MS",
                address: "AV. ANTARES MZ. J1. LT 31. URB. SAN ROQUE CIVIL. SANTIAGO DE SURCO",
                reference: "A 3 CUADRAS DEL WONG DE TOMAS MARSANO/FRENTE AL CRUCE CON LA CALLE GENERAL ARMANDO ZAMUDIO",
                district: "Santiago de Surco",
                latitude: -12.145446,
                longitude: -76.992464
            },
            {
                id: "388",
                FolderPath: "Tiendas Mass",
                name: "Condor 6 LM MS",
                address: "AV. DE LOS CONDORES N° 610 MZ A LT 10, URB. VALLE DE LA MOLINA (ASPOVILM).",
                reference: "A 1 CUADRA DE POLLERÍA LAS TINAJAS DEL ÓVALO LOS CÓNDORES/A MEDIA CUADRA DEL ÓVALO LOS CÓNDORES",
                district: "La Molina",
                latitude: -12.101332,
                longitude: -76.944505
            },
            {
                id: "389",
                FolderPath: "Tiendas Mass",
                name: "Manue L6 SC MS",
                address: "JR. FUENTE CHAVEZ, MANUEL DE LA N° 600. MZ. C. LT. 1A. URB. TEJADITA. SANTIAGO DE SURCO",
                reference: "FRENTE A LA INSTITUCION EDUCATIVA JOSÉ MARÍA EGUREN/CRUCE AV MANUEL DE LA FUENTE CHAVEZ CON MCAL STA CRUZ",
                district: "Santiago de Surco",
                latitude: -12.15198,
                longitude: -77.017939
            },
            {
                id: "390",
                FolderPath: "Tiendas Mass",
                name: "Lomas 12 SJL MS",
                address: "AV. LAS LOMAS N° 1289-1291. LT. 02. URB. MANGOMARCA.",
                reference: "A UNA CUADRA DE LA INSTITUCIÓN EDUCATIVA PNP MARTÍN ESQUICHA BERNEDO/ALTURA DEL CRUCE DE AV. LAS LOMAS CON PURUCHUCO",
                district: "San Juan de Lurigancho",
                latitude: -12.012254,
                longitude: -76.984718
            },
            {
                id: "391",
                FolderPath: "Tiendas Mass",
                name: "Saenz 8 CA MS",
                address: "AV. SAENZ PEÑA N° 807. CDO. DEL CALLAO.CALLAO",
                reference: "A UNA CUADRA DEL MERCADO CENTRAL DEL CALLAO/A MEDIA CUADRA DEL CRUCE CON VILLAR",
                district: "Callao",
                latitude: -12.059927,
                longitude: -77.136819
            },
            {
                id: "392",
                FolderPath: "Tiendas Mass",
                name: "Canta E36 SMP MS",
                address: "AV. CANTA CALLAO S/N, MANZANA E, LOTE 36, URBANIZACIÓN MONTE DE LOS OLIVOS, ETAPA I. SAN MARTIN DE PORRES.",
                reference: "FRENTE AL GRIFO PETRO PERÚ \"EL PADRINO\"/A MEDIA CUADRA DEL CRUCE CON AV. LOS OLIVOS",
                district: "San Martín de Porres",
                latitude: -11.983529,
                longitude: -77.09966
            },
            {
                id: "393",
                FolderPath: "Tiendas Mass",
                name: "Trapi A2 CO MS",
                address: "AV. HEROES DEL ALTO CENEPA N° 410, MANZANA A2, LT 02. COOP. VIV. PNP. VIPOL. PROGRAMA EL ALAMO. COMAS",
                reference: "A 20 METROS AL HOTEL MARBELLA/A MEDIA CUADRA DEL CRUCE CON AV. MICAELA BASTIDAS",
                district: "Comas",
                latitude: -11.926425,
                longitude: -77.064681
            },
            {
                id: "394",
                FolderPath: "Tiendas Mass",
                name: "Garzon 11 JM MS",
                address: "AV. GENERAL GARZON 1120",
                reference: "A UNA CUADRA DE LA COMISARÍA DE JESÚS MARÍA-A 20 METROS DEL CRUCE CON AV. GRAL SANTA CRUZ",
                district: "Jesús María",
                latitude: -12.07123,
                longitude: -77.048969
            },
            {
                id: "395",
                FolderPath: "Tiendas Mass",
                name: "Aviacion 30 SB MS",
                address: "AV. AVIACION 3016. MZ. I. 10. LT. 02. SAN BORJA SUR. I ETAPA. 1ERA SECCION. SAN BORJA",
                reference: "A MEDIA CUADRA DEL PARADERO TREN SAN BORJA SUR/A MEDIA CUADRA DEL CRUCE CON AV. SAN BORJA SUR",
                district: "San Borja",
                latitude: -12.099874,
                longitude: -77.002066
            },
            {
                id: "396",
                FolderPath: "Tiendas Mass",
                name: "MexicoCO 6 CO MS",
                address: "AV. MEXICO (AV.3) 697-A MZ A LT 45-44 URB. SANTA ISOLINA I ETAPA",
                reference: "FRENTE AL RESTAURANT ESTRELLA DE MAR/CRUCE DE AV. METROPOLITANA Y AV MEXICO",
                district: "Comas",
                latitude: -11.955696,
                longitude: -77.063568
            },
            {
                id: "397",
                FolderPath: "Tiendas Mass",
                name: "Triu C21 SJM MS",
                address: "COOP. DE VIV. AMERICA. MZ. C. LT. 21. 21-A. SAN JUAN DE MIRAFLORES.",
                reference: "FRENTE A TAMBO/ALTURA DEL CRUCE DE AV.EL TRIUNFO CON CALLE D",
                district: "San Juan de Miraflores",
                latitude: -12.183939,
                longitude: -76.975435
            },
            {
                id: "398",
                FolderPath: "Tiendas Mass",
                name: "Aviacion 45 SC MS",
                address: "AV. AVIACION N° 4524 URB. RESIDENCIAL HIGUERETA",
                reference: "A UNA CUADRA DEL MERADO MANUEL GONZALES PRADA/AV. AVIACIÓN CUADRA 45 ESQUINA CON UN PARQUE",
                district: "Santiago de Surco",
                latitude: -12.12103923,
                longitude: -76.99859042
            },
            {
                id: "399",
                FolderPath: "Tiendas Mass",
                name: "SanLuis 22 SB MS",
                address: "AV. SAN LUIS N° 2267. MZNA B15, LT 20. SAN BORJA SUR III ETAPA. SECTOR A. SAN BORJA.",
                reference: "A MEDIA CUADRA DEL PARDOS DE AV. SAN LUIS/A MEDIA CUADRA DEL CRUCE CON CALLE LOPEZ DE AYALA",
                district: "San Borja",
                latitude: -12.09493,
                longitude: -76.995298
            },
            {
                id: "400",
                FolderPath: "Tiendas Mass",
                name: "Panama 65 SC MS",
                address: "AV. REPUBLICA DE PANAMA 6537. URB. SANTA RITA. SANTIAGO DE SURCO",
                reference: "A MEDIA CUADRA DEL CRUCE CON AV. 28 DE JULIO/A MEDIA CUADRA DEL CRUCE CON AV. 28 DE JULIO",
                district: "Santiago de Surco",
                latitude: -12.131138,
                longitude: -77.017837
            },
            {
                id: "401",
                FolderPath: "Tiendas Mass",
                name: "Pacas 46 CA MS",
                address: "CA. 01. 4676. MZ. E. LT. 42. URB. EL OLIVAR. CALLAO.",
                reference: "A 3 CUADRAS DEL HOSPITAL NEGREIROS/CRUCE CON AV. EL OLIVAR",
                district: "Callao",
                latitude: -12.020888,
                longitude: -77.09492
            },
            {
                id: "402",
                FolderPath: "Tiendas Mass",
                name: "Chancas 11 SA MS",
                address: "AV. LOS CHANCAS MZ. D. LT. 15. ASOCIACION RESIDENCIAL LOS PINOS. SANTA ANITA.",
                reference: "A MEDIA CUADRA DEL GRIFO PECSA DEL CRUCE DE AV. HUAROCHIRI Y AV. CHANCAS/A UNA CUADRA DEL CRUCE CON AV. HUAROCHIRI",
                district: "Santa Anita",
                latitude: -12.0354,
                longitude: -76.95714
            },
            {
                id: "403",
                FolderPath: "Tiendas Mass",
                name: "PastorF 2 VES MS",
                address: "AGRUP. PACHACAMAC. SECTOR 2. B3. PARC. D. MANZANA. F. LOTE 2-3. VILLA EL SALVADOR",
                reference: "A MEDIA CUADRA DEL  COLEGIO JUAN PABLO VIZCARDO Y GUZMÁN VILLA EL SALVADOR/CRUCE DE AV PASTOR SEVILLA CON PERUANO CANADIENSE",
                district: "Villa El Salvador",
                latitude: -12.2411566,
                longitude: -76.9265186
            },
            {
                id: "404",
                FolderPath: "Tiendas Mass",
                name: "Alisos 4 SMP MS",
                address: "AV. ALISOS 410. MZ. M. LT. 18. ASOC. VIV. NARANJAL",
                reference: "CENTRO DE SALUD VIRGEN DEL PILAR DE NARANJAL/AV. LOS ALISOS CON CALLE LAS GUABAS",
                district: "San Martín de Porres",
                latitude: -11.98366,
                longitude: -77.063246
            },
            {
                id: "405",
                FolderPath: "Tiendas Mass",
                name: "JCM B32 VMT MS",
                address: "AV. GRAL CESAR PANDO EGUSQUIZA N° 270. MZ. B2. LT. 32. I. ETAPA. JCM. VMT",
                reference: "A 2 CDRAS DEL PARQUE JOSÉ CARLOS MARIATEGUI/AV. PANDO EGUSQUIZA CON CALLE SANTA CATALINA",
                district: "Villa María del Triunfo",
                latitude: -12.156147,
                longitude: -76.955701
            },
            {
                id: "406",
                FolderPath: "Tiendas Mass",
                name: "Canta A13 SMP MS",
                address: "AV. CANTA CALLAO PROG VIV LAS BEGONIAS. MZ. A. LT.  13 1ERA ETAPA.SAN MARTIN DE PORRES.",
                reference: "A MEDIA CDRA DEL HOTEL ARIZONA/CRUCE DE AV. CANTA CALLAO CON LA CALLE MARIANO USTERIZ",
                district: "San Martín de Porres",
                latitude: -11.978264,
                longitude: -77.093799
            },
            {
                id: "407",
                FolderPath: "Tiendas Mass",
                name: "America 13 LV MS",
                address: "AV. LAS AMERICAS 1351. LA VICTORIA",
                reference: "A MEDIA CDRA DEL MERCADO LA POLVORA/CRUCE DE LA AV PARINACOCHAS CON LAS AMÉRICAS",
                district: "La Victoria",
                latitude: -12.076475,
                longitude: -77.015308
            },
            {
                id: "408",
                FolderPath: "Tiendas Mass",
                name: "Meliton 3 AT MS",
                address: "JR. MELITON CARBAJAL 356. MZ. J. LT. 18. ATE",
                reference: "FRENTE A \"PARQUE GENERAL ATE\" /A MEDIA CUADRA DEL CRUCE DE AV LOS ROSALES CON MELITÓN CARBAJAL",
                district: "Ate",
                latitude: -12.061833,
                longitude: -76.992583
            },
            {
                id: "409",
                FolderPath: "Tiendas Mass",
                name: "Chinchay IN MS",
                address: "AV. CHINCHAYSUYO N° 142. MZ. 6. LT. 3. URB. TAHUANTINSUYO. INDEPENDENCIA",
                reference: "A 3 CUADRAS DEL EMBARQUE ALIMENTADOR CARABAYLLO (AV TUPAC AMARU)/AV CHINCHAYSUYO CRUCE CON ATAHUALPA",
                district: "Independencia",
                latitude: -11.979708,
                longitude: -77.057191
            },
            {
                id: "410",
                FolderPath: "Tiendas Mass",
                name: "Surco 7 SC MS",
                address: "AV. SURCO, MZ. E, LT. 15. URB. LA VIRREYNA. SANTIAGO DE SURCO",
                reference: "A 1 CUADRA DEL GRIFO PRIMAX DE AV. TOMAR MARSANO CON AV. SURCO-A MEDIA CUADRA DEL CRUCE CON AV. TOMÁS MARSANO",
                district: "Santiago de Surco",
                latitude: -12.140157,
                longitude: -76.994255
            },
            {
                id: "411",
                FolderPath: "Tiendas Mass",
                name: "Iglesia 8 SJM MS",
                address: "AV. MIGUEL IGLESIAS MZ. S. LT. 8. PJ. SAN ANTONIO DE PADUA. II ETAPA. SAN JUAN DE MIRAFLORES",
                reference: "A 2 CUADRAS DEL HOSPITAL MARÍA AUXILIADORA/FRENTE AL CRUCE CON AV. BILLINGHURST",
                district: "San Juan de Miraflores",
                latitude: -12.163054,
                longitude: -76.959996
            },
            {
                id: "412",
                FolderPath: "Tiendas Mass",
                name: "Bueno 23 PP MS",
                address: "AV. BUENOS AIRES N° 2339 MZ L LT 09 ASOCIACION DE POBLADORES MICAELA BASTIDAS DE ZAPALLAL LEONCIO PRADO.",
                reference: "PARADERO OVALO ZAPALLAL/ENTRE CL LAS DELICIAS Y CL GLORIA BAMBA",
                district: "Puente Piedra",
                latitude: -11.842932,
                longitude: -77.102151
            },
            {
                id: "413",
                FolderPath: "Tiendas Mass",
                name: "Trapi L2 CB MS",
                address: "AV. A (AV. SANTO DOMINGO) MZ. L2. LOTE 3 Y 4. URB. SANTO DOMINGO. 6 ETAPA.",
                reference: " MEDIA CUADRA DE LA E.T SALAMANCA Y UNA CUADRA DEL GRIFO PECSA/CRUCE DE LA AV. CAMINO REAL CON LA AV. TRAPICHE",
                district: "Carabayllo",
                latitude: -11.873664,
                longitude: -77.029639
            },
            {
                id: "414",
                FolderPath: "Tiendas Mass",
                name: "Pablo 1 BE MS",
                address: "AV. JUAN PABLO II N° 107-109.URB. STELLA MARIS. BELLAVISTA. CALLAO",
                reference: "A UNA CUADRA DEL MERCADO TRABAJADORES UNIDOS (CRUZANDO AV BENAVIDES)-AV JUAN PABLO II CRUCE CON JOSÉ PARODI",
                district: "Bellavista",
                latitude: -12.05774,
                longitude: -77.1144156
            },
            {
                id: "415",
                FolderPath: "Tiendas Mass",
                name: "HerreF 13 SJM MS",
                address: "A.H. IGNACIA RODULFO VDA. DE CANEVARO. MZ. F. LT. 13. SAN JUAN DE MIRAFLORES",
                reference: "FRENTE AL MERCADO 18 DE ENERO/CRUCE CALLE HERRERILLOS CON CALLE LAS AZUCENAS",
                district: "San Juan de Miraflores",
                latitude: -12.165678,
                longitude: -76.984155
            },
            {
                id: "416",
                FolderPath: "Tiendas Mass",
                name: "Silencio PH MS",
                address: "URBANIZACION EL CARMEN, SIN VÍA, MANZANA G1, LOTE 10. PUNTA HERMOSA",
                reference: "A 100 METROS DEL GRIFO PRIMAX DEL SILENCIO/KM 43 DE LA ANTIGUA PANAMERICANA SUR",
                district: "Punta Hermosa",
                latitude: -12.3167,
                longitude: -76.832383
            },
            {
                id: "417",
                FolderPath: "Tiendas Mass",
                name: "Morropon 1 CO MS",
                address: "JR. MORROPON N° 190. MZ. I. LT. 13. URB. SAN FELIPE I ETAPA. ",
                reference: "FRENTE AL COLEGIO 2049/CRUCE AV. SAN FELIPE CON JIRÓN MORROPÓN",
                district: "Comas",
                latitude: -11.906626,
                longitude: -77.035241
            },
            {
                id: "418",
                FolderPath: "Tiendas Mass",
                name: "Retablo 13 CO MS",
                address: "AV. EL RETABLO N° 1356. MZ. F1. LT. 11. COOP. VIV. PRIMAVERA. COMAS",
                reference: "EN LA MISMA CUADRA DE LA JEFATURA DE POLICÍA \"PNP DIRINCRI COMAS\"/A MEDIA CUADRA DEL CRUCE DE AV RETABLO CON CALLE 9",
                district: "Comas",
                latitude: -11.923699,
                longitude: -77.054222
            },
            {
                id: "419",
                FolderPath: "Tiendas Mass",
                name: "Granda 24 SMP MS",
                address: "AV. JOSE GRANDA S/N. MZ. C-11. LT. 40. URB. CONDEVILLA SEÑOR II ETAPA. SMP.",
                reference: "AL FRENTE DEL MERCADO CENTRO CIVICO/A 1 CDRA Y MEDIA DEL OVALO JOSE GRANDA",
                district: "San Martín de Porres",
                latitude: -12.027195,
                longitude: -77.072228
            },
            {
                id: "420",
                FolderPath: "Tiendas Mass",
                name: "Grau 5 PU MS",
                address: "CALLE COMANDANTE JUAN MOORE 305",
                reference: "A 4 CUADRAS DE LA MUNICIPALIDAD DE LA PUNTA-CRUCE DE AV. GRAU CON CALLE MOORE",
                district: "La Punta",
                latitude: -12.070585,
                longitude: -77.161027
            },
            {
                id: "421",
                FolderPath: "Tiendas Mass",
                name: "Mass Pedro 2 BR MS",
                address: "JR. PEDRO RUIZ GALLO N° 244. BREÑA",
                reference: "A UNA CUADRA Y MEDIA DE LA AV BRASIL/SAKI BIGIO LE OTORGA PODERES PARA CELEBRAR CONTRATOS A JACK BIGIO",
                district: "Breña",
                latitude: -12.068886,
                longitude: -77.051282
            },
            {
                id: "422",
                FolderPath: "Tiendas Mass",
                name: "Pleyad13 CLL MS",
                address: "JR. LAS PLEYADES (EX CALLE 18) MZ. S LT. 13 URB. PARCELACIÓN RÚSTICA LA CAMPIÑA",
                reference: "A UNA CUADRA DEL MERCADO SAN SEBASTIAN/A UAN CUADRA DEL CRUCE CON AV. FAISANES",
                district: "Chorrillos",
                latitude: -12.170721,
                longitude: -76.996762
            },
            {
                id: "423",
                FolderPath: "Tiendas Mass",
                name: "Villa 4 VMT MS",
                address: "AV. VILLA MARÍA S/N MZ. G-4 LT. 8 PRIMER HOGAR POLICIAL ACU",
                reference: "FRENTE AL POLICLINICO MUNICIPAL DE VMT/ENTRE AV. VILLA MARIA Y JR. UNIÓN",
                district: "Villa María del Triunfo",
                latitude: -12.1643834,
                longitude: -76.9478041
            },
            {
                id: "424",
                FolderPath: "Tiendas Mass",
                name: "Petit 30 SI MS",
                address: "AV. PETIT THOURS N° 3051-3055",
                reference: "A MEDIA CUADRA DEL INKAFARMA DE PETIT THOUARS CON JAVIER PRADO-A MEDIA CUADRA DEL CRUCE CON CALLE OLAVIDE",
                district: "San Isidro",
                latitude: -12.092947,
                longitude: -77.032002
            },
            {
                id: "425",
                FolderPath: "Tiendas Mass",
                name: "Flores 5 SJL MS",
                address: "AV. FLORES DE PRIMAVERA N° 599 MZ 11 LT 7 COOP LAS FLORES",
                reference: "PARADERO 3 DE LAS FLORES-CRUCE AV LAS FLORES CON PJE. LOS AMARUS",
                district: "San Juan de Lurigancho",
                latitude: 12.01883236,
                longitude: -77.0124685
            },
            {
                id: "426",
                FolderPath: "Tiendas Mass",
                name: "Pachacu 21 VMT MS",
                address: "AV. VILLA MARÍA 123 MZ. G-1 LT. 5 PRIMER HOGAR POLICIAL ACU CERCADO",
                reference: "FRENTE A BCP-AV. PACHACUTEC ESQUINA CON AV. VILLA MARÍA",
                district: "Villa María del Triunfo",
                latitude: -12.166733,
                longitude: -76.951543
            },
            {
                id: "427",
                FolderPath: "Tiendas Mass",
                name: "StaElvira LO MS",
                address: "AV. SANTA ELVIRA 6372-6378 MZ. 2F LT. 2-3 URB. PARC. RESIDENCIAL SANTA LUISA II ETAPA",
                reference: "A 2 CDRAS DEL CC. VILLA SOL/CRUCE AV. SANTA ELVIRA CON CALLE SAN HERNÁN",
                district: "Los Olivos",
                latitude: -11.955036,
                longitude: -77.073268
            },
            {
                id: "428",
                FolderPath: "Tiendas Mass",
                name: "Camino C5 CB MS",
                address: "A. CAMINO REAL MZ C LT 3 ASOC VIV PRADERAS DE CARABAYLLO.",
                reference: "AL FRENTE DE AGENTE KASNET - LIBRERÍA ELIAN/LOCAL UBICADO ENTRE LAS CALLES MICAELAS BASTIDAS Y NUEVA ESTRELLA",
                district: "Carabayllo",
                latitude: -11.879872,
                longitude: -77.024695
            },
            {
                id: "429",
                FolderPath: "Tiendas Mass",
                name: "VillaT 5 LO MS",
                address: "AV. UNIVERSITARIA NORTE MZ I LT 3 URB. LOS PARQUES DE VILLA SOL.",
                reference: "A 50 METROS DE LOS EDIFICIOS VILLA SOL/PARADERO VILLA SOL",
                district: "Los Olivos",
                latitude: -11.9682401,
                longitude: -77.0746054
            },
            {
                id: "430",
                FolderPath: "Tiendas Mass",
                name: "Tambo B13 PP MS",
                address: "V. ASOC. PROPIETARIOS TAMBO INGA MZ B LT 13",
                reference: "A MEDIA CUADRA DEL PARADERO FAMESA DE PANAMERICANA NORTE/EN AV LOS ALGARROBOS Y CL 4",
                district: "Puente Piedra",
                latitude: -11.885299,
                longitude: -77.070174
            },
            {
                id: "431",
                FolderPath: "Tiendas Mass",
                name: "Union 1 VMT MS",
                address: "JR. UNION 1624 MZ. 2A LT. 1 SECTOR PJ SAN FCO DE TABLADA DE LURIN",
                reference: "AL FRENTE DEL MERCADO LAS CONCHITAS EN TABLADA/CRUCE DE JR. UNION Y JR. ATAHUALPA",
                district: "Villa María del Triunfo",
                latitude: -12.2020406,
                longitude: -76.9256045
            },
            {
                id: "432",
                FolderPath: "Tiendas Mass",
                name: "PachacuE 9 VE MS",
                address: "MZ E LOTE 07-09-10 GR BARRIO XV SECTOR G URB. POPULAR DE INTERES SOCIAL(UPIS) PROYECTO ESPECIAL CIUDAD PACHACUTEC CN OESTE B.",
                reference: "FRENTE AL GRIFO REPSOL DE AV 225 /CRUCE DE AV 225 Y AV LOS ARQUITECTOS",
                district: "Ventanilla",
                latitude: -11.8383269,
                longitude: -77.13900447
            },
            {
                id: "433",
                FolderPath: "Tiendas Mass",
                name: "Peru 25 SMP MS",
                address: "AV. PERU N° 2573 AAHH URB. PERU ZONA 2DO SECTOR 48.",
                reference: "FRENTE A BOTICA MODERNA/AV. PERU CDRA 24 CREUCE CON CALLE VILLA RICA",
                district: "San Martín de Porres",
                latitude: -12.03207639,
                longitude: -77.07390028
            },
            {
                id: "434",
                FolderPath: "Tiendas Mass",
                name: "Septi G2 LO MS",
                address: "AV. 23 DE SETIEMBRE MZ 81 LT 01 AAHH CHILLON.",
                reference: "A 60 METROS DE INKAFARMA-ENTRE CL MONTERRICO SUR Y CL JOSE CARLOS MARIATEGUI",
                district: "Los Olivos",
                latitude: -11.9231226,
                longitude: -77.07463961
            },
            {
                id: "435",
                FolderPath: "Tiendas Mass",
                name: "Mercado SJL MS",
                address: "AV. DEL MERCADO MZ Q9 LT 2,3 , PROGRAMA CIUDAD MARISCAL CACERES REF. AAHH JOSE CARLOS MARIATEGUI",
                reference: "A UNA CUADRA DEL MERCADO JOSÉ CARLOS MARIÁTEGUI/CRUCE AV DEL MERCADO CON J.J. SEGOVILA",
                district: "San Juan de Lurigancho",
                latitude: -11.939472,
                longitude: -76.990389
            },
            {
                id: "436",
                FolderPath: "Tiendas Mass",
                name: "Marsano 36 SC MS",
                address: "AV. SANTIAGO DE SURCO N° 3664 URB. LOS ROSALES.",
                reference: "ESTACIÓN AYACUCHO LÍNEA 1/AV. SANTIAGO DE SURCO CON AV. AYACUCHO",
                district: "Santiago de Surco",
                latitude: -12.13535179,
                longitude: -76.99675113
            },
            {
                id: "437",
                FolderPath: "Tiendas Mass",
                name: "Condor 6 CB MS",
                address: "AV. CONDORCANQUI MZ A1 LOTE 49,50 ASOC. DE VIV. VILLA CORPAC",
                reference: "AL FRENTE DEL MINIMARKET YAMILET/A 180 MTS DE CRUCE CON AV CHIMPU OCLLO - ENTRE JR SANTIAGO DE CARDENAS Y JR CARLOS MARTINEZ DE PINILLOS",
                district: "Carabayllo",
                latitude: -11.89106272,
                longitude: -77.0379976
            },
            {
                id: "438",
                FolderPath: "Tiendas Mass",
                name: "Rosa N3 SJL MS",
                address: "AV. SANTA ROSA DE LIMA SECCION 01 MZ N III LT 41 - APV INCA  MANCO CAPAC.",
                reference: "FRENTE AL MERCADO NÚMERO 2 SANTA ROSA/CRUCE FLOSFERRI CON AV. SANTA ROSA DE LIMA",
                district: "San Juan de Lurigancho",
                latitude: -12.005336,
                longitude: -76.998257
            },
            {
                id: "439",
                FolderPath: "Tiendas Mass",
                name: "SantaG 7 LO MS",
                address: "CA 9 MZ. G LT 7 ASOC RIO SANTA",
                reference: "CON FRENTE A CONDOMINIOS TORRES DE LOS OLIVOS/ENTRE CALLE SAN BLAS Y ALFREDO MENDIOLA ",
                district: "Los Olivos",
                latitude: -11.94991201,
                longitude: -77.0714444
            },
            {
                id: "440",
                FolderPath: "Tiendas Mass",
                name: "Josey J4 CB MS",
                address: "JR.YEPES MZ J LOTE 4 URB. TUNGASUCA II ETAPA.",
                reference: "FRENTE AL MERCADO MODELO TUNGASUCA-LOCAL UBICADO ENTRE LAS CALLES AYMI TUPAC Y TUPAC AMARU",
                district: "Carabayllo",
                latitude: -11.89259654,
                longitude: -77.04382734
            },
            {
                id: "441",
                FolderPath: "Tiendas Mass",
                name: "Mariate 17 VMT MS",
                address: "AV. JOSÉ CARLOS MARIATEGUI 1700 MZ. 26 LT. 1 SECTOR VALLECITO BAJO ETAPA III JOSE CARLOS MARIATEGUI",
                reference: "A UNA CUADRA DEL COMPLEJO DEPORTIVO VILLA MARÍA DEL TRIUNFO-CRUCE DE AV JOSE CARLOS MARIATEGUI CON AV APURIMAC",
                district: "Villa María del Triunfo",
                latitude: -12.148249,
                longitude: -76.950615
            },
            {
                id: "442",
                FolderPath: "Tiendas Mass",
                name: "BasadreF SJL MS",
                address: "SV MZ F LT 14 1ER PISO APV SANTA ELIZABETH.",
                reference: "A MEDIA CUADRA DE TAMBO DE CANTO GRANDE/AV JORGE BASADRE OESTE CRUCE CON CALLE MARTE",
                district: "San Juan de Lurigancho",
                latitude: -11.989711,
                longitude: -77.014634
            },
            {
                id: "443",
                FolderPath: "Tiendas Mass",
                name: "Tacna 9 MA MS",
                address: "AV. TACNA (EX PASCUAL DE VIVERO) N° 965-967.",
                reference: "A UNA CUADRA DEL MERCADO DE MAGDALENA/CRUCE DE JR. TACNA CON JR. SAN MARTÍN",
                district: "Magdalena del Mar",
                latitude: -12.089397,
                longitude: -77.070801
            },
            {
                id: "444",
                FolderPath: "Tiendas Mass",
                name: "Gloria C3 AT MS",
                address: "PARCELA 10626 MZ C LOTE 02 PROG VIV LAS CASUARINAS DE GLORIA, SEC 31, ZONA 5, 1ER PISO.",
                reference: "A 4 CUADRAS DE LA CARRETERA CENTRAL INGRESANDO POR EL GRIFO CHINITO/A UNOS METROS DEL CRUCE DE AV GLORIA GRANDE CON CALLE GARDENIAS 1°",
                district: "Ate",
                latitude: -12.009656,
                longitude: -76.852931
            },
            {
                id: "445",
                FolderPath: "Tiendas Mass",
                name: "Bolivar 3 PL MS",
                address: "AV. SIMON BOLIVAR 359-361",
                reference: "FRENTE A GRIFO REPSOL/AV. BOLÍVAR CON AV. DEL RÍO",
                district: "Pueblo Libre",
                latitude: -12.07327421,
                longitude: -77.05646686
            },
            {
                id: "446",
                FolderPath: "Tiendas Mass",
                name: "Peru38 SMP MS",
                address: "AV. PERÚ 3858",
                reference: "A 40 MTS DEL CHIFA REAL/ ENTRE JR HUARAZ Y JR HUANCAYO ",
                district: "San Martín de Porres",
                latitude: -12.03020142,
                longitude: -77.09085341
            },
            {
                id: "447",
                FolderPath: "Tiendas Mass",
                name: "Grau 15 PA MS",
                address: "AV. MIGUEL GRAU MZ. 73 LT. 13 BILLA POETA JOE GALVEZ PARCELA B",
                reference: "FRENTE AL MERCADO N°1 JOSE GALVEZ-ENTRE AV. LAS PALMAS Y JR. RICARDO PALMA",
                district: "Pachacamac",
                latitude: -12.21966228,
                longitude: -76.90650305
            },
            {
                id: "448",
                FolderPath: "Tiendas Mass",
                name: "RemoB 17 PP MS",
                address: "MZ B LT 17 ASOCIACION DE VIVIENDA VILLA SAN REMO, ADV ZZONA 04 SECTOR 05",
                reference: "A 120 METROS DE PARADERO FAMESA/ENTRE CALLE 2 Y PASAJE 3 ",
                district: "Puente Piedra",
                latitude: -11.89064788,
                longitude: -77.06968778
            },
            {
                id: "449",
                FolderPath: "Tiendas Mass",
                name: "Circun P SJL MS",
                address: "SV MZ P-2 LT 24, 1ER PISO COOP SANTA MARTHA.",
                reference: "A 1 CUADRA DEL MERCADO 4 DE AGOSTO    ",
                district: "San Juan de Lurigancho",
                latitude: -11.966782,
                longitude: -76.984451
            },
            {
                id: "450",
                FolderPath: "Tiendas Mass",
                name: "Proce 78 LO MS",
                address: "AV. PROCERES N°7898 MZ II4 LT 10-11 URB. PRO 4, SECTOR I ETAPA.",
                reference: "FRENTE A BOTICAS HOGAR Y SALUD    ",
                district: "Los Olivos",
                latitude: -11.93800458,
                longitude: -77.07403913
            },
            {
                id: "451",
                FolderPath: "Tiendas Mass",
                name: "SanjuanB 13 PP MS",
                address: "CALLE ACCOMARCA MZ B LT 13 URBANIZACION SAN JUAN DE VILCA TAMBO INGA ESTE.",
                reference: "EX POLLERIA JUHNIORS/ESQUINA DE AV SAN JUAN DE VILCA Y REMANZO",
                district: "Puente Piedra",
                latitude: -11.876742,
                longitude: -77.06322804
            },
            {
                id: "452",
                FolderPath: "Tiendas Mass",
                name: "America 6 LV MS",
                address: "AV. DE LAS AMERICAS N°667",
                reference: "AL COSTADO DEL PUESTO DE VIGILACIA DE LA MUNICIPALIDA QUE ESTA EN EL OVALO",
                district: "La Victoria",
                latitude: -12.0767238,
                longitude: -77.02096759
            },
            {
                id: "453",
                FolderPath: "Tiendas Mass",
                name: "Huanuco 10 CE MS",
                address: "JR. HUANUCO 1014 - 1016",
                reference: "FRENTE AL HOSPITAL DOS DE MAYO/CRUCE DE JR. HUANUCO CON TARATA",
                district: "Cercado de Lima",
                latitude: -12.05630801,
                longitude: -77.01793105
            },
            {
                id: "454",
                FolderPath: "Tiendas Mass",
                name: "Mar 2 SJL MS",
                address: "SV MZ B LT 04- 1ER PISO PUE JOVEN ENRIQUE MONTENEGRO.",
                reference: "A 2 CUADRAS DE AV FERNANDO WIESSE    ",
                district: "San Juan de Lurigancho",
                latitude: -11.93975612,
                longitude: -76.97231091
            },
            {
                id: "455",
                FolderPath: "Tiendas Mass",
                name: "Rosa 14 PP MS",
                address: "MZ 73 LT 20 ASENTAMIENTO HUMANO SANTA ROSA",
                reference: "A MEDIA CUADRA DE LA CAPILLA DEL SEÑOR DE HUAMANGA    ",
                district: "Puente Piedra",
                latitude: -11.872416,
                longitude: -77.085196
            },
            {
                id: "456",
                FolderPath: "Tiendas Mass",
                name: "Postes 1 SJL MS",
                address: "AV. LOS POSTES OESTE N° 165 MZ E LT 05 APV SAN HILARION",
                reference: "A 2 CUADRAS DE AV FERNADO WISSE",
                district: "San Juan de Lurigancho",
                latitude: -11.997759,
                longitude: -77.010925
            },
            {
                id: "457",
                FolderPath: "Tiendas Mass",
                name: "Colonial 46 BE MS",
                address: "AV. ÓSCAR R. BENAVIDES  4698 URB. SAN JOSÉ BELLAVISTA CALLAO",
                reference: "PLAZA VEA COLONIAL/A 2 CUADRAS DE LA AV. FAUCET",
                district: "Bellavista",
                latitude: -12.053233,
                longitude: -77.094964
            },
            {
                id: "458",
                FolderPath: "Tiendas Mass",
                name: "Peru 50 CA MS",
                address: "AV. PERU PG PROLONG 5076 MZ M LT 32 URB. JORGE CHAVEZ 1ERA ETAPA.",
                reference: "AL COSTADO DE POLLERIA CRISOLITO /ENTRE CALLE JOSE GALVEZ Y CALLE O",
                district: "Callao",
                latitude: -12.02111736,
                longitude: -77.10016277
            },
            {
                id: "459",
                FolderPath: "Tiendas Mass",
                name: "Chavez 1F VES MS",
                address: "SECTOR 06 GRUPO 04A MZ. F LT. 17",
                reference: "Al frente del mercado Arenas en Villa el Salvador/Cruce de Av. Maria Elena Moyano y Jorge Chavez",
                district: "Villa El Salvador",
                latitude: -12.2180881,
                longitude: -76.9508289
            },
            {
                id: "460",
                FolderPath: "Tiendas Mass",
                name: "ProceK37 SJL MS",
                address: "AV. PROCERES DE LA INDEPENDENCIA MZ K1 LT 37 (N°2761)",
                reference: "A MEDIA CUADRA DEL MERCADO SAN CARLOS/CRUCE DE AV. EL SOL CON AV. PROCERES (MEDIA CUADRA DE LA ESTACIÓN DEL METRO)",
                district: "San Juan de Lurigancho",
                latitude: -11.9849555,
                longitude: -77.005682
            },
            {
                id: "461",
                FolderPath: "Tiendas Mass",
                name: "Bueno 11 PP MS",
                address: "AV. CERCADO DE PUENTE PIEDRA - BUENOS AIRES 1123A ADV ZONA 03 SECTOR 0 - PUENTE PIEDRA",
                reference: "BODEGAS, BOTICAS, COMERCIOS MEDIANOS/A 1/2 CUADRA DE CRUCE AV. BUENOS AIRES CON AV. SAN JUAN",
                district: "Puente Piedra",
                latitude: -11.8587881,
                longitude: -77.0844966
            },
            {
                id: "462",
                FolderPath: "Tiendas Mass",
                name: "26Nov 94 VMT MS",
                address: "AV. 26 DE NOVIEMBRE S/N MZ. 94 LT. 2 PUEBLO JOVEN NUEVA ESPERANZA",
                reference: "AL COSTADO DEL MERCADO SANTA ROSA EN AV. 26 DE NOVIEMBRE VMT/A MEDIA CUADRA DEL CRUCE CON CALLE ARICA",
                district: "Villa María del Triunfo",
                latitude: -12.16714386,
                longitude: -76.92439195
            },
            {
                id: "463",
                FolderPath: "Tiendas Mass",
                name: "Basadre W SJL MS",
                address: "AV. BASADRE, JORGE ESTE N° 117-119 MZ W LT 20 1ER PISO AVP JORGE BASADRE.",
                reference: "AL COSTADO DE POLLERÍA NORKY'S/CRUCE DE AV. PRÓCERES DE LA INDEPENDENCIA CON AV. JORGE BASADRE ESTE",
                district: "San Juan de Lurigancho",
                latitude: -11.98982762,
                longitude: -77.01004757
            },
            {
                id: "464",
                FolderPath: "Tiendas Mass",
                name: "Loreto 2 LM MS",
                address: "JR. MADRE DE DIOS MZ. K LT. 4, 5 URB. SANTA PATRICIA II ETAPA, LA MOLINA",
                reference: "A UNA CUADRA DE AV JAVIER PRADO ESTE/CRUCE DE CALLE AREQUIPA CON MADRE DE DIOS",
                district: "La Molina",
                latitude: -12.06938387,
                longitude: -76.94615774
            },
            {
                id: "465",
                FolderPath: "Tiendas Mass",
                name: "Haya 2 LP MS",
                address: "AV. VICTOR R. HAYA DE LA TORRE N°203 MZ D LT 25 URB BALTA RIMAC LA PERLA",
                reference: "Al costado del Hotel Francis/Cruce calle Uzquil con Av Victor Raúl Haya de la Torre",
                district: "La Perla",
                latitude: -12.068197,
                longitude: -77.109161
            },
            {
                id: "466",
                FolderPath: "Tiendas Mass",
                name: "Alameda U25 CO MS",
                address: "AV. ALAMEDA DEL PINAR MZ U LT 25 URB. ALAMEDA DEL PINAR.",
                reference: "A 100 mts de Paradero Alameda - Trapiche /Entre Cl Las Begonias y Cl A",
                district: "Comas",
                latitude: -11.901621,
                longitude: -77.055276
            },
            {
                id: "467",
                FolderPath: "Tiendas Mass",
                name: "Izagui 26 SMP MS",
                address: "AV. CARLOS IZAGUIRRE S/N MZ A LT 2 CDRA 28, PROG RES CALIFORNIA SECTOR 25",
                reference: "A 50 MTS DE BOTICA CAMILA´S/ESQUINA DE AV IZAGUIRRE CON CL LOS LIRIOS",
                district: "San Martín de Porres",
                latitude: -11.98971781,
                longitude: -77.1021648
            },
            {
                id: "468",
                FolderPath: "Tiendas Mass",
                name: "Canto 5 SJL MS",
                address: "AV. CANTO REY MZ B LT 11 1ER PISO APV GARAGAY.",
                reference: "FRENTE AL MERCADO CANTO REY/AV CANTO REY CRUCE CON RÍO UCAYALI",
                district: "San Juan de Lurigancho",
                latitude: -11.97798011,
                longitude: -76.99831507
            },
            {
                id: "469",
                FolderPath: "Tiendas Mass",
                name: "Wiesse N7 SJL MS",
                address: "SV MZ N-7 LT41 1ER PISO PROGRAMA CIUDAD MARISCAL CACERES SECTOR I 1ERA ETAPA",
                reference: "A 3 PREDIOS DEL MERCADO 5 DE NOVIEMBRE/AV FERNANDO WIESSE CRUCE CON ROMA",
                district: "San Juan de Lurigancho",
                latitude: -11.96316492,
                longitude: -76.99029085
            },
            {
                id: "470",
                FolderPath: "Tiendas Mass",
                name: "Ollantay3 VMT MS",
                address: "ZONA: JOSE CARLOS MARIATEGUI- SECTOR: PUEBLO JOVEN JOSE CARLOS MARIATEGUI SECTOR VALLECITO ALTO ETAPA QUINTA JR.INCA OLLANTAY N°337 MZ.211 LT.01",
                reference: "A MEDIA CUADRA DEL PARQUE PRINCIPAL OLLANTAY/CRUCE DE JR. INCA OLLANTAY CON CALLE ALFONSO UGARTE",
                district: "Villa María del Triunfo",
                latitude: -12.142604,
                longitude: -76.9391726
            },
            {
                id: "471",
                FolderPath: "Tiendas Mass",
                name: "Central 3 LO MS",
                address: "AV. CENTRAL MZ 2J LT 14URB. PARC RESIDENCIAL SANTA LUISA II ETAPA",
                reference: "FRENTE A D´GALIA - LIMA NORTE/EN AV ALFREDO MENDIOLA Y CL SAN ERNESTO",
                district: "Los Olivos",
                latitude: -11.95873,
                longitude: -77.07052
            },
            {
                id: "472",
                FolderPath: "Tiendas Mass",
                name: "Canada 1 CO MS",
                address: "CLL CANADA N°141 URB. HUAQUILLAY I ETAPA",
                reference: "A 40 MTS DE RENIEC COMAS/ENTRE AV. TUPAC AMARU Y AV. REPUBLICA DEL PERU",
                district: "Comas",
                latitude: -11.948005,
                longitude: -77.050214
            },
            {
                id: "473",
                FolderPath: "Tiendas Mass",
                name: "MarcoC 22 SMP MS",
                address: "AV. MARCO POLO N° 1636 MZ C-1 LT 28 URB. FIORI-SECTOR 36-3 ETAPA",
                reference: "FRENTE AL MERCADO MAYORISTA DE FIORI/ENTRE CALLES NAPOLES Y MIGUEL ANGEL ",
                district: "San Martín de Porres",
                latitude: -12.00985795,
                longitude: -77.05706295
            },
            {
                id: "474",
                FolderPath: "Tiendas Mass",
                name: "Allende 1 VMT MS",
                address: "ZONA: JOSE CARLOS MARIATEGUI - SECTOR: PUEBLO JOVEN JOSE CARLOS MARIATEGUI ETAPA PRIMERA-AV SALVADOR ALLENDE N°172 MZ. N2 LT.13",
                reference: "A 2 CASAS DE BANCO AZTECA/ENTRE AV. SALVADOR ALLENDE CON AV. JOSE CARLOS MARIATEGUI",
                district: "Villa María del Triunfo",
                latitude: -12.1595851,
                longitude: -76.9558288
            },
            {
                id: "475",
                FolderPath: "Tiendas Mass",
                name: "GuardiaA1CLL MS",
                address: "AV.GUARDIA PERUANA URBANIZACION MATELLINI MZ.A01 LT.06 URB. MATELLINI",
                reference: "A 3 CASAS DEL GIMNASIO ARNOLD/ENTRE AV. GUARDIA PERUANA CON AV. LOS PUMAS",
                district: "Chorrillos",
                latitude: -12.1749772,
                longitude: -77.0083927
            },
            {
                id: "476",
                FolderPath: "Tiendas Mass",
                name: "DomingoD8 PP MS",
                address: "AV. ROLLIN THORNE SOLAGUREN URB STO.DOMINGODE PUENTE PIEDRA MZ D LOTE 8​",
                reference: "FRENTE AL MERCADO SAN MIGUEL CAMPOY/LOS PINOS CON LOS JAZMINES",
                district: "Puente Piedra",
                latitude: -11.86715801,
                longitude: -77.07456716
            },
            {
                id: "477",
                FolderPath: "Tiendas Mass",
                name: "Carabay 10 CO MS",
                address: "AV.CARABAYLLO (CA.127)NRO.1081-1083 MZ.Ñ-3 LTE 12",
                reference: "CERCA AL MERCADO SANTA ROSA (1 CDRA)/CERCA AL CRUCE DE AV. MIGUEL GRAU CON CALLE ROLLIN THORNE SOLAGUREN",
                district: "Comas",
                latitude: -11.9521792,
                longitude: -77.0565085
            },
            {
                id: "478",
                FolderPath: "Tiendas Mass",
                name: "Retablo 11 CO MS",
                address: "URB EL RETABLO IV ETAPA B - AV. EL RETABLO NRO 1131 MZN R LTE 32 - COMAS ",
                reference: "FRENTE AL MERCADO EL PARRAL/CRUCE DE AV. CARABAYLLO CON CALLE PARIS",
                district: "Comas",
                latitude: -11.92805223,
                longitude: -77.05585858
            },
            {
                id: "479",
                FolderPath: "Tiendas Mass",
                name: "Benavi 38 SC MS",
                address: "AV. BENAVIDES, ALFREDO 3879 LOCAL RESTAURANT URB. CHAMA",
                reference: "FRENTE AL MERCADO NARANJAL /ENTRE PASAJE 106 Y PASAJE JUAN DE DIOS DIAZ ",
                district: "Santiago de Surco",
                latitude: -12.12827754,
                longitude: -76.99489174
            },
            {
                id: "480",
                FolderPath: "Tiendas Mass",
                name: "Grau 3 BA MS",
                address: "AV. ALMIRANTE MIGUEL GRAU 341, BARRANCO",
                reference: "FRENTE AL TAMBO/CRUCE DE AV. BENAVIDES CON CL. ARTURO GARCIA SALAZAR",
                district: "Barranco",
                latitude: -12.14829402,
                longitude: -77.02127064
            },
            {
                id: "481",
                FolderPath: "Tiendas Mass",
                name: "Fray 4 SMP MS",
                address: "AVENIDA FRAY BARTOLOME DE LAS CASAS ->(340370) NRO 408 MZ D1 LT 02 CDRA 4 LADO DER. LUGAR: URB LOS JARDINES II ETAPA ==> (1990) SECTOR 36 URB LOS JARDINES",
                reference: "A MEDIA CUADRA DEL INTERBANK/A MEDIA CUADRA DE LA PLAZA Y EL BULEVARD DE BARRANCO",
                district: "San Martín de Porres",
                latitude: -12.012995,
                longitude: -77.0563861
            },
            {
                id: "482",
                FolderPath: "Tiendas Mass",
                name: "Belaunde 8 CO MS",
                address: "AV. BELAUNDE OESTE N°828 MZ E-X LT 4-5 URB. EL RETABLO I ETAPA",
                reference: "CHIFA LONG SEN, BODEGAS C&G, MINIMARKETS/ENTRE CL JUNCOS Y PSJE LOS ESPINOS",
                district: "Comas",
                latitude: -11.9364098,
                longitude: -77.05449826
            },
            {
                id: "483",
                FolderPath: "Tiendas Mass",
                name: "Alisos 10 SMP MS",
                address: "AV. LOS ALISOS P-1 LT 07-08 ASOC. JAZMINES DE NARANJAL, SMP",
                reference: "AL COSTADO DE PIZZA RAUL/ENTRE AV UNIVERSITARIA Y JR SANCHEZ CARRION ",
                district: "San Martín de Porres",
                latitude: -11.98209157,
                longitude: -77.09020234
            },
            {
                id: "484",
                FolderPath: "Tiendas Mass",
                name: "Inca 68 VMT MS",
                address: "JR. ISABEL DE OLIVA S/N MZ. 68 LT. 02 SECTOR VALLECITO BAJO ETAPA III JCM",
                reference: "AL COSTADO DEL MERCADO LOS JAZMINES DEL NARANJAL/ESQUINA AV. ALISOS Y AV. LAS MALVAS ",
                district: "Villa María del Triunfo",
                latitude: -12.1481319,
                longitude: -76.9450209
            },
            {
                id: "485",
                FolderPath: "Tiendas Mass",
                name: "Ciruelas 1 CO MS",
                address: "CLL LAS CIRUELAS N° 108 MZ D1 LT 1-2 URB. LA ALBORADA II ETAPA.",
                reference: "POR EL MERCADO SAN JUAN BOSCO/CRUCE DE AV. LATINOAMERICA CON AV.INCA GARCILAZO",
                district: "Comas",
                latitude: -11.91157546,
                longitude: -77.04644287
            },
            {
                id: "486",
                FolderPath: "Tiendas Mass",
                name: "Blondev 46 LO MS",
                address: "JR MORENO ANTONIO N°196 MZ V LT 46 URB. VILLA SOL II ETAPA.",
                reference: "AL COSTADO DEL RESTAURANT SABORES DEL NORTE /ENTRE AV. UNIVERSITARIA Y CL FRUTALES",
                district: "Los Olivos",
                latitude: -11.9661472,
                longitude: -77.0708028
            },
            {
                id: "487",
                FolderPath: "Tiendas Mass",
                name: "Huanca D5 SA MS",
                address: "URB. SANTA AURELIA AV. HUANCARAY MZ. B1 LT. 06, SANTA ANITA",
                reference: "FRENTE A CAPILLA VIRGEN DE LA MEDALLA MILAGROSA/ESQUNA DE AV. ARMANDO BLONDET Y CL 24 ",
                district: "Santa Anita",
                latitude: -12.0378086,
                longitude: -76.9536028
            },
            {
                id: "488",
                FolderPath: "Tiendas Mass",
                name: "Talara 12 VES MS",
                address: "SCTR SECTOR 3 GRUPO 18-(NINGUNO)- MANZANA N LOTE 12 UNIDAD INMOBILIARIA 01",
                reference: "FRENTE A TAMBO/CRUCE AV HUANCARAY CON AV. HUAROCHIRÍ",
                district: "Villa El Salvador",
                latitude: -12.2271474,
                longitude: -76.931178
            },
            {
                id: "489",
                FolderPath: "Tiendas Mass",
                name: "BerteB 15 CA MS",
                address: "AV. BERTELLO MZ B LT 15 APV DE LOS TRABAJADORES DEL PUERTO, CALLAO",
                reference: "POR EL MERCADO TUPAC AMARU/CRUCE DE AV. TALARA CON AV. ALAMOS",
                district: "Callao",
                latitude: -11.99993575,
                longitude: -77.11093134
            },
            {
                id: "490",
                FolderPath: "Tiendas Mass",
                name: "Satelite31VE MS",
                address: "URBANIZACION CIUDAD SATELITE DE VENTANILLA MZ V-31 LOTE 13 - VENTANILLA ",
                reference: "A 40 MTS DE SPA YOVANA/ENTRE CL 4 Y CL LOS CIBELES ",
                district: "Ventanilla",
                latitude: -11.8724959,
                longitude: -77.12227435
            },
            {
                id: "491",
                FolderPath: "Tiendas Mass",
                name: "Mayo D12 MS",
                address: "AV. 26 DE MAYO MZ. D LT. 12 COOP. LAS PALMERAS",
                reference: "AL COSTADO DEL RESTAURANTE LA TABERNA II/ENTRE CL 10 Y CL 09",
                district: "Ate",
                latitude: -12.02673,
                longitude: -76.93179
            },
            {
                id: "492",
                FolderPath: "Tiendas Mass",
                name: "Castilla3 LU MS",
                address: " JR. CASTILLA MZ. I LT. 36",
                reference: "FRENTE AL CONDOMINIO LAS TORRES DE CERES - ATE",
                district: "Lurin",
                latitude: -12.2763289,
                longitude: -76.8691522
            },
            {
                id: "493",
                FolderPath: "Tiendas Mass",
                name: "Principal5 SU MS",
                address: "AV. PRINCIPAL N° 560 MZ R LT 7",
                reference: "A 1 CUADRA DEL MERCADO SANTA ROSA/ENTRE JR. CASTILLA CON JR. TARAPACA",
                district: "Surquillo",
                latitude: -12.116964,
                longitude: -77.006464
            },
            {
                id: "494",
                FolderPath: "Tiendas Mass",
                name: "Alameda 31 LM MS",
                address: "ALAMEDA DEL CORREGIDOR MZ. E LT. 19 - URB LAS PRADERAS DE LA MOLINA - LA MOLINA",
                reference: "FRENTE A HUACA LA MERCED/AV. PRINCIPAL CUADRA 5",
                district: "La Molina",
                latitude: -12.11506535,
                longitude: -76.939866
            },
            {
                id: "495",
                FolderPath: "Tiendas Mass",
                name: "Habich5 SMP MS",
                address: "AVENIDA EDUARDO HABICH -> (350362) NRO 529 MZ C-1 LT 13 CDRA 5 LADO IZQ LUGAR URB INGENIERIA 1RA, 2DA (1820-1830) ==> (0136) SECTOR 44",
                reference: "A 3 CUADRAS DE LA COMISARÍA DE LAS PRADERAS/MEDIA CUADRA DEL CRUCE CON CALLE RIO MAGDALENA",
                district: "San Martín de Porres",
                latitude: -12.027883,
                longitude: -77.0563976
            },
            {
                id: "496",
                FolderPath: "Tiendas Mass",
                name: "NestorC6 CA MS",
                address: "AV. NESTOR GAMBETTA MZ C6 LT. 03 - CALLAO",
                reference: "AL COSTADO DE TAMBO, AL FRENTE DE BOTICAS ARCANGEL/A 1 CDRA DEL OVALO HABICH",
                district: "Callao",
                latitude: -11.9722861,
                longitude: -77.1247345
            },
            {
                id: "497",
                FolderPath: "Tiendas Mass",
                name: "Pimentel2 CO MS",
                address: "JR. PIMENTEL 297 URB SAN CARLOS",
                reference: "AV. GAMBETTA CON CALLE CALIFORNIA/PUENTE PEATONAL OQUENDO",
                district: "Comas",
                latitude: -11.9073996,
                longitude: -77.0375536
            },
            {
                id: "498",
                FolderPath: "Tiendas Mass",
                name: "Jazmin1 IND MS",
                address: "JAZMINES 109 -  111  ERMITAÑO",
                reference: "A MEDIA CUADRA DEL MERCADO VILLAREAL/EXACTAMENTE EN EL CRUCE DE CALLE PIMENTEL CON CALLE SAN LUIS",
                district: "Independencia",
                latitude: -12.00132488,
                longitude: -77.0544787
            },
            {
                id: "499",
                FolderPath: "Tiendas Mass",
                name: "Prima 2 PP MS",
                address: "AVENIDA PRIMAVERA MZ D LT 07 CENTRO POBLADO RURAL EL CALICHE",
                reference: "A DOS LOTES DE INKAFARMA/ENTRE AV TUPAC AMARU Y CL GARDENIAS",
                district: "Puente Piedra",
                latitude: -11.84874194,
                longitude: -77.09774114
            },
            {
                id: "500",
                FolderPath: "Tiendas Mass",
                name: "SaucesE16 CH MS",
                address: "MZ E LT 16 (DE VIV. CAMPO SOL - CARAPONGO) ",
                reference: "AL COSTADO DE POLLERIA CARBON Y LEÑA/EN LA CL LOS VIÑEDOS CL LAS MALVINAS",
                district: "Lurigancho-Chosica",
                latitude: -12.0031458,
                longitude: -76.864223
            },
            {
                id: "501",
                FolderPath: "Tiendas Mass",
                name: "Alva 5 CO MS",
                address: "JR.ALVARADO 510 -511 - JR ARNALDO ALVARADO MZ F LT 12 PJ. PAMPA DE COMAS",
                reference: "MERCADO CENTRAL DE CARAPONGO/CERCA AL CRUCE JR. SAUCES CON AV. 24 SETIEMBRE",
                district: "Comas",
                latitude: -11.95676069,
                longitude: -77.04824809
            },
            {
                id: "502",
                FolderPath: "Tiendas Mass",
                name: "Marquez 5G CLL MS",
                address: "AV. MARQUEZ DE CORPAC MZ G LT 5(PROCERES LOS CEDROS)",
                reference: "A 70 MTS DEL MERCADO COMAS/ENTRE AV GRAU Y AV AREQUIPA",
                district: "Chorrillos",
                latitude: -12.2020985,
                longitude: -77.0205171
            },
            {
                id: "503",
                FolderPath: "Tiendas Mass",
                name: "Indepen20VMT MS",
                address: "PUEBLO JOVEN SN FCO. DE TABLADA DE LURIN- SECTOR 1,2,3- JR. INDEPENDENCIA N° MZ.9N LT.20",
                reference: "POR EL MERCADO LAS PALMERAS/CRUCE DE AV. MARQUEZ DE CORPAC CON C.F",
                district: "Villa María del Triunfo",
                latitude: -12.18980726,
                longitude: -76.92272737
            },
            {
                id: "504",
                FolderPath: "Tiendas Mass",
                name: "PedroA3 CB MS",
                address: "RESIDEN. LOS JAZMINES DE SAN PEDRO DE CARABAYLLO, MANZANA A LOTE 3 ",
                reference: "POR EL MERCADO FLORA TRISTAN/CRUCE DE AV. LOS INCAS CON AV. FLORA TRISTAN",
                district: "Carabayllo",
                latitude: -11.8540662,
                longitude: -77.0367801
            },
            {
                id: "505",
                FolderPath: "Tiendas Mass",
                name: "Union23 SANT MS",
                address: "AV. UNIÓN JICAMARCA MZ LL LT23 ASOC AGRUPACION VECINAL SECTOR NORTE ANEXO 8",
                reference: "AL COSTADO MERCADO MODELO SAN PEDRO CARABAYLLO/AV. SACO ROJAS CON AV. CAMINO REAL",
                district: "San Antonio",
                latitude: -11.9747808,
                longitude: -76.9403695
            },
            {
                id: "506",
                FolderPath: "Tiendas Mass",
                name: "Libertad29 SM MS",
                address: "JR. LIBERTAD 2961 SAN MIGUEL",
                reference: "A UNA CUADRA DE LA CAJA AREQUIPA Y DE SOLGAS/AV. UNION JICAMARCA Y LUIS PARDO",
                district: "San Miguel",
                latitude: -12.07661708,
                longitude: -77.10900661
            },
            {
                id: "507",
                FolderPath: "Tiendas Mass",
                name: "Sanluis31 SB MS",
                address: "AVENIDA SAN LUIS NRO 3148 MZ. C LT. 12 PEQUEÑOS AGRICULTORES TODOS LOS SANTOS-SAN BORJA",
                reference: "A 1 CUADRA DEL MERCADO INSURGENTES/A UNA CUADRA DEL CRUCE DE AV. INSURGENTES CON JR. LIBERTAD",
                district: "San Borja",
                latitude: -12.110146,
                longitude: -76.993669
            },
            {
                id: "508",
                FolderPath: "Tiendas Mass",
                name: "CampoyC2 SJL MS",
                address: "Mz C Lote 2A - APV San Hilarión de Campoy - SJL",
                reference: "TAMBO (AL COSTADO)/AV. SAN LUIS CON AV. PRIMAVERA",
                district: "San Juan de Lurigancho",
                latitude: -12.022125,
                longitude: -76.9686827
            },
            {
                id: "509",
                FolderPath: "Tiendas Mass",
                name: "ChillonQ22 PP MS",
                address: "M Q - L 22 ASOC. DE VIVIENDA CHILLON",
                reference: "bodegas minimarket cerca al Mercado Tupac Amaru y tienda Te Apuesto/Cerca al cruce de Av. San Martin y Av. Malecon checa",
                district: "Puente Piedra",
                latitude: -11.924156,
                longitude: -77.077434
            },
            {
                id: "510",
                FolderPath: "Tiendas Mass",
                name: "FloresC 11 CY MS",
                address: "cooperación la floresta manzana C lote 11 Chaclacayo  ",
                reference: "A 2 CUADRAS DEL MERCADO MODELO CHILLÓN/A UNA CUADRA DEL CRUCE DE MAL. CHILLON CON AV. EL PUENTE",
                district: "Chaclacayo",
                latitude: -11.9914245,
                longitude: -76.8220054
            },
            {
                id: "511",
                FolderPath: "Tiendas Mass",
                name: "Merced 3 SMP MS",
                address: "Avenida Santa Mercedes n° 367 Urbanización Palao I Etapa - SMP",
                reference: "bodegas, minimarkets y restaurantes locales/a 1/2 cdra de carretera central, paradero Huascata",
                district: "San Martín de Porres",
                latitude: -12.0194738,
                longitude: -77.0636761
            },
            {
                id: "512",
                FolderPath: "Tiendas Mass",
                name: "Alamos1M VES MS",
                address: "SECTOR 03 GRUPO 13 MZ. M LT.01",
                reference: "Al frente de Cevicheria \"Por mi madre\" y Pollería Julito's/a Pocos pasos del cruce de Sta Margarita con Sta Mercedes",
                district: "Villa El Salvador",
                latitude: -12.2151688,
                longitude: -76.9387257
            },
            {
                id: "513",
                FolderPath: "Tiendas Mass",
                name: "Dominico 6 CA MS",
                address: "Av Los Dominicos Mz E1 Lt 23 - Callao",
                reference: "POR EL MERCADO SAN PEDRO VES/CRUCE DE AV. ALAMOS CON AV. CESAR VALLEJO",
                district: "Callao",
                latitude: -12.009334,
                longitude: -77.09786935
            },
            {
                id: "514",
                FolderPath: "Tiendas Mass",
                name: "Izagui11 LO MS",
                address: "AV. CARLOS IZAGUIRRE -MZ J LT 26 - URB. COVIDA 1 ETAPA -  LOS OLIVOS    ",
                reference: "Al costado de la Panaderia artesanal \"El pastelero\"/Esquina de Av. Dominicos y Los Heliotropos",
                district: "Los Olivos",
                latitude: -11.9919955,
                longitude: -77.0753242
            },
            {
                id: "515",
                FolderPath: "Tiendas Mass",
                name: "Habich 131 SMP MS",
                address: "Av. Eduado de Habich Nro 131 Mz A-3 Lote 20 Urb Ingenieria Sector 44 - SMP",
                reference: "A 20 MTS DE TARAPORO COURIER",
                district: "San Martín de Porres",
                latitude: -12.0240533,
                longitude: -77.0507657
            },
            {
                id: "516",
                FolderPath: "Tiendas Mass",
                name: "Olaya4 VTM MS",
                address: "AV. JOSE OLAYA 468 MZ. 167 LT. 2 SECT. VALLECITO ALTO ETAPA QUINTA J.C.M",
                reference: "KFC, Al costado de Tienda Mass Habich1 SMP MS/A 1 cdra de cruce Habich con Unger",
                district: "Villa María del Triunfo",
                latitude: -12.1433106,
                longitude: -76.9448309
            },
            {
                id: "517",
                FolderPath: "Tiendas Mass",
                name: "Acaceres 2 SU MS",
                address: "AV. ANDRES AVELINO CACERES N° 479. SURQUILLO.",
                reference: "POR EL MERCADO GIRASOL/CRUCE DE AV. JOSE OLAYA CON AV. TARAPOTO",
                district: "Surquillo",
                latitude: -12.119388,
                longitude: -77.020722
            },
            {
                id: "518",
                FolderPath: "Tiendas Mass",
                name: "Fresnos 13 LM MS",
                address: "AV. LOS FRESNOS MZ 13 LT 7",
                reference: "CERCA A MUNICIPALIDAD DE SURQUILLO",
                district: "La Molina",
                latitude: -12.104609,
                longitude: -76.941132
            },
            {
                id: "519",
                FolderPath: "Tiendas Mass",
                name: "Luna 2 SMP MS",
                address: "AV. FRANCISCO JAVIER LUNA PIZARRO N° 270 - URB. INGENIERÍA",
                reference: "RESTAURANTE LAS TINAJAS/A 2 CUADRAS DEL OVALO LOS CONDORES",
                district: "San Martín de Porres",
                latitude: -12.0246995,
                longitude: -77.060941
            },
            {
                id: "520",
                FolderPath: "Tiendas Mass",
                name: "Tupac 16 CB MS",
                address: "AV. TUPAC AMARU 1636 MANZANA 14 LOTE 1 UI.INT. 01 - CARABAYLLO",
                reference: "FRENTE AL MERCADO INGENIERIA/A 1 CDRA DE CRUCE AV. LUNA PIZARRO CON AV. MENDIOLA",
                district: "Carabayllo",
                latitude: -11.89114387,
                longitude: -77.02709055
            },
            {
                id: "521",
                FolderPath: "Tiendas Mass",
                name: "LomasA6 PA MS",
                address: "ASOC. DE VIV. LAS LOMAS DE PACHACAMAC, CLLE. S/N, MZA. A, LT. 06, DISTRITO DE PACHACAMAC, PROVINCIA Y DEPARTAMENTO DE LIMA.",
                reference: "CERCA A MERCADO SANTA ROSA/CRUCE AV. TUPAC AMARU CON AV. MARIATEGUI",
                district: "Pachacamac",
                latitude: -12.08414129,
                longitude: -76.87863253
            },
            {
                id: "522",
                FolderPath: "Tiendas Mass",
                name: "Quilca10 CA MS",
                address: "AV QUILCA N°1007 ",
                district: "Callao",
                latitude: -12.03430338,
                longitude: -77.09641164
            },
            {
                id: "523",
                FolderPath: "Tiendas Mass",
                name: "SantaA33 SA MS",
                address: "AV. SANTA ANA MZ A LT 41 - SANTA ANITA",
                reference: "ENTRE CL URANO Y CL SATURNOAL COSTADO DE SHOP EXPRESS/",
                district: "Santa Anita",
                latitude: -12.04214418,
                longitude: -76.95509749
            },
            {
                id: "524",
                FolderPath: "Tiendas Mass",
                name: "Villamar 6 CLL MS",
                address: "AVENIDA C URBANIZACION VILLAMAR MZ.C-07 LT.06 URB.VILLAMAR",
                reference: "A MEDIA CDRA DE MERCADO LA MODERNA Y MCDO. 1 DE MAYO/A 1 CDRA DE CRUCE AV. HUAROCHIRI CON AV. SANTA ANA",
                district: "Chorrillos",
                latitude: -12.2059125,
                longitude: -77.0115647
            },
            {
                id: "525",
                FolderPath: "Tiendas Mass",
                name: "VirreyI12 SA MS",
                address: "COOPERATIVA VIÑA SAN FRANCISCO AV. LOS VIRREYES MZ. I, LT. 12",
                reference: "POR LA TRANQUERA DE LA ENCANTADA/CRUCE DE AV. ALAMEDA LOS CEDROS CON AV. LOS HORIZONTES",
                district: "Santa Anita",
                latitude: -12.03315973,
                longitude: -76.95035596
            },
            {
                id: "526",
                FolderPath: "Tiendas Mass",
                name: "Octubre E5 CLL MS",
                address: "AV. 24 DE OCTUBRE  MZ E5-LOTE 15",
                reference: "AL COSTADO DE MIFARMA Y POLLERÍA REYES BRASA, AL COSTADO DE CASAS DE APUESTA/CERCA AL CRUCE DE AV. VIRREYES CON AV. CULTURA",
                district: "San Juan de Miraflores",
                latitude: -12.1959588,
                longitude: -76.983628
            },
            {
                id: "527",
                FolderPath: "Tiendas Mass",
                name: "Bologne1 MA MS",
                address: "JR. BOLOGNESI 137 MAGDALENA",
                reference: "POR EL MERCADO SAN PEDRO/CRUCE DE AV. 24 DE OCTUBRE CON TUPAC AMARU",
                district: "Magdalena del Mar",
                latitude: -12.09304968,
                longitude: -77.06912802
            },
            {
                id: "528",
                FolderPath: "Tiendas Mass",
                name: "Garzon 6 JM MS",
                address: "AV. GENERAL EUGENIO GARZON NUM. 00651 -653 TDA.4 T.B",
                reference: "A 3 CUADRAS DE LA PLAZA DE MAGDALENA/A MEDIA CUADRA DEL CRUCE CON AV. BRASIL",
                district: "Jesús María",
                latitude: -12.066413,
                longitude: -77.045013
            },
            {
                id: "529",
                FolderPath: "Tiendas Mass",
                name: "Militar 22 LI MS",
                address: "AV. MILITAR 2258 LINCE",
                reference: "A 50 METROS DEL GRIFO PETRO AMERICA/GARZON CRUCE CON JUAN ANTONIO RIBEYRO",
                district: "Lince",
                latitude: -12.0861813,
                longitude: -77.0311485
            },
            {
                id: "530",
                FolderPath: "Tiendas Mass",
                name: "Braille 13 CE MS",
                address: "AV. LUIS BRAILLE N° 1368 - 1386 - CERCADO DE LIMA",
                reference: "A UNA CUADRA DEL MERCADO LOBATON  EN LINCE/A MEDIA CUADRA DEL CRUCE DE MILITAR CON TOMAS GUIDO LINCE",
                district: "Cercado de Lima",
                latitude: -12.0524388,
                longitude: -77.0670656
            },
            {
                id: "531",
                FolderPath: "Tiendas Mass",
                name: "Antigua1 LU MS",
                address: "CAR. ANTIGUA PANAMERICANA SUR MZ.A01 LT.12",
                reference: "TORRE CONDOMINIOS LOS CIPRECES/CRUCE AV. BRAILLE Y AV. ARICA",
                district: "Lurin",
                latitude: -12.3075021,
                longitude: -76.8395542
            },
            {
                id: "532",
                FolderPath: "Tiendas Mass",
                name: "Iglesias5 CLL MS",
                address: "AV. ALEJANDRO IGLESIAS Nº 591",
                reference: "FRENTE AL RESTAURANTE MARAS/ENTRE AVENIDA PANAMERICANA SUR CON CALLE EL PULPO",
                district: "Chorrillos",
                latitude: -12.1685357,
                longitude: -77.0259491
            },
            {
                id: "533",
                FolderPath: "Tiendas Mass",
                name: "CINERARI47SC MS",
                address: "JR CINERARIA MZ F LT 47",
                reference: "FRENTE A MERCADO MUNICIPAL CHORRILOS N°1/AV. ALEJANDRO IGLESIAS ESQ. CON JR. OSCAR DE LA BARRERA",
                district: "Santiago de Surco",
                latitude: -12.0955258,
                longitude: -76.9636935
            },
            {
                id: "534",
                FolderPath: "Tiendas Mass",
                name: "UNIVER7 SM MS",
                address: "AV. UNIVERSITARIA 721",
                reference: "FRENTE AL MERCADO VILLA LIBERTAD/A 2 CUADRAS DE LA AV. RAUL FERRERO",
                district: "San Miguel",
                latitude: -12.0812245,
                longitude: -77.0824093
            },
            {
                id: "535",
                FolderPath: "Tiendas Mass",
                name: "Peru22 SMP MS",
                address: "AV.PERU =(350900) NRO.2209, CDRA:22LADO IZQ. LUGAR AAHH. URB.PERU ZONA2 SECTOR LL (0854)SECTOR 48.",
                reference: "AL COSTADO DEL BVVA/AV. UNIVERSITARIA CUADRA 7",
                district: "San Martín de Porres",
                latitude: -12.0316133,
                longitude: -77.0691698
            },
            {
                id: "536",
                FolderPath: "Tiendas Mass",
                name: "Lomas27PP MS",
                address: "VIA AAHH PROY. INT. ZAPALLAL ALTO SECTOR L MZ 01 LT 27 AH. PROYECTO INTEGRAL LOMAS DE ZAPALLAL ALTO LOMAS",
                reference: "FRENTE AL BANCO BCP/A UNA CDRA DE LA CALLE MONTERREY Y AV. PERU ",
                district: "Puente Piedra",
                latitude: -11.8237837,
                longitude: -77.1010759
            },
            {
                id: "537",
                FolderPath: "Tiendas Mass",
                name: "Martin27 CLL MS",
                address: "JR. SAN MARTIN AAHH 3 DE OCTUBRE DE VILLA MZ.27 LT 19 AAHH 3 DE OCTUBRE DE VILLA",
                reference: "PARQUE # 8 LOMAS DE ZAPALLAL/A 4 CUADRAS DE LA CALLE MARCO ESPINOZA CON CALLE 37",
                district: "Chorrillos",
                latitude: -12.1993354,
                longitude: -76.9792541
            },
            {
                id: "538",
                FolderPath: "Tiendas Mass",
                name: "Villa3 SMP MS",
                address: "AVENIDA VILLA VERDE -> (340910) NRO. 309 MZ J. LT. 43-44 CDRA 3 LADO IZQ, LUGAR: URB. SAN AMADEO ==> (0290) SECTOR 34, U.I N° 01",
                reference: "A 3 CASAS DEL MERCADO SANTA ISABEL EN CHORRILLOS/AV. SAN MARTIN CON AV. VISTA ALEGRE",
                district: "San Martín de Porres",
                latitude: -12.0139556,
                longitude: -77.0735846
            },
            {
                id: "539",
                FolderPath: "Tiendas Mass",
                name: "Defensor1SJM MS",
                address: "AV. DEFENSORES LIMA (EX - AGUSTIN LA ROSA LOZANO Y TIRAD NUM. 1224 MZ. F4 LT. 4 - 4A PP.JJ",
                reference: "ENTRE JR. ELOY REATEGUI Y ELEUTERIO VENTURA/A 2 CDRAS DEL CRUCE AV. TOMAS VALLE CON AV. JUVENAL VILLAVERDE",
                district: "San Juan de Miraflores",
                latitude: -12.1585322,
                longitude: -76.9578368
            },
            {
                id: "540",
                FolderPath: "Tiendas Mass",
                name: "CulturaO1 SA MS",
                address: "AV.LA CULTURA MZ. O LT. 01 COOPERATIVA VIÑA SAN FRANCISCO",
                reference: "AL FRENTE DEL BCP/ENTRE AV. SALVADOR ALLENDE Y CALLE BOLOGNESI",
                district: "Santa Anita",
                latitude: -12.0298695,
                longitude: -76.9509033
            },
            {
                id: "541",
                FolderPath: "Tiendas Mass",
                name: "Paraiso1U Vmt ",
                address: "A.A H.H EL PARAISO AV. EL PARAISO N°192 MZ.U LT.2",
                reference: "A 120 METROS DEL MERCADO VIÑA DE ISRAEL/A 100 METRO DEL CRUCE CON AV. FERROCARRIL",
                district: "Villa María del Triunfo",
                latitude: -12.1473296,
                longitude: -76.9350019
            },
            {
                id: "542",
                FolderPath: "Tiendas Mass",
                name: "MigueG14 SJL MS",
                address: "SV MZ.G LT.14 UPCV-SAN MIGUEL DE PALLACHACRA LTDA 50 LIM-SAN JUAN DE LURIGANCHO",
                reference: "FRENTE AL MERCADO \"EL PARAISO\"",
                district: "San Juan de Lurigancho",
                latitude: -12.0194581,
                longitude: -76.9612343
            },
            {
                id: "543",
                FolderPath: "Tiendas Mass",
                name: "Romero9 SJM MS",
                address: "JR BERNARDINO ROMERO NUM 966 - 968 ZONA D URB. SAN JUAN",
                reference: "A 100 METROS DEL CENTRO COMERCIAL CALLE 8 CAMPOY/CALLE 8 Y CALLE S/N",
                district: "San Juan de Miraflores",
                latitude: -12.1599922,
                longitude: -76.9593357
            },
            {
                id: "544",
                FolderPath: "Tiendas Mass",
                name: "Jose7 VMT MS",
                address: "ZONA VILLA MARIA DEL TRIUNFO - SECTOR: PRIMER HOGAR POLICIAL ACU- AV. SAN JOSE 706 MZ.G7 SUBLOTE 1A",
                reference: "CERCA AL HOSPITAL MARIA AUXILIADORA/ENTRE CALLE BERNARDINO CON AV. LOS HEROES",
                district: "Villa María del Triunfo",
                latitude: -12.1620217,
                longitude: -76.9460262
            },
            {
                id: "545",
                FolderPath: "Tiendas Mass",
                name: "Samoa320 LM MS",
                address: "JR.SAMOA 320-A MZ 3H1 LT.23 URB. SOL DE LA MOLINA 3ERA ETAPA",
                reference: "A 1 PREDIO DEL MERCADO PRINCIPAL VILLA MARIA DEL TRIUNFO/AV. SAN JOSE CON AV. VILLA MARIA",
                district: "La Molina",
                latitude: -12.0834091,
                longitude: -76.8977932
            },
            {
                id: "546",
                FolderPath: "Tiendas Mass",
                name: "PucusanaD13 MS",
                address: "ZONA 01- A.H. NUEVO PUCUSANA AV. NUEVO PUCUSANA MZ D LT 13",
                reference: "A 200 METROS DEL CASINIO DE LA POLICIA/A 200 METROS DEL CRUCE DE JR. SAMOA CON AV. LA MOLINA ESTE",
                district: "Pucusana",
                latitude: -12.4672117,
                longitude: -76.7538767
            },
            {
                id: "547",
                FolderPath: "Tiendas Mass",
                name: "Rosales 1 SJM MS ",
                address: "MZ.K1 LT.34 AA.HH LAS FLORES DE VILLA (EX SUSANA HIGUCHI) PANAMERICANA SUR",
                reference: "FRENTE AL MERCADO PUCUSANA/ 1DRA GRIFO PRIMAX/A MEDIA CDRA. PANAMERICANA SUR",
                district: "San Juan de Miraflores",
                latitude: -12.1908741,
                longitude: -76.9752397
            },
            {
                id: "548",
                FolderPath: "Tiendas Mass",
                name: "Lomas60 AN MS",
                address: "ZONA 4 OTROS ASOC. POP. LAS LOMAS DE ANCON MZ 60 LT 09",
                reference: "FRENTE AL MERCADO \"VILLA FLORES\"/ENTRE CALLE ROSALES CON CALLE A1",
                district: "Ancón",
                latitude: -11.7368384,
                longitude: -77.1435596
            },
            {
                id: "549",
                FolderPath: "Tiendas Mass",
                name: "AlamedaH3CLL MS",
                address: "AV. ALAMEDA SUR URBANIZACION LOS CEDROS DE VILLA MZ.H 03 LT.28",
                reference: "A 7 CDRAS DE LA PARROQUIA SAGRADA FAMILIA/A DOS CDRAS. AV. 11 DE ENERO Y CALLE JUNIN",
                district: "Chorrillos",
                latitude: -12.1981964,
                longitude: -77.0118247
            },
            {
                id: "550",
                FolderPath: "Tiendas Mass",
                name: "HorizonA1SMP MS",
                address: "EL HORIZONTE DE NARANJAL -2DA. ETAPA MZ. A LT.1 -PARCELA 29 EX FDO NARANJAL/P 4398-PROG.VIV.EL HORIZONTEDE NARANJAL-2DA ETAPA",
                reference: "AL COSTADO DE METRO DE ALAMEDA SUR/ENTRE AV. ALAMEDA SUR CON AV. ALAMEDA SAN LORENZO",
                district: "San Martín de Porres",
                latitude: -11.972119,
                longitude: -77.1021937
            },
            {
                id: "551",
                FolderPath: "Tiendas Mass",
                name: "ChavinC24S Mp",
                address: "RESIDENCIAL LOS PORTALES DE CHAVIN MZ C LT.24 PROG. LOS PORTALES DE CHAVIN",
                reference: "A MEDIA CDRA. MERCADO HORIZONTE /AV.PACASMAYO CRUCE CON CALLE EL OLIVAR",
                district: "San Martín de Porres",
                latitude: -11.9720274,
                longitude: -77.0943191
            },
            {
                id: "552",
                FolderPath: "Tiendas Mass",
                name: "ManzanG19SMP MS",
                address: "MANZANARESMZ G LT 19 ASOC. MANZANARES SMP",
                reference: "A UNA CDRA. PARROQUIA INMACULADA CONCEPCION",
                district: "San Martín de Porres",
                latitude: -11.9682596,
                longitude: -77.0973055
            },
            {
                id: "553",
                FolderPath: "Tiendas Mass",
                name: "PALMERAZ7 LO MS",
                address: "AV. PALMERAS, LAS MZ Z LOTE 7 URB. MICAELA BASTIDADAS,SECTOR 1  I ETAPA LOS OLIVOS",
                reference: "FRENTE GRIFO OLIMPO/CRUCE AV. SOL DE NARANJAL Y AV. PACASMAYO",
                district: "Los Olivos",
                latitude: -11.9866116,
                longitude: -77.0725816
            },
            {
                id: "554",
                FolderPath: "Tiendas Mass",
                name: "Alfonso 1 AQP MS",
                address: "URB. LA LIBERTAD. CA. ALFONSO UGARTE, MZ. X. LT. 1-B. CERRO COLORADO.",
                reference: "A 2 CDRAS. DE LA  HUACA AZNAPUQUIO/A 3 CDRAS. DE LA AV. ALISOS",
                district: "Cerro Colorado",
                latitude: -16.37991,
                longitude: -71.55907
            },
            {
                id: "555",
                FolderPath: "Tiendas Mass",
                name: "Guardia 4 AQP MS",
                address: "URB. GUARDIA CIVIL, AV. GUARDIA CIVIL N° 350. ZN 3. MZ G. LOTE 04. PAUCARPATA",
                reference: "A UNA CUADRA DE LA COMISARÍA DE CERRO COLORADO/CRUCE DE CALLE ALFONSO UGARTE Y AV. JOSÉ OLAYA",
                district: "Paucarpata",
                latitude: -16.42401,
                longitude: -71.51109
            },
            {
                id: "556",
                FolderPath: "Tiendas Mass",
                name: "Pierola 1 AQP MS",
                address: "URB. SAN JUAN MZ A, LOTE 9 Y 10. CERRO COLORADO",
                reference: "FRENTE A POLLERÍA MR PEPES/A MEDIA CUADRA DEL CRUCE DE AV. GUARDIA CIVIL Y CALLE RUA LOS ÁLAMOS",
                district: "Cerro Colorado",
                latitude: -16.392145,
                longitude: -71.5643189
            },
            {
                id: "557",
                FolderPath: "Tiendas Mass",
                name: "Progres 6 AQP MS",
                address: "AV. PROGRESO 645. MIRAFLORES",
                reference: "FRENTE AL INNOVA SCHOOLS DE VALLE BLANCO/A MEDIA CUADRA DEL CRUCE DE PIEROLA Y GARCILASO DE LA VEGA",
                district: "Miraflores",
                latitude: -16.393637,
                longitude: -71.522253
            },
            {
                id: "558",
                FolderPath: "Tiendas Mass",
                name: "Merced 1 AQP MS",
                address: "CALLE LA MERCED 113. AREQUIPA",
                reference: "FRENTE A PARQUE EL PROGRESO/A MEDIA CUADRA DEL CRUCE DE AV. PROGRESO Y AV. PRO HOGAR",
                district: "Cercado de Lima",
                latitude: -16.399604,
                longitude: -71.537793
            },
            {
                id: "559",
                FolderPath: "Tiendas Mass",
                name: "Dolores 2 AQP MS",
                address: "AV. DOLORES 183-A. 1ER PISO.",
                reference: "A MEDIA CUADRA DE LA PLAZA DE ARMAS DE AREQUIPA/A MEDIA CUADRA DE LA CALLE BOLOGNESI",
                district: "Jose Luis Bustamante Y Rivero",
                latitude: -16.43114,
                longitude: -71.523219
            },
            {
                id: "560",
                FolderPath: "Tiendas Mass",
                name: "Andes 5 AQP MS",
                address: "AV. LOS ANDES (A.S.A.) 500. SELVA ALEGRE",
                reference: "A UNA CUADRA DE LA IGLESIA DE JESUCRISTO DE LOS SANTOS DE LOS ÚLTIMOS DÍAS /A MEDIA CUADRA DEL CRUCE CON CALLE SANTA MARIA II",
                district: "Alto Selva Alegre",
                latitude: -16.380655,
                longitude: -71.51916
            },
            {
                id: "561",
                FolderPath: "Tiendas Mass",
                name: "Socabay 4 AQP MS",
                address: "AV. SOCABAYA 407. URB. SAN MARTIN DE SOCABAYA. SOCABAYA",
                reference: "A MEDIA CUADRA DE LA COMISARÍA DE ALTO SELVA ALEGRE/CRUCE DE CALLE ESPAÑA CON CALLE LOS ANDES",
                district: "Socabaya",
                latitude: -16.440147,
                longitude: -71.529311
            },
            {
                id: "562",
                FolderPath: "Tiendas Mass",
                name: "Salave 1 AQP MS",
                address: "AVENIDA QUIROZ. REF. ESQUINA CON SALAVERRY 106-108. AREQUIPA.",
                reference: "FRENTE AL MERCADO SAN MARTÍN DE SOCABAYA/CRUCE CON CALLE CONTAMANA",
                district: "Cercado de Lima",
                latitude: -16.407641,
                longitude: -71.538814
            },
            {
                id: "563",
                FolderPath: "Tiendas Mass",
                name: "Umachiri 1 AQP MS",
                address: "CALLE AMAZONAS N° 302-308. MARIANO MELGAR.",
                reference: "FRENTE AL MI FARMA DE AV. SALAVERRY CON CALLE QUIROZ/CRUCE DE AV. SALAVERRY CON CALLE QUIROZ",
                district: "Mariano Melgar",
                latitude: -16.400429,
                longitude: -71.505875
            },
            {
                id: "564",
                FolderPath: "Tiendas Mass",
                name: "Dolores 1 AQP MS",
                address: "AV. DOLORES 119 SECCIÓN 6 - JLBR",
                reference: "FRENTE AL ÓVALO UMACHIRI/CRUCE DE CALLE AMAZONAS CON AV. PERÚ",
                district: "Jose Luis Bustamante Y Rivero",
                latitude: -16.419167,
                longitude: -71.526189
            },
            {
                id: "565",
                FolderPath: "Tiendas Mass",
                name: "Maran 3 AQP MS",
                address: "CALLE MARAÑON 322 - ZAMACOLA - CERRO COLORADO",
                reference: "A MEDIA CUADRA DEL RESTAURANTE TRADICIÓN AREQUIPEÑA/CRUCE CON CALLE LOS NARANJOS",
                district: "Cerro Colorado",
                latitude: -16.353226,
                longitude: -71.56461
            },
            {
                id: "566",
                FolderPath: "Tiendas Mass",
                name: "Lambra 1 AQP MS",
                address: "RESIDENCIAL KILLARI I ETAPA MZ. VIV. LOTE COM 1 - JLBYR",
                reference: "A DOS CUADRAS DEL MERCADO DE ZAMÁCOLA/A DOS CUADRAS DEL CRUCE CON AV. AVIACIÓN",
                district: "Jose Luis Bustamante Y Rivero",
                latitude: -16.416534,
                longitude: -71.518827
            },
            {
                id: "567",
                FolderPath: "Tiendas Mass",
                name: "Jesus 22 AQP MS",
                address: "AV. JESUS 2203 MZ. B LT. 13 URB. VILLA PORONGOCHE PAUCARPATA",
                reference: "A 2 CUADRAS DE FRANCO DE AV. LAMBRAMANI-A 1 CUADRA DEL CRUCE CON CALLE ARENALES",
                district: "Paucarpata",
                latitude: -16.419947,
                longitude: -71.501052
            },
            {
                id: "568",
                FolderPath: "Tiendas Mass",
                name: "Arequi 6 AQP MS",
                address: "AV. AREQUIPA 623 - ALTO SELVA ALEGRE",
                reference: "A MEDIA CUADRA DE LA AV. PORONGOHE-FRENTE AL CRUCE CALLE LOS GERANIOS Y AV. PORONGOCHE",
                district: "Alto Selva Alegre",
                latitude: -16.385964,
                longitude: -71.522101
            },
            {
                id: "569",
                FolderPath: "Tiendas Mass",
                name: "Castilla 9 AQP MS",
                address: "AV. RAMÓN CASTILLA 864 - LA TOMILLA - CAYMA",
                reference: "A MEDIA DE LA IGLESIA PARROQUIA DEL ESPIRITU SANTO DE ASA/A MEDIA CUADRA DEL CRUCE CON PASAJE LAS ROSAS",
                district: "Cayma",
                latitude: -16.35951,
                longitude: -71.54376
            },
            {
                id: "570",
                FolderPath: "Tiendas Mass",
                name: "Derecha 3 HUA MS",
                address: "CALLE DERECHA INT 354 - HUARAL",
                reference: "A MEDIA CUADRA DE LA PLAZA LA TOMILLA-A MEDIA CUADRA DEL CRUCE CON CALLE REFORMA NACIONAL",
                district: "Huaral",
                latitude: -11.495691,
                longitude: -77.206953
            },
            {
                id: "571",
                FolderPath: "Tiendas Mass",
                name: "Alamos HUAU MS",
                address: "CALLE JOSÉ CARLOS MARIATEGUI S/N HUAURA",
                reference: "A DOS COLINDANTES DEL BBVA/LOCAL UBICADO EN LA PLAZA DE ARMAS",
                district: "Huaura",
                latitude: -11.069689,
                longitude: -77.598249
            },
            {
                id: "572",
                FolderPath: "Tiendas Mass",
                name: "Bologne BARR MS",
                address: "CALLE BOLOGNESI 110 LT. 01 - BARRANCA - BARRANCA",
                reference: "COLINDANTE A TEMPLO DE LOS MORMONES/A MEDIA CUADRA DE LA PLAZA DE ARMAS",
                district: "Barranca",
                latitude: -10.754031,
                longitude: -77.760344
            },
            {
                id: "573",
                FolderPath: "Tiendas Mass",
                name: "TupaC3 HUAC MS",
                address: "AV. TUPAC AMARU 504 - ESQUINA CALLE LA PAZ - SANTA MARÍA",
                reference: "A POCOS METROS DE LA PLAZA DE ARMAS-CALLE BOLOGNESI CRUCE CON JR. AREQUIPA",
                district: "Santa María",
                latitude: -11.10754,
                longitude: -77.59865
            },
            {
                id: "574",
                FolderPath: "Tiendas Mass",
                name: "Rodri 14 AQP MS",
                address: "CALLE TENENTE RODRIGUEZ 1415 MIRAFLORES",
                reference: "FRENTE A LAS OFICINAS DEL GOBIERNO REGIONAL-AV. TUPAC AMARU CRUCE CON AV. LA PAZ - SANTA MARÍA-HUACHO   ",
                district: "Miraflores",
                latitude: -16.39135,
                longitude: -71.51349
            },
            {
                id: "575",
                FolderPath: "Tiendas Mass",
                name: "JC Tello HUA MS",
                address: "AV. CHANCAY 123 - CERCADO-HUARAL    ",
                reference: "A 3 CUADRAS DEL CEMENTERIO PARQUE DE LA ESPERANZA",
                district: "Huaral",
                latitude: -11.49643,
                longitude: -77.20922
            },
            {
                id: "576",
                FolderPath: "Tiendas Mass",
                name: "Chavez4 AQP MS",
                address: "AV. JORGE CHAVEZ 407 CERCADO",
                reference: "FRENTE A LA PLAZA CENTENARIO-AV. CHANCAY ESQ. CON AV. LA ESTACIÓN -CERCADO HUARAL",
                district: "Arequipa",
                latitude: -16.40527,
                longitude: -71.5326
            },
            {
                id: "577",
                FolderPath: "Tiendas Mass",
                name: "Peru 3 AQP MS",
                address: "CALLE PERU 307 ALTO D LA LIBERTAD MZ. 12 ZONA A",
                reference: "FRENTE A LA REIDENCIAL EDIFICIOS DE NICOLÁS DE PIEROLA",
                district: "Cerro Colorado",
                latitude: -16.384465,
                longitude: -71.569848
            },
            {
                id: "578",
                FolderPath: "Tiendas Mass",
                name: "Vidal 4 SUP MS",
                address: "AV. FRANCISCO VIDAL 472 SUPE BARRANCA",
                reference: "A 3 CUADRAS DEL CEMENTERIO PARQUE DE LA ESPERANZA",
                district: "Supe",
                latitude: -10.79628141,
                longitude: -77.71653806
            },
            {
                id: "579",
                FolderPath: "Tiendas Mass",
                name: "Lima 11 Barr MS",
                address: "JR. LIMA 1174 BARRANCA",
                reference: "A MEDIA CUADRA DEL CRUCE CON JR. SAN MARTÍN/A MEDIA CUADRA DEL CRUCE CON JR. SAN MARTÍN",
                district: "Barranca",
                latitude: -10.747606,
                longitude: -77.762385
            },
            {
                id: "580",
                FolderPath: "Tiendas Mass",
                name: "Pizarro 1 AQP MS",
                address: "AV. PIZARRO 127 - SECCIÓN 6",
                reference: "FRENTE AL MERCADO FUENTE DE VIDA SEÑOR DE LOS MILAGROS",
                district: "Jose Luis Bustamante Y Rivero",
                latitude: -16.42331696,
                longitude: -71.51591467
            },
            {
                id: "581",
                FolderPath: "Tiendas Mass",
                name: "VidaUA 15 AQP MS",
                address: "AV. VIDAURRAZAGA URB. CAMPIÑA DORADA A-15 CERCADO",
                reference: "FRENTE AL MERCADO TOTAL DE AV. PIZARRO/A MEDIA CUADRA DEL CRUCE CON CALLE ALBERTO LAVEAU",
                district: "Arequipa",
                latitude: -16.41856417,
                longitude: -71.53906761
            },
            {
                id: "582",
                FolderPath: "Tiendas Mass",
                name: "Tacna 3 NA MS",
                address: "CALLE TACNA N° ALT 354 MZ. 348 CERCADO DE NAZCA",
                reference: "A MEDIA CUADRA DEL GRIFO LOS INCAS/A 2 CUADRAS DEL CRUCE DE AV. VIDAURRAZAGA Y AV. LOS INCAS",
                district: "Nasca",
                latitude: -14.82729412,
                longitude: -74.93656217
            },
            {
                id: "583",
                FolderPath: "Tiendas Mass",
                name: "Bolog11 AQP MS",
                address: "CALLE PROGRESO (69) NUM 1100 MZ J LT 1 ASENTAMIENTO HUMANO FRANCISCO BOLOGNESI ZONA B CAYMA AREQUIPA ",
                reference: "FRENTE A LA PLAZA DE ARMAS DE NAZCA_/A MEDIA CUADRA DEL CRUCE DE CALLE TACNA CON CALLE CALLAO",
                district: "Cayma",
                latitude: -16.34563166,
                longitude: -71.5414812
            },
            {
                id: "584",
                FolderPath: "Tiendas Mass",
                name: "PalacG19 AQP MS",
                address: "URBANIZACIÓN EL PALACIO - II ETAPA CALLE N° 4 MZ G LT 19 - SACHACA - AREQUIPA",
                reference: "FRENTE AL ESTADO BOLOGNESI DE CAYMA /CRUCE DE AV. BOLOGNESI CON CALLE PROGRESO",
                district: "Sachaca",
                latitude: -16.429999,
                longitude: -71.568477
            },
            {
                id: "585",
                FolderPath: "Tiendas Mass",
                name: "Argenti 9 AQP MS",
                address: "AV. ARGENTINA 926 - MARIANO MELGAR",
                reference: "A 1 CUADRA DEL PALACIO DE GOYONECHE/A 1 CUADRA DEL CRUCE CON CALLE FERNANDINI",
                district: "Mariano Melgar",
                latitude: -16.404944,
                longitude: -71.57630849
            },
            {
                id: "586",
                FolderPath: "Tiendas Mass",
                name: "Progres 2 AQP MS",
                address: "AV. PROGRESO 208 HURANGILLO",
                reference: "FRENTE AL PARQUE ULRICH NEISSER/AV. ARGENTINA Y CALLE HUACAR",
                district: "Sachaca",
                latitude: -16.41313707,
                longitude: -71.5763084
            },
            {
                id: "587",
                FolderPath: "Tiendas Mass",
                name: "Belaun 3 AQP MS",
                address: "URB. PRIMAVERA MZ. A LOTE 27 - AV. VICTOR BELAUNDE NRO. 331",
                reference: "FRENTE A LA RESIDENCIAL HUARANGUILLO A 1 CUADRA DEL CRUCE CON CALLE SALAVERRY",
                district: "Yanahuara",
                latitude: -16.40009347,
                longitude: -71.55014475
            },
            {
                id: "588",
                FolderPath: "Tiendas Mass",
                name: "GloriaB4 AQP MS",
                address: "URB. VILLA GLORIA MZ B LOTE 4 VILLA GLORIA - CERCADO - AREQUIPA",
                reference: "CERCA A BODEGAS Y RESTAURANTES/ CERCA AL CRUCE AV. METROPOLITANA Y AV. BELAUNDE",
                district: "Arequipa",
                latitude: -16.41111,
                longitude: -71.53203
            },
            {
                id: "589",
                FolderPath: "Tiendas Mass",
                name: "Apariu9 HUA MS",
                address: "URB. APARACIO MZ U LOTE 9  HUARAL",
                reference: "FRENTE A LA RESIDENCIAL FRANCISCO MOSTAJO/A 1 CUADRA DEL CRUCE DE AV. FRANCISCO MOSTAJO CON AV. VENEZUELA",
                district: "Huaral",
                latitude: -11.4933886,
                longitude: -77.200446
            },
            {
                id: "590",
                FolderPath: "Tiendas Mass",
                name: "AlegreP 14 NA MS",
                address: "CENTRO POBLADO RURAL URB. VISTA ALEGRE MZ P LOTE 14    ",
                reference: " FRENTE AL MERCADO MORA PARRA HUARAL/A UNA CDRA DEL CRUCE AV. NATURALES CON AV. GARCIA ALONSO",
                district: "Nasca",
                latitude: -14.8455794,
                longitude: -74.9467518
            },
            {
                id: "591",
                FolderPath: "Tiendas Mass",
                name: "Characas 1 AQP MS",
                address: "PUEBLO TRADICIONAL DE CHARACATO MZ S LT 1 - LA PLAZA - CHARACATO  ",
                reference: "A 1 CUADRA DEL MERCADO VISTA ALEGRE",
                district: "Characato",
                latitude: -16.4683331,
                longitude: -71.4840829
            },
            {
                id: "592",
                FolderPath: "Tiendas Mass",
                name: "Ferre2 AQP MS",
                address: "AV. TENIENTE FERRE 213 MZ K LOTE 13 - MIRAFLORES",
                reference: "FRENTE A LA PLAZA DE ARMAS DE CHARACATO",
                district: "Miraflores",
                latitude: -16.4002323,
                longitude: -71.5161615
            },
            {
                id: "593",
                FolderPath: "Tiendas Mass",
                name: "CerroC9 AQP MS",
                address: "URB. EDUARDO DE LA PINIELLA MZ C LT 09 - CERRO COLORADO - AQP",
                reference: "A UNA CUADRA DE LA FERIA DEL ALTIPLANO/A MEDIA CUADRA DEL CRUCE DE AV. TENIENTE FERRE Y AV. SEPÚLVEDA",
                district: "Cerro Colorado",
                latitude: -16.326669,
                longitude: -71.5953767
            },
            {
                id: "594",
                FolderPath: "Tiendas Mass",
                name: "Vidau A3 AQP MS",
                address: "LOTIZACIÓN VIZA CALA MZ. A LT. 3 - JLBR - AREQUIPA",
                reference: "FRENTE AL GRIFO MUNICIPAL CONO NORTE/FRENTE AL CRUCE DE CARRETERA A YURA Y AV. 54",
                district: "Jose Luis Bustamante Y Rivero",
                latitude: -16.4277677,
                longitude: -71.5385062
            },
            {
                id: "595",
                FolderPath: "Tiendas Mass",
                name: "Paz 2 Aqp",
                address: "P. TRAD. DE LA JOYA AV. PRUDENCIO VIDAL PAZ SOLDAN LT 204 - LA JOYA - AQP",
                reference: "FRENTE A LA PUERTA DEL MERCADO \"MI MERCADO\"/A UNA CUADRA DEL CRUCE DE AV. DE LAS CONVERCIONES Y AV. VIDAURRAZAGA",
                district: "La Joya",
                latitude: -16.424182,
                longitude: -71.8186905
            },
            {
                id: "596",
                FolderPath: "Tiendas Mass",
                name: "Leon3 AQP MS",
                address: "LEON VELARDE 307 YANAHUARA, AREQUIPA",
                reference: "A 2 CUADRAS DE LA PLAZA DE ARMAS DE LA JOYA",
                district: "Yanahuara",
                latitude: -16.3842194,
                longitude: -71.5394807
            },
            {
                id: "597",
                FolderPath: "Tiendas Mass",
                name: "Martin 1 AQP MS",
                address: "CALLE SAN MARTIN SN MZ L LT 14 EL PUEBLO DE SOCABAYA AREQUIPA",
                reference: "8 CUADRAS ATRÁS DE LA PLAZA CAYMA/ENTRE LOS CRUCES DE LEON VELARDE CON TACNA Y MISTI",
                district: "Socabaya",
                latitude: -16.4673602,
                longitude: -71.5284615
            },
            {
                id: "598",
                FolderPath: "Tiendas Mass",
                name: "Valdivia7 AQP MS ",
                address: "C. POBLADO CENTRO POBLADO COCACHACRA CALLE DEAN VALDIVIA 731 MZ F2 LT 09",
                reference: "AL COSTADO DE LA MUNICIPALIDAD DE SOCABAYA/FRENTE A LA PLAZA DE ARMAS DE SOCABAYA",
                district: "Arequipa",
                latitude: -17.0910385,
                longitude: -71.7634781
            },
            {
                id: "599",
                FolderPath: "Tiendas Mass",
                name: "Zuñiga384CHA MS",
                address: "CLL. LOPEZ DE ZUÑIGA Nº. 0384 - 0386 - CHANCAY",
                reference: "A UNA CUADRA DE AV. LIBERTAD",
                district: "Chancay",
                latitude: -11.5656602,
                longitude: -77.2690021
            },
            {
                id: "600",
                FolderPath: "Tiendas Mass",
                name: "Lima5 AQP MS",
                address: "AV. LIMA 505 LOTE 4A P. T. URB. MARIANO MELGAR MZ J LOTE 4A - MARIANO MELGAR",
                reference: "A UNA CUADRA DE LA PLAZA DE ARMAS DE CHANCAY/A MEDIA CUADRA DEL CRUCE DE CALLE ALBERTO DE LAS CASAS Y JR. ZUÑIGA",
                district: "Mariano Melgar",
                latitude: -16.4028684,
                longitude: -71.5115913
            },
            {
                id: "601",
                FolderPath: "Tiendas Mass",
                name: "Pedro T1 SJL MS",
                address: "MZ A T-2 MZ T-1 LT 14A - APV SAN PEDRO, CAMPOY - SJL",
                reference: "A UNA CUADRA DEL CENTRO DE SALUD DE MARIANO MELGAR/A MEDIA CUADRA DEL CRUCE DE CALLE ANCASH Y AV. LIMA",
                district: "San Juan de Lurigancho",
                latitude: -12.01879,
                longitude: -76.96123
            }
        ];
    }
}
