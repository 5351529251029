import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appValidate]'
})
export class InputValidateDirective {
    constructor(private el: ElementRef) {
    }
    @HostListener('keyup', ['$event'])
    onkeyup(event: KeyboardEvent) {
        const _parent = <HTMLElement>this.el.nativeElement;
        const id = _parent.getAttribute('data-id');
        const field =  <HTMLElement>document.querySelector('#' + id);

    }
}
