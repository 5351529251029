import { Component, Renderer2, OnInit } from '@angular/core';
import { AppSplashScreenService } from '@core/services/splash-screen.service';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LoaderService } from '@core/services/loader.services';

declare var ga: Function;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(private appSplashScreen: AppSplashScreenService,
        private router: Router, private renderer: Renderer2, private loader: LoaderService,
    ) {


        this._init();

    }
    ngOnInit(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.getCurrentUrlPath(event);
            }
            if (event instanceof NavigationEnd) {
                if (event instanceof NavigationEnd) {
                    // ga('set', 'page', event.urlAfterRedirects);
                    // ga('send', 'pageview');
                }
            }
        });
    }
    getCurrentUrlPath(event) {
        const urlDelimitators = new RegExp(/[?//,;&:#$+=]/);
        const currentUrlPath = event.url.slice(1).split(urlDelimitators)[0];
        if (currentUrlPath === 'admin') {
            this.renderer.addClass(document.body, 'admin');
        } else {
            this.renderer.removeClass(document.body, 'admin');
        }
    }

    private _init(): void {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationStart))
            .subscribe(() => {
                this.loader.show();
            });

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd || event instanceof NavigationError || event instanceof NavigationCancel))
            .subscribe(() => {
                this.loader.hide();
            });
    }
}
