import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { HttpModule } from '@angular/http';
import { Ng2Webstorage } from 'ngx-webstorage';
import 'hammerjs';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppMainModule } from './main/main.module';
import { AppSplashScreenService } from '@core/services/splash-screen.service';
import { AppConfigService } from '@core/services/config.service';
import { AuthInterceptor } from './services/auth/auth.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { HttpErrorHandler } from '@core/services/http-error-handler.service';
import { MessageService } from '@core/services/message.service';
import { LayoutService } from '@core/services/layout.service';
import { ApiService } from 'app/services/api/api.service';
import localeEs from '@angular/common/locales/es';
import { AuthService } from './services/auth/auth.service';

registerLocaleData(localeEs);


const appRoutes: Routes = [


    {
        path: 'local',
        loadChildren: './main/content/pages/local/page-local.module#PageLocalModule'
    },
    {
        path: 'auth/forgot-password',
        loadChildren: './main/content/pages/authentication/forgot-password/page-forgot-password.module#PageForgotPasswordModule'
    },
    {
        path: 'auth/login',
        loadChildren: './main/content/pages/authentication/login/page-login.module#PageLoginModule'
    },
    {
        path: 'auth/register',
        loadChildren: './main/content/pages/authentication/register/page-register.module#PageRegisterModule'
    },
    {
        path: 'auth/forgot-password-reset',
        loadChildren: './main/content/pages/authentication/forgot-reset/page-reset.module#PageForgotResetModule'
    },
    {
        path: 'admin',
        loadChildren: './main/content/admin/admin.module#AdminModule'
    },
    {
        path: '**',
        redirectTo: 'auth/login',
        pathMatch:'full'
    }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        HttpModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, { useHash: false, preloadingStrategy: PreloadAllModules }),
        AppMainModule,
        Ng2Webstorage,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-center',
            extendedTimeOut: 1000
        }),
    ],
    providers: [
        AppSplashScreenService,
        HttpErrorHandler,
        MessageService,
        LayoutService,
        AppConfigService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        AuthService,
        ApiService,
        { provide: LOCALE_ID, useValue: 'es' }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
