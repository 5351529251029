import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector   : 'app-alert-dialog',
    templateUrl: './alert-dialog.component.html',
    styleUrls  : ['./alert-dialog.component.scss']
})
export class AppAlertDialogComponent implements OnInit
{
    public alertMessage: string;

    constructor(public dialogRef: MatDialogRef<AppAlertDialogComponent>)
    {
    }

    ngOnInit()
    {
    }

}
