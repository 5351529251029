import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  isAdminMenuOpened = new BehaviorSubject<boolean>(false);


  isScrollFaq = new BehaviorSubject<boolean>(false);

  constructor() { }

  setVisibility(status: boolean) {
    this.isAdminMenuOpened.next(status);
  }


  setScrollFaq(status: boolean) {
    this.isScrollFaq.next(status);
  }






}
