import { NgModule } from '@angular/core';
import { NumberOnlyDirective } from './only-number.directive';
import { AppPerfectScrollbarDirective } from './app-perfect-scrollbar/app-perfect-scrollbar.directive';
import { AppIfOnDomDirective } from './app-if-on-dom/app-if-on-dom.directive';
import { InputValidateDirective } from './input-validate.directive';


@NgModule({
    declarations: [
        NumberOnlyDirective,
        InputValidateDirective,
        AppPerfectScrollbarDirective,
        AppIfOnDomDirective

    ],
    imports     : [],
    exports     : [
        NumberOnlyDirective,
        InputValidateDirective,
        AppPerfectScrollbarDirective,
        AppIfOnDomDirective
    ]
})
export class AppDirectivesModule
{
}
