export const VARIABLES = {
    MESSAGES: {
        PHOTOS: 'Las fotos pueden pesar máximo hasta 5MB',
        FILES: 'Los archivos pueden pesar máximo hasta 5MB'
    }
};

export function getUrlApi(): string {
    const hostName = document.location.hostname;

    let apiURL = '';
    /**
     * PRD
     */
    if ( (hostName === 'ofrecetulocal.tiendasmass.com.pe') || (hostName === 'www.ofrecetulocal.tiendasmass.com.pe') ) {
        apiURL = 'https://mass-api.tiendasmass.com.pe/api/v1';
    }
    /**
     * QA
     */
    else {
        apiURL = 'https://mass-api.iz.pe/api/v1';
    }

    // apiURL = 'https://mass-api.patternqa.com/api/v1';
    //apiURL = 'http://tiendasmass-api.test/api/v1';

    return apiURL;
}

export const MIN_LOCAL_AREA = 180;