import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AppNavigationService
{
    public menuOpened: boolean;
    private _visible: BehaviorSubject<boolean>;
    constructor(private _router: Router)
    {
        this._init();
    }

    get visible(): Observable<any>
    {
        return this._visible.asObservable();
    }

    private _init(): void
    {
        this._visible = new BehaviorSubject(false);
    }

    onToggleMenu(): void
    {
        this.menuOpened = !this.menuOpened;
        this._visible.next(this.menuOpened);
    }
    hide(): void
    {
        this.menuOpened = false;
        this._visible.next(this.menuOpened);
    }

}
