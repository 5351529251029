import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@core/modules/shared.module';
import { AppMainComponent } from './main.component';
import { AppContentComponent } from './content/content.component';
import { LoaderService } from '@core/services/loader.services';
import { AppFooterComponent } from './layout/footer/footer.component';
import { AppNavBarHorizontalComponent } from './layout/navbar/horizontal/navbar-horizontal.component';
import { AppNavBarVerticalComponent } from './layout/navbar/vertical/navbar-vertical.component';
import { NavbarAdminComponent } from './layout/navbar/navbar-admin/navbar-admin.component';
import { AuthGuard } from 'app/services/auth/auth.guard';
import { AppMenuComponent } from './layout/navbar/menu/menu.component';
import { AppFormPrivacityComponent } from './content/pages/authentication/register/privacity/privacity.component';

@NgModule({
    declarations: [
        AppContentComponent,
        AppFooterComponent,
        AppMainComponent,
        AppNavBarHorizontalComponent,
        AppNavBarVerticalComponent,
        NavbarAdminComponent,
        AppMenuComponent,
        AppFormPrivacityComponent
    ],
    imports: [SharedModule, RouterModule],
    exports: [AppMainComponent],
    entryComponents: [AppFormPrivacityComponent],
    providers: [LoaderService, AuthGuard]
})
export class AppMainModule {}
