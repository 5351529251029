import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MapTypeStyle } from '@agm/core';
import { MapStyles } from '@core/store/agm-style';

@Component({
    selector   : 'app-map-dialog',
    templateUrl: './map-dialog.component.html',
    styleUrls  : ['./map-dialog.component.scss']
})
export class AppMapDialogComponent implements OnInit
{
    latitude: number;
    longitude: number;
    zoom = 17;
    icon = 'assets/images/mkr.png';
    styles: MapTypeStyle[] = MapStyles.getStyle();

    constructor(public dialogRef: MatDialogRef<AppMapDialogComponent>)
    {
    }

    ngOnInit()
    {
    }

}
