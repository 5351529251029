import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { ApiService } from '../api/api.service';
import { Http, Response, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
@Injectable()
export class AuthService {
    public currentUser: any;
    onUserChanged: BehaviorSubject<any> = new BehaviorSubject({});
    url: string;
    constructor(
        private router: Router,
        private _http: Http,
        private _storage: LocalStorageService,
        private _apiService: ApiService,
    ) {
        this.currentUser = this._storage.retrieve('currentUser') || '';
        if (this.currentUser) {
            const user = JSON.parse(this.currentUser);
            this.onUserChanged.next(user);
        } else {
            this.onUserChanged.next(false);
        }
        this.url = environment.api;
    }

    _login(data): Observable<any> {
        const httpOptions = {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        };
        const _data = `email=${data.email}&password=${data.password}&captcha=${data.captcha}`;
        return this._http
            .post(this._apiService.user.login(), _data, httpOptions)
            .pipe(map(res => res.json()));
    }



    public forgot_reset(data) {
        const _params = JSON.stringify(data);
        const httpOptions = {
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        };
        return this._http
            .post(this._apiService.user.reset_password(), _params, httpOptions)
            .pipe(map(res => res.json()))
            .catch((error: Response) => Observable.throw(error.json()));
    }

    public forgot_password(data) {
        const _params = JSON.stringify(data);
        const httpOptions = {
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        };
        return this._http
            .post(this._apiService.user.forgot_password(), _params, httpOptions)
            .pipe(map(res => res.json()))
            .catch((error: Response) => Observable.throw(error.json()));
    }

    public logout() { }

    public deleteStorage() {
        this._storage.clear();
    }

    public isAuthenticated(): boolean {
        const tokens = this.getToken();
        return !!tokens;
    }

    public collectFailedRequest(): void {
        this.logout();
    }

    public failedRequestCode(): void {
        this.deleteStorage();
        this.setCurrentRoute();
        this.onUserChanged.next(false);
        this.redirectTo('/auth/login');
    }

    public failedToken(): void {
        this.deleteStorage();
        this.onUserChanged.next(false);
    }

    public getCurrentRoute() {
        return this._storage.retrieve('currentUser') || '';
    }

    public setCurrentRoute() {
        this._storage.store('currentRoute', this.router.url);
    }

    public removeCurrentRoute() {
        this._storage.clear('currentRoute');
    }

    public getToken(): string {
        return this._storage.retrieve('token') || '';
    }
    redirectTo(uri: any) {
        // this.router.navigate([uri]);
        this.router
            .navigateByUrl('/auth/login', { skipLocationChange: true })
            .then(async () => {
                await window.location.reload();
                await this.router.navigate([uri]);
            });
    }
}
