import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '@core/services/config.service';
import { Subscription, Subject } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { AppNavigationService } from '../AppNavigationService';
import { takeUntil } from 'rxjs/operators';
@Component({
    selector   : 'app-navbar-horizontal',
    templateUrl: './navbar-horizontal.component.html',
    styleUrls  : ['./navbar-horizontal.component.scss']
})

export class AppNavBarHorizontalComponent implements OnInit,  OnDestroy
{

    onSettingsChanged: Subscription;
    active = false;
    box = false;
    help = false;
    header: { home: boolean; register: boolean; fixed: boolean };
    userName: string;
    activeAccount = false;
    private _unsubscribeAll: Subject<any>;



    constructor( private router: Router, private appConfig: AppConfigService,
        private _storage: LocalStorageService, private appNavigationService: AppNavigationService)
    {
        this._unsubscribeAll = new Subject();
        this.onSettingsChanged = this.appConfig.onSettingsChanged
        .subscribe(
            (settings) => {
                this.header =  settings.layout.header;
            }
        );
        if (this._storage.retrieve('currentuser')) {
            const currentuser = JSON.parse(this._storage.retrieve('currentuser'));
            this.userName = currentuser.name;
            this.activeAccount = true;
        }
    }
    open(){

        this.appNavigationService.onToggleMenu();

    }

    ngOnInit(): void {
        this.appNavigationService.visible
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((visible) => {
           this.active = visible;
        });
    }
    openBoxUser(){
        this.box = !this.box;
    }
    openBoxHelp(){
        this.help = !this.help;
    }

    close() {

        this._storage.clear('currentUser');
        this._storage.clear('token');
        this.activeAccount = false;
        this.redirectTo('/');


    }

    redirectTo(uri) {
        this.router.navigateByUrl('/auth/login', {skipLocationChange: true}).then(() =>
        this.router.navigate([uri]));
    }
    ngOnDestroy(){
       this.onSettingsChanged.unsubscribe();
       this._unsubscribeAll.next();
       this._unsubscribeAll.complete();
    }

}
