import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
export const statusCodes = {
    offline: 0,
    code: 200,
    no_content: 204,
    multiple_choices: 300,
    internal_server_error: 500,
    not_found: 404,
    not_authorized: 401,
    status: 'ok'
};

export interface Store {
    ubigeo: () => string;
    list: () => string;
    byDistrict: (id) => string;
}

export interface ApiLocal {
    lead: () => string;
    lead_pending: () => string;
    lead_completed: () => string;
    lead_signed: () => string;
    lead_discarded: () => string;
    addAttach: (id) => string;
    update: (id) => string;
    finish: (id) => string;
    delete_attach: (lead_id, id) => string;
    read: (id) => string;
    owner_lead: (id) => string;
    ubigeo: () => string;
    ubigeo_departments: () => string;
    ubigeo_provinces: (department_id) => string;
    ubigeo_districts: (department_id, province_id) => string;
    bank: () => string;
    status: (id) => string;
    plantsType: () => string;
}

export interface ApiUser {
    create: () => string;
    login: () => string;
    forgot_password: () => string;
    me: () => string;
    update: () => string;
    reset_password: () => string;
}

export interface Product {
    list: () => string;
}

export interface SearchZone {
    list: () => string;
}

@Injectable()
export class ApiService {
    url: string;
    constructor() {
        this.url = environment.api;
    }
    public local: ApiLocal = {
        lead: () => `${this.url}/lead`,
        lead_pending: () => `${this.url}/lead?status=PENDING`,
        lead_completed: () =>
            `${this.url}/lead?status=COMPLETED,ATTENDED,CONFIRMED,VISIT-LOCAL`,
        lead_signed: () => `${this.url}/lead?status=SIGNATURE-CONTRACT`,
        lead_discarded: () => `${this.url}/lead?status=GIVE-LOW,NOT-QUALIFY`,
        addAttach: id => `${this.url}/lead/${id}/attach`,
        update: id => `${this.url}/lead/${id}`,
        finish: id => `${this.url}/lead/${id}/finish`,
        delete_attach: (lead_id, id) =>
            `${this.url}/lead/${lead_id}/attach/${id}`,
        read: id => `${this.url}/lead/${id}`,
        owner_lead: id => `${this.url}/owner-lead/${id}`,
        ubigeo: () => `${this.url}/ubigeo/district?deparment=Lima`,
        ubigeo_departments: () => `${this.url}/ubigeo`,
        ubigeo_provinces: (department_id) => `${this.url}/ubigeo?provinces=1&department_id=${department_id}`,
        ubigeo_districts: (department_id, province_id) => `${this.url}/ubigeo?districts=1&department_id=${department_id}&province_id=${province_id}`,
        bank: () => `${this.url}/bank`,
        status: id => `${this.url}/opportunity/lead/${id}/change-status`,
        plantsType: () => `${this.url}/lead/plant-type`
    };
    public user: ApiUser = {
        create: () => `${this.url}/user`,
        login: () => `${this.url}/auth/login`,
        forgot_password: () => `${this.url}/auth/forgot`,
        me: () => `${this.url}/user/me`,
        update: () => `${this.url}/user`,
        reset_password: () => `${this.url}/auth/password/reset`
    };

    public product: Product = {
        list: () => `${this.url}/product/?pagination=false`
    };

    public store: Store = {
        ubigeo: () => `${this.url}/local/search`,
        list: () => `${this.url}/local?pagination=false`,
        byDistrict: id => `${this.url}/local/search?district_id=${id}`
    };

    public searchZone: SearchZone = {
        list:() => `${this.url}/searchzone?pagination=false`
    };
}
