import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppConfigService } from '@core/services/config.service';
import { GeneralData } from '@core/store/general';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { MatDialog } from '@angular/material';
import { AppFormPrivacityComponent } from 'app/main/content/pages/authentication/register/privacity/privacity.component';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class AppFooterComponent implements OnInit, OnDestroy {
    dialogRef: any;
    onSettingsChanged: Subscription;
    menus$ = GeneralData.getMenuFooter();
    logos$ = GeneralData.getLogosFooter();
    footer: { home: boolean; register: boolean; fixed: boolean };
    active = true;
    constructor(
        private appConfig: AppConfigService,
        private _storage: LocalStorageService,
        private _matDialog: MatDialog
    ) {
        this.onSettingsChanged = this.appConfig.onSettingsChanged.subscribe(
            settings => {
                this.footer = settings.layout.footer;
            }
        );
    }

    ngOnInit() {
        if (this._storage.retrieve('wa-text')) {
            this.active = false;
        }
    }
    ngOnDestroy() {
        this.onSettingsChanged.unsubscribe();
    }

    close() {
        if (!this._storage.retrieve('wa-text')) {
            this.active = false;
            this._storage.store('wa-text', true);
        }
    }
    privacity(type): void {
        this.dialogRef = this._matDialog.open(AppFormPrivacityComponent, {
            panelClass: 'privacity-form-dialog',
            data: {
                action: type
            }
        });
        this.dialogRef.afterClosed().subscribe((response: FormGroup) => {
            if (!response) {
                return;
            }
        });
    }
}
