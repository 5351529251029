import { AuthService } from './../../../../services/auth/auth.service';
import { LayoutService } from '@core/services/layout.service';
import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector   : 'app-navbar-vertical',
    templateUrl: './navbar-vertical.component.html',
    styleUrls  : ['./navbar-vertical.component.scss']
})
export class AppNavBarVerticalComponent implements OnInit, OnDestroy
{

    open = false;
    userName: any;
    isAdminMenuOpened: boolean;

    @HostBinding('attr.class') get isOpened() {
        return this.isAdminMenuOpened ? 'mbl-opened' : '';
    }

    private _unsubscribeAll: Subject<any>;
    constructor(private router: Router,
        private _authService: AuthService,
        private _storage: LocalStorageService,
        private layoutService: LayoutService)
    {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                const urlDelimitators = new RegExp(/[?//,;&:#$+=]/);
                const currentUrlPath = event.url.slice(1).split(urlDelimitators)[0];

                if ( currentUrlPath === 'admin') {
                    this.open = true;
                }else{
                    this.open = false;
                }
            }
        });
        this._unsubscribeAll = new Subject();

    }

    ngOnInit(): void {

        this._authService.onUserChanged.pipe(takeUntil(this._unsubscribeAll))
        .subscribe( user => {
            this.userName = user.name;
        });

        this.layoutService.isAdminMenuOpened.subscribe(res => this.isAdminMenuOpened = res);
    }
    close() {
        this.layoutService.setVisibility(false);
        localStorage.clear();
        this.router.navigateByUrl('/').then( user => {
            setTimeout(() => {
                location.reload();
            }, 100);
        });
    }

    toFaq() {
        this.layoutService.setVisibility(false);
        this.layoutService.setScrollFaq(true);
        setTimeout( () => {
            this.router.navigate(['/como-ofrecer-local']);
        });
    }

    openLink(url) {
        this.layoutService.setVisibility(false);
        setTimeout( () => {
             this.router.navigate([`${url}`]);
        }, 600);
    }


    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}
