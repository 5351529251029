import { Component, OnInit, Inject } from '@angular/core';
import { appAnimations } from '@core/animations';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FormGroup } from '@angular/forms';
@Component({
    selector: 'app-privacity',
    templateUrl: './privacity.component.html',
    styleUrls: ['./privacity.component.scss'],
    animations: appAnimations
})
export class AppFormPrivacityComponent implements OnInit {
    action: any;
    dialogRef: any;

    constructor(private _matDialog: MatDialog, public matDialogRef: MatDialogRef<AppFormPrivacityComponent>, @Inject(MAT_DIALOG_DATA) private _data: any, ) {
        this.action = _data.action;
    }

    ngOnInit() {


    }

    openModal(type): void {
        this.dialogRef = this._matDialog.open(AppFormPrivacityComponent, {
            panelClass: 'privacity-form-dialog',
            hasBackdrop: false,
            data: {
                action: type
            }
        });
        this.dialogRef.afterClosed().subscribe((response: FormGroup) => {
            if (!response) {
                return;
            }
        });
    }

}
