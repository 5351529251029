import { Component, OnInit, Input, OnDestroy, AfterContentInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { TweenLite, TimelineLite, Power3 } from 'gsap';
import { AppNavigationService } from '../AppNavigationService';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';
declare var TweenMax: any;
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class AppMenuComponent implements OnInit, OnDestroy {
    visible = false;
    menuList: any;
    private _unsubscribeAll: Subject<any>;
    activeAccount = false;
    constructor(
        private router: Router,
        private elementRef: ElementRef,
        private appNavigationService: AppNavigationService,
        private _storage: LocalStorageService

    ) {
        this._unsubscribeAll = new Subject();

        if (this._storage.retrieve('currentuser')) {
            this.activeAccount = true;
        }
    }

    ngOnInit() {
        this.appNavigationService.visible
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((visible) => {
           this.visible = visible;

        });
    }


    openLink(url) {
        this.appNavigationService.hide();
        setTimeout( () => {
             this.router.navigate([`${url}`]);
        }, 600);
    }




    ngOnDestroy(): void
    {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
