import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class AppConfirmDialogComponent implements OnInit {
    public confirmMessage: string;

    subtitle: boolean;
    messageButton: string;
    textSubtitle: string;
    hideButtonCancel: boolean;

    constructor(public dialogRef: MatDialogRef<AppConfirmDialogComponent>) {
        this.subtitle = true;
        this.messageButton = 'Finalizar';
        this.textSubtitle =
            'Recuerda que una vez que se envie no podrás editar el contenido.';
            this.hideButtonCancel = true;
    }

    ngOnInit() {}
}
