import { AuthService } from './../../../../services/auth/auth.service';
import { LayoutService } from '@core/services/layout.service';
import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-navbar-admin',
  templateUrl: './navbar-admin.component.html',
  styleUrls: ['./navbar-admin.component.scss']
})
export class NavbarAdminComponent implements OnInit, OnDestroy {

    private _unsubscribeAll: Subject<any>;
    
    open = false;
    userName: any;
    menuOpened: boolean;

    constructor(private router: Router, private _authService: AuthService, private _storage: LocalStorageService, private layoutService: LayoutService) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                const urlDelimitators = new RegExp(/[?//,;&:#$+=]/);
                const currentUrlPath = event.url.slice(1).split(urlDelimitators)[0];

                this.open = currentUrlPath === 'admin' ? true : false;
            }
        });

        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._authService.onUserChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(user => this.userName = user.name);
        this.layoutService.isAdminMenuOpened.subscribe(res => this.menuOpened = res);
    }

    close() {
        localStorage.clear();
        this.router.navigateByUrl('/').then( user => setTimeout(() => location.reload(), 100));
    }


    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    toggleMenu() {
        this.layoutService.setVisibility(!this.menuOpened);
    }

}
